



















































































import Vue from "vue";
import Button from "@/components/input-components/Button";
import Pagination from "@/components/common/Pagination";
import Dialog from "@/components/dialogs/Dialog";
import PatientCreationWindow from "@/components/patients/PatientCreationWindow";
import ToolbarSearchInput from "@/components/common/ToolbarSearchInput";
import DateHelperMixin from "@/mixins/DateHelperMixin";
import {
  FETCH_PATIENTS,
  FETCH_PATIENTS_BY_PHYSICIANID,
  FETCH_PATIENTS_SEXES,
} from "@/store/types/actions.type";
import { mapGetters } from "vuex";
import { Patient } from "@/entities/patient";
import { FORM_FLAGS } from "@/utils/constants";
import {
  SET_PATIENTS_SEARCH_INPUT,
  SET_PATIENT,
} from "@/store/types/mutations.type";

export default Vue.extend({
  name: "PatientPickerWindow",
  components: {
    ToolbarSearchInput,
    Button,
    Pagination,
    Dialog,
    PatientCreationWindow,
  },
  props: {
    flag: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedPatientId: -1,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      isLoading: false,
      isWindowOpen: false,
      dateHelperMixin: new DateHelperMixin(),
    };
  },
  mounted() {
    this.fetchSexes();
    // listen for change in patient data in other tabs
    window.addEventListener("storage", this.refreshPatients);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.refreshPatients);
  },
  computed: {
    ...mapGetters("patient", [
      "getPatient",
      "getPatients",
      "getSearchInput",
      "getSexById",
    ]),
    ...mapGetters("physician", ["getPhysician"]),
    headers(): any[] {
      return [
        {
          text: this.$t("Patient name"),
          align: "start",
          sortable: true,
          value: "firstName",
        },
        {
          text: this.$t("CeGaT ID"),
          value: "patientNumber",
          sortable: true,
        },
        {
          text: this.$t("Patient d.o.b"),
          value: "dateOfBirth",
          sort: (a: string, b: string) => this.dateHelperMixin.sortDate(a, b),
        },
        {
          text: this.$t("sex"),
          value: "sexId",
          sortable: true,
        },
        { text: this.$t("Actions"), value: "actions" },
      ];
    },
    patient(): Patient {
      return this.getPatient;
    },
    patients(): Patient[] {
      return this.getPatients;
    },
    searchInput(): string {
      return this.getSearchInput;
    },
    getPhysicianId(): string {
      return this.getPhysician.userId;
    },
  },
  methods: {
    openPickerWindow(): void {
      this.isWindowOpen = true;
    },
    openCreationWindow(): void {
      const creationWindow = this.$refs.creationWindow as any;
      creationWindow.openCreationWindow();
    },
    closePickerWindow(): void {
      this.isWindowOpen = false;
    },
    selectItem(item: Patient): void {
      if (this.flag === FORM_FLAGS.isPatientInfo) {
        this.$store.dispatch(`patient/${SET_PATIENT}`, item);
        this.$emit("patientSelected", item);
      } else if (this.flag === FORM_FLAGS.isParentConsent) {
        this.$emit("parentSelected", item);
      }
      this.closePickerWindow();
    },
    selectNewPatient(): void {
      if (this.flag === FORM_FLAGS.isPatientInfo) {
        this.$emit("patientSelected", this.patient);
      } else if (this.flag === FORM_FLAGS.isParentConsent) {
        this.$emit("parentSelected", this.patient);
      }
      this.closePickerWindow();
    },
    async fetchSexes(): Promise<void> {
      this.isLoading = true;
      await this.$store.dispatch(`patient/${FETCH_PATIENTS_SEXES}`);
      this.fetchPatients();
    },
    async fetchPatients(): Promise<void> {
      if (this.getPhysicianId) {
        await this.$store.dispatch(
          `patient/${FETCH_PATIENTS_BY_PHYSICIANID}`,
          this.getPhysicianId
        );
      } else {
        await this.$store.dispatch(`patient/${FETCH_PATIENTS}`, true);
      }

      this.isLoading = false;
    },
    updateSearchInput(value: any): void {
      this.$store.dispatch(`patient/${SET_PATIENTS_SEARCH_INPUT}`, value);
    },
    refreshPatients(event: any): void {
      if (event.key === "refreshPatients") {
        this.fetchPatients();
        window.localStorage.removeItem("refreshPatients");
      }
    },
  },
});
