






























































































import Vue from "vue";
import Button from "@/components/input-components/Button.vue";
import InputText from "@/components/input-components/InputText.vue";
import NotificationMessageService from "@/services/notificationmessage.service";
import { ProfileService } from "@/services/profile.service";
import { EMAIL_REG_EXP } from "@/utils/constants";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";

export default Vue.extend({
  name: "ProfileDataSharing",
  props: {
    userProfile: {
      type: Object,
      required: true,
      default() {
        return {
          additionalEmail: [],
        };
      },
    },
    sharedUsers: {
      type: Object,
      required: true,
      default() {
        return {
          sharedUsers: [],
        };
      },
    },
  },
  components: {
    Button,
    InputText,
    ConfirmationDialog,
  },
  data() {
    return {
      emailAdressToAdd: "",
      emailRules: [
        (v: string) =>
          !v ||
          EMAIL_REG_EXP.test(v) ||
          this.$t("This field must contain a valid email"),
      ],
      profileService: new ProfileService(),
      showDeleteDialog: false,
      deleteEmail: "",
    };
  },
  methods: {
    handleEmailAdressAdd() {
      this.profileService
        .postSharedUsers(this.emailAdressToAdd, "", "", "", "")
        .then(() => {
          NotificationMessageService.showSuccess(
            this.$t("Shared user has been added succesful.") as string
          );
          this.$emit("reload-shared-users");
        })
        .catch(() => {
          NotificationMessageService.showError(
            this.$t("The e-mail you entered is already registered.") as string
          );
        });
    },

    deleteSharedUser(email: any) {
      this.showDeleteDialog = true;
      this.deleteEmail = email;
    },
    confirmDeleteSharedUser() {
      this.profileService
        .deleteSharedUsers(this.deleteEmail, "", "", "", "")
        .then(() => {
          NotificationMessageService.showSuccess(
            this.$t("Shared user has been deleted succesful.") as string
          );
          this.$emit("reload-shared-users");
          this.showDeleteDialog = false;
        });
    },
  },
  computed: {
    validEmail(): boolean {
      return EMAIL_REG_EXP.test(this.emailAdressToAdd);
    },
  },
});
