import Keycloak from 'keycloak-js';

export default class AuthenticatedUser {
  private _token: Keycloak.KeycloakTokenParsed;
  constructor(token: Keycloak.KeycloakTokenParsed) {
    this._token = token;
  }

  get firstName(): string {
    return this._token.given_name;
  }

  get lastName(): string {
    return this._token.family_name;
  }

  get fullName(): string {
    return this._token.name;
  }

  get email(): string {
    return this._token.email;
  }

  get roles(): string[] {
    return this._token.realm_access?.roles || [];
  }
}
