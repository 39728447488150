import Config from './config'

interface AuthenticationOptions {
  url: string,
  backendApi: string,
  realm: string,
  clientId: string,
  scope?: string,
  backendClientId: string
}

const KeycloakConfig : AuthenticationOptions = {
  url: Config.KEYCLOAK_BASE_URL,
  backendApi: Config.API_BASE_URL,
  realm: Config.KEYCLOAK_REALM_NAME,
  clientId: Config.KEYCLOAK_CLIENTID,
  backendClientId: Config.KEYCLOAK_BACKENDCLIENTID
}

export {KeycloakConfig, AuthenticationOptions}
