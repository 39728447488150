import {IProspectUserService} from "@/services/iprospectuser.service";
import axios from "@/config/api-config";

const prospectUserApi = '/prospectUsers'

export class ProspectUserApiService implements IProspectUserService {

  postMergeAccount(parameters : string): Promise<any> {
    return axios.post(prospectUserApi + '/mergedUser?' + encodeURI(parameters))
  }
}
