



























import Vue from "vue";
import Dialog from "@/components/dialogs/Dialog";
import Button from "@/components/input-components/Button.vue";
import OrderConsentDownload from "@/components/orders/OrderConsentDownload.vue";

export default Vue.extend({
  name: "ConsentDownloadDialog",
  components: {Button, Dialog, OrderConsentDownload},
  inheritAttrs: false,
  props: {
    dataLoading: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
  },
});
