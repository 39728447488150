



















import Vue from "vue";
import OrdersToolbarInternal from "@/components/orders/OrdersToolbarInternal.vue";
import OrdersToolbar from "@/components/orders/OrdersToolbar.vue";
import OrdersGrid from "@/components/orders/OrdersGrid";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MyOrders",
  components: {
    OrdersToolbarInternal,
    OrdersToolbar,
    OrdersGrid,
  },
  created() {
    // check for orderId param, save it temporary and remove the param from the url
    this.orderId = this.$route?.params?.orderId;
    
    if (this.orderId) {
      this.$router.replace('/MyOrders')
    }
  },
  data() {
    return {
      orderId: "",
    }
  },
  computed: {
    ...mapGetters("physician", ["getPhysician"]),
    ...mapGetters("order", ["getSearchOrderId", "getSearchCegatId"]),
    isSearchingByPhysician(): boolean {
      return (
        this.getPhysician?.userId !== "" && this.getPhysician?.userId !== null
      );
    },
    isSearchingByOrderId(): boolean {
      return this.getSearchOrderId !== "" && this.getSearchOrderId !== null;
    },
    isSearchingByCegatId(): boolean {
      return this.getSearchCegatId !== "" && this.getSearchCegatId !== null;
    },
    showOrdersGrid(): boolean {
      return (
        (this.hasOrderReadAllPermission && this.isSearchingByPhysician) ||
        (this.hasOrderReadAllPermission && this.isSearchingByOrderId) ||
        (this.hasOrderReadAllPermission && this.isSearchingByCegatId) ||
        (this.hasOrderReadPermission && !this.hasOrderReadAllPermission)
      );
    },
  },
  methods: {
    showInternalToolbar(): boolean {
      return this.hasOrderReadAllPermission;
    },
  },
});
