import {NavigationGuard, VueRouter} from "vue-router/types/router";
import axios from "@/config/api-config";

import NotificationMessageService from "@/services/notificationmessage.service";
import Vue from "vue/types/umd";
import permissions from "@/plugins/permissions";

const guardFactory = function (router: VueRouter): NavigationGuard<Vue> {
  const guard: NavigationGuard<Vue> = async (to, from, next) => {
    const perm = new permissions();

    // If reload the permission token might not be loaded yet
    if (!axios._keycloak?.permissions_token) {
      await axios._keycloak?.fetchPermissionsToken()
    }

    switch (to.name) {
      case "My Orders":
        if (perm.hasOrderReadPermission || perm.hasOrderReadAllPermission) {
          next();
        } else {
          NotificationMessageService.showWarning("Not Enough Permissions");
        }
        break;
      case "View Order":
        if (
          perm.hasOrderReadPermission ||
          perm.hasOrderReadAllPermission
        ) {
          next();
        } else {
          NotificationMessageService.showWarning("Not Enough Permissions");
        }
        break;
      case "Create Order":
        if (
          perm.hasOrderCreatePermission ||
          perm.hasOrderCreateAllPermission
        ) {
          next();
        } else {
          NotificationMessageService.showWarning("Not Enough Permissions");
        }
        break;
      case "Select New Order Template":
        if (
          perm.hasOrderCreatePermission ||
          perm.hasOrderCreateAllPermission
        ) {
          next();
        } else {
          NotificationMessageService.showWarning("Not Enough Permissions");
        }
        break;
      case "Edit Order":
        if (
          perm.hasOrderUpdatePermission ||
          perm.hasOrderUpdateAllPermission
        ) {
          next();
        } else {
          NotificationMessageService.showWarning("Not Enough Permissions");
        }
        break;
      // case "My Patients":
      //   if (
      //     perm.hasPatientReadPermission ||
      //     perm.hasPatientReadAllPermission
      //   ) {
      //     next();
      //   }
      //   break;
      case "Create Patient":
        if (
          perm.hasPatientCreatePermission ||
          perm.hasPatientCreateAllPermission
        ) {
          next();
        } else {
          NotificationMessageService.showWarning("Not Enough Permissions");
        }
        break;
      case "Edit Patient":
        if (
          perm.hasPatientUpdatePermission ||
          perm.hasPatientUpdateAllPermission
        ) {
          next();
        } else {
          NotificationMessageService.showWarning("Not Enough Permissions");
        }
        break;
      // case "My Profile":
      //   if (
      //     perm.hasUserReadPermission ||
      //     perm.hasUserReadAllPermission
      //   ) {
      //     next();
      //   }
      //   break;
      default:
        next();
    }
  }

  return guard
}

export default guardFactory
