import { Order } from "@/entities/order";
import Vue from "vue";

export default Vue.extend({
  methods: {
    getPanelName(order: Order, locale: string): string {
      let panelName = order.form.formConfig.panelName

       if (locale !== "en" &&
         order.form?.formConfig?.localizations !== undefined &&
         order.form?.formConfig?.localizations[locale]?.panelName) {
         panelName = order.form.formConfig.localizations[locale].panelName
       }

       // use old test value as fallback
       if (!panelName) {
         panelName = order.formTestName
       }

       return panelName
    },
  }
})