














































import Vue from "vue";
import Button from "@/components/input-components/Button";

export default Vue.extend({
  name: "UserMenu",
  components: { Button },
  data() {
    return {
      userProfile: {},
    };
  },
  mounted() {
    this.$root.$refs.UserMenu = this;
    this.userProfile = this.user;
  },
  methods: {
    async updateComponent(userProfile: Object) {
      this.userProfile = userProfile;
    },
    logout(): void {
      this.$keycloak.logout();
    },
    profile(): void {
      if (this.$router.currentRoute.path === '/MyProfile') {
        this.$root.$emit("resetMyProfileCurrentTab");
      } else {
        this.$router.push({ path: `/MyProfile` });
      }
    },
  },
  computed: {
    emailNotVerified(): boolean {
      return this.hasRoleExternalUser && !this.hasRoleCegatVerified;
    },
  },
});
