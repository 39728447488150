

































































import Vue from "vue";
import Button from "@/components/input-components/Button";
import Pagination from "@/components/common/Pagination";
import Dialog from "@/components/dialogs/Dialog";
import ToolbarSearchInput from "@/components/common/ToolbarSearchInput";
import { Physician } from "@/entities/physician";
import { mapGetters } from "vuex";
import { FETCH_PHYSICIANS } from "@/store/types/actions.type";
import { SET_PHYSICIANS_SEARCH_INPUT } from "@/store/types/mutations.type";

export default Vue.extend({
  name: "PhysicianPickerWindow",
  components: {
    ToolbarSearchInput,
    Button,
    Pagination,
    Dialog,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      isLoading: false,
      isWindowOpen: false,
    };
  },
  mounted() {
    this.fetchPhysicians();
  },
  computed: {
    headers(): any[] {
      return [
        {
          text: this.$t("First name"),
          align: "start",
          sortable: false,
          value: "firstName",
        },
        {
          text: this.$t("Last name"),
          align: "start",
          sortable: false,
          value: "lastName",
        },
        {
          text: this.$t("Email"),
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: this.$t("City"),
          align: "start",
          sortable: false,
          value: "city",
        },
        {
          text: this.$t("Actions"),
          align: "end",
          sortable: false,
          value: "actions",
        },
      ];
    },
    ...mapGetters("physician", ["getPhysicians", "getSearchInput"]),
    physicians(): Physician[] {
      return this.getPhysicians;
    },
    searchInput(): string {
      return this.getSearchInput;
    },
  },
  methods: {
    openPickerWindow() {
      this.isWindowOpen = true;
    },
    closePickerWindow() {
      this.isWindowOpen = false;
    },
    async fetchPhysicians(): Promise<void> {
      this.isLoading = true;
      await this.$store.dispatch(`physician/${FETCH_PHYSICIANS}`);
      this.isLoading = false;
    },
    onSearchInputChange(searchInput: string): void {
      this.$store.dispatch(
        `physician/${SET_PHYSICIANS_SEARCH_INPUT}`,
        searchInput
      );
    },
    onSelectPhysician(physician: Physician) {
      this.$emit("physicianSelected", physician);
      this.closePickerWindow();
    },
  },
});
