import axios from '@/config/api-config';
import {ApiResponse} from '@/entities/api-response';
import {IReportsService} from "@/services/ireports.service";
import {Report} from "@/entities/report";
import fileDownload from 'js-file-download';
const api = '/orders';

export class ReportsApiService implements IReportsService {
  private loaded: number|null = null;
  private reportsUrl: string = 'reports'

  constructor(reportsUrl?: string) {
    if (reportsUrl) {
      this.reportsUrl = reportsUrl
    }
  }

  getReports(orderId: number): Promise<ApiResponse> {
    return axios.get(`${api}/${orderId}/${this.reportsUrl}`).then(res => res.data);
  }
  getReport(orderId: number, orderFileId: number, fileName: string): Promise<void> {
    this.loaded = null
    return axios.get(`${api}/${orderId}/${this.reportsUrl}/${orderFileId}`, {
      validateStatus: function (status) {
        return status === 403 || status === 200 || status === 204;
      },
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        if (progressEvent && progressEvent.loaded) {
          this.loaded = Math.round(progressEvent.loaded / 1000)
        }
      },
    }).then(res => {
      if(res.status === (200||204)) {
        fileDownload(res.data, fileName)
      } else {
        throw res
      }
      this.loaded = null
    })
  }
  getLoadedValue(): number|null {
    return this.loaded
  }
  insertReport(orderId: number, report: any): Promise<ApiResponse> {
    const formData = new FormData();
    formData.append('file', report);
    return axios.post(
      `${api}/${orderId}/${this.reportsUrl}`,
      formData,
      {headers: {"Content-Type": "multipart/form-data"}}
    ).then(res => res.data);
  }
  updateReport(orderId: number, orderFileId: number, report: Report): Promise<ApiResponse> {
    return axios.post(`${api}/${orderId}/${this.reportsUrl}/${orderFileId}`, report).then(res => res.data);
  }
  deleteReport(orderId: number, orderFileId: number): Promise<ApiResponse> {
    return axios.delete(`${api}/${orderId}/${this.reportsUrl}/${orderFileId}`).then(res => res.data);
  }
}
