

















































































import Vue from "vue";
import Dialog from "@/components/dialogs/Dialog";
import FileInput from "@/components/input-components/FileInput";
import InputText from "@/components/input-components/InputText.vue";
import Button from "@/components/input-components/Button.vue";
import FileTooltip from "@/components/common/FileTooltip";
import { OrderSupportingFile } from "@/entities/order-supporting-file";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";

export default Vue.extend({
  name: "SampleUploadDialog",
  components: {
    Button,
    FileInput,
    Dialog,
    InputText,
    ConfirmationDialog,
    FileTooltip,
  },
  inheritAttrs: false,
  props: {
    initialSupportingFiles: {
      type: Array as () => Array<OrderSupportingFile>,
      required: true,
      default() {
        return [];
      },
    },
    show: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      files: [] as Array<any>,
      showReplacingFileDialog: false,
      existingFile: {} as OrderSupportingFile,
      supportingFiles: this.initialSupportingFiles,
      wrongDatatype: false,
    };
  },
  computed: {
    saveInProgress(): boolean {
      return (this.$attrs && !!this.$attrs["save-in-progress"]) ?? false;
    },
  },
  methods: {
    async filesSelected(event: any): Promise<void> {
      this.files = event;

      for (const file of event) {
        // check if file is in the store list
        const existingFiles: OrderSupportingFile[] =
          this.supportingFiles?.filter((f: OrderSupportingFile) => {
            return f.fileName === file.name;
          });

        if (existingFiles?.length) {
          // file is already in the list
          // ask user if he wants to replace it
          this.existingFile = existingFiles[0];
          this.showReplacingFileDialog = true;

          while (this.showReplacingFileDialog) {
            await new Promise((resolve) => setTimeout(resolve, 100));
          }
        }
      }
    },
    cancelReplacingFile(): void {
      this.files = this.files.filter(
        (f: any) => f.name !== this.existingFile.fileName
      );
      this.showReplacingFileDialog = false;
    },
    replaceExistingFile(): void {
      this.supportingFiles = this.supportingFiles.filter(
        (f: any) => f.fileName !== this.existingFile.fileName
      );
      this.showReplacingFileDialog = false;
    },
    toggleDeleteState(orderFileId: number): void {
      const index = this.supportingFiles.findIndex(
        (f: any) => f.orderFileId == orderFileId
      );
      if (index !== -1) {
        this.$set(
          this.supportingFiles[index] as OrderSupportingFile,
          "delete",
          !(this.supportingFiles[index] as OrderSupportingFile).delete
        );
      }
    },
    input(input: boolean) {
      if (!input) {
        this.resetDeleteState();
      }
      this.$emit("input", input);
    },
    cancel() {
      this.resetDeleteState();
      this.$emit("cancel");
    },
    resetDeleteState(): void {
      this.supportingFiles.forEach(
        (file: any) => file.delete = false
      );
    },
    save() {
      const deletedSupportingFiles = this.supportingFiles.filter(
        (f: any) => f.delete
      );

      const add = this.files;

      this.$emit("save", { remove: deletedSupportingFiles, add });
    },
  },  
  watch: {
    show: {
      immediate: true,
      handler() {
        this.files = [];
        this.supportingFiles = this.initialSupportingFiles;
      },
    },
  },
});
