























































































































































































































































































































import Vue from "vue";
import {
  DELETE_ORDER,
  DELETE_ORDER_NOTIFICATION,
  DELETE_REPORT,
  FETCH_NOTIFICATIONS,
  FETCH_ORDER,
  FETCH_ORDER_NOTIFICATIONS,
  FETCH_ORDER_REPORTS,
  FETCH_ORDERS,
  FETCH_ORDERS_STATUS,
  INSERT_ORDER_REPORT,
  POST_ORDER_NOTIFICATION,
  PUT_ORDER,
  FETCH_FORM_TESTS,
} from "@/store/types/actions.type";
import Button from "@/components/input-components/Button";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import DateHelperMixin from "@/mixins/DateHelperMixin";
import LocalizationMixin from "@/mixins/LocalizationMixin";
import NotificationMessageService from "@/services/notificationmessage.service";
import OrderGridActionEdit from "@/components/orders/OrderGridActionEdit.vue";
import OrderGridActionReportDownload from "@/components/orders/OrderGridActionReportDownload.vue";
import OrderGridActionSampleReception from "@/components/orders/OrderGridActionSampleReception.vue";
import OrderGridActionReportManagement from "@/components/orders/OrderGridActionReportManagement.vue";
import OrderGridActionView from "@/components/orders/OrderGridActionView.vue";
import OrderNotificationDialog from "@/components/dialogs/OrderNotificationDialog.vue";
import OrderShareDialog from "@/components/dialogs/OrderShareDialog.vue";
import Pagination from "@/components/common/Pagination.vue";
import { ORDER_STATUS, PERMISSION } from "@/utils/constants";
import ReportDownloadDialog from "@/components/dialogs/ReportDownloadDialog.vue";
import ConsentDownloadDialog from "@/components/dialogs/ConsentDownloadDialog.vue";
import ReportManagementDialog from "@/components/dialogs/ReportManagementDialog.vue";
import SampleReceptionDialog from "@/components/dialogs/SampleReceptionDialog.vue";
import OrderViewDialog from "@/components/dialogs/OrderViewDialog.vue";
import StatusChangeDialog from "@/components/dialogs/StatusChangeDialog.vue";
import OrderTestChangeDialog from "@/components/dialogs/OrderTestChangeDialog.vue";
import { Order } from "@/entities/order";
import { Notification } from "@/entities/notification";
import { OrderStatus } from "@/entities/order-status";
import { Report } from "@/entities/report";
import { mapGetters } from "vuex";
import { promisedTimeout } from "@/utils";
import { OrderUsersService } from "@/services/orderusers.service";
import { OrderUser } from "@/entities/order-user";
import { SET_ORDERS_SEARCH_INPUT } from "@/store/types/mutations.type";
import { FormTest } from "@/entities/form-test";

export default Vue.extend({
  name: "OrdersGrid",
  components: {
    OrderGridActionEdit,
    OrderGridActionView,
    OrderGridActionReportDownload,
    OrderGridActionSampleReception,
    OrderGridActionReportManagement,
    SampleReceptionDialog,
    OrderViewDialog,
    ReportDownloadDialog,
    ConsentDownloadDialog,
    ReportManagementDialog,
    OrderNotificationDialog,
    StatusChangeDialog,
    OrderTestChangeDialog,
    ConfirmationDialog,
    OrderShareDialog,
    Pagination,
    Button,
  },
  props: {
    orderIdPreFilter: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      sortBy: 'orderDate',
      sortDesc: true,
      showDeleteDialog: false,
      showStatusChangeDialog: false,
      showOrderTestChangeDialog: false,
      showNotificationDialog: false,
      showReportManagementDialog: false,
      showReportDownloadDialog: false,
      showConsentDownloadDialog: false,
      showSampleReceptionDialog: false,
      showOrderViewDialog: false,
      showOrderShareDialog: false,
      selectedOrderId: -1,
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      dateHelperMixin: new DateHelperMixin() as any,
      localizationMixin: new LocalizationMixin() as any,
      dataLoading: false,
      saveInProgress: false,
      errorOnSave: false,
      permission: PERMISSION.frontendCheck.none,
      permissionNone: PERMISSION.frontendCheck.none,
      orderUserService: new OrderUsersService(),
      currentOrderUsers: [] as Array<OrderUser>,
    };
  },
  created() {
    if (this.orderIdPreFilter !== "") {
      this.$store.dispatch(`order/${SET_ORDERS_SEARCH_INPUT}`, this.orderIdPreFilter);
    }
  },
  mounted() {
    this.fetchOrdersStatus();
  },
  computed: {
    ...mapGetters("order", [
      "getOrder",
      "getOrders",
      "getOrdersStatus",
      "getGridContentType",
      "getOrderNotifications",
      "getLoadingCount"
    ]),
    ...mapGetters("form", ["getFormTests"]),
    ...mapGetters("orderNotification", ["getNotifications"]),
    ...mapGetters({
      getReport: "report/getReports",
      getReportLoadedValue: "report/getLoadedValue",
    }),
    ...mapGetters({
      getSupportingFiles: "supportingFile/getReports",
      getSupportingFilesLoadedValue: "supportingFile/getLoadedValue",
    }),
    ...mapGetters("physician", ["getPhysician"]),
    headers(): any[] {
      if (this.contentType === "My Orders") {
        return [
          {
            text: this.$t("Order date"),
            sortable: true,
            value: "orderDate",
            sort: (a: string, b: string) => this.dateHelperMixin.sortDate(a, b),
          },
          { text: this.$t("Order ID"), sortable: true, value: "orderId" },
          {
            text: this.$t("Reference ID"),
            sortable: true,
            value: "referenceId",
          },
          { text: this.$t("CeGaT ID"), sortable: true, value: "cegatId" },
          {
            text: this.$t("Patient"),
            sortable: true,
            value: "patientName",
          },
          {
            text: this.$t("Patient d.o.b"),
            sortable: true,
            value: "patientDateOfBirth",
            sort: (a: string, b: string) => this.dateHelperMixin.sortDate(a, b),
          },
          { text: this.$t("Test"), sortable: true, value: "formTestName" },
          { text: this.$t("Status"), sortable: true, value: "statusId" },
          { text: this.$t("Actions"), sortable: false, value: "actions" },
        ];
      } else {
        return [
          {
            text: this.$t("Order date"),
            sortable: true,
            value: "orderDate",
            sort: (a: string, b: string) => this.dateHelperMixin.sortDate(a, b),
          },
          { text: this.$t("Order ID"), sortable: true, value: "orderId" },
          { text: this.$t("CeGaT ID"), sortable: true, value: "cegatId" },
          { text: this.$t("Patient"), sortable: true, value: "patientName" },
          {
            text: this.$t("Patient d.o.b"),
            sortable: true,
            value: "patientDateOfBirth",
            sort: (a: string, b: string) => this.dateHelperMixin.sortDate(a, b),
          },
          { text: this.$t("Test"), sortable: true, value: "formTestName" },
          { text: this.$t("Status"), sortable: true, value: "statusId" },
          { text: this.$t("Shared By"), sortable: true, value: "sharedBy" },
          { text: this.$t("Actions"), sortable: false, value: "actions" },
        ];
      }
    },
    order(): Order {
      return this.getOrder;
    },
    orderFormId(): Order {
      return this.getOrder.form?.formConfig?.uniqueId;
    },
    orderFormTests(): FormTest[] {
      return this.getFormTests;
    },
    orders(): Order[] {
      return this.getOrders;
    },
    ordersStatus(): OrderStatus[] {
      return this.getOrdersStatus;
    },
    contentType(): string {
      return this.getGridContentType;
    },
    notifications(): Notification[] {
      return this.getNotifications;
    },
    orderNotifications(): Notification[] {
      return this.getOrderNotifications;
    },
    orderReports(): any {
      return this.getReport;
    },
    getCurrentLoadedValue(): number | null {
      return this.getReportLoadedValue;
    },
    supportingFiles(): any {
      return this.getSupportingFiles;
    },
    getCurrentSfLoadedValue(): number | null {
      return this.getSupportingFilesLoadedValue;
    },
    getPhysicianId(): string {
      return this.getPhysician.userId;
    },
    isLoading(): boolean {
      return this.getLoadingCount > 0;
    },
  },
  methods: {
    getStatusName(id: string): string {
      return this.getOrdersStatus.find((s: OrderStatus) => s.id == id)?.name;
    },
    getStatusTooltip(id: string): string {
      return this.getOrdersStatus.find((s: OrderStatus) => s.id == id)?.tooltip;
    },
    getStatusShowTooltip(id: string): string {
      return this.getOrdersStatus.find((s: OrderStatus) => s.id == id)
        ?.show_tooltip;
    },
    async fetchOrder() {
      if (this.selectedOrderId !== -1) {
        await this.$store.dispatch(`order/${FETCH_ORDER}`, [
          this.selectedOrderId,
          this.getPhysicianId,
        ]);
      }
    },
    async fetchOrders(): Promise<void> {
      if (this.hasOrderReadPermission && !this.hasOrderReadAllPermission) {
        await this.$store.dispatch(`order/${FETCH_ORDERS}`, true);
      }
    },
    async fetchOrdersStatus(): Promise<void> {
      await this.$store.dispatch(`order/${FETCH_ORDERS_STATUS}`);
      this.fetchOrders();
    },
    async fetchOrderNotifications() {
      if (this.selectedOrderId !== -1) {
        await Promise.all([
          this.$store.dispatch(`orderNotification/${FETCH_NOTIFICATIONS}`),
          this.$store.dispatch(
            `order/${FETCH_ORDER_NOTIFICATIONS}`,
            this.selectedOrderId
          ),
        ]);
      }
    },

    // ConfirmationDialog
    deleteItem(item: Order) {
      this.selectedOrderId = item.orderId;
      this.showDeleteDialog = true;
    },
    async deleteItemConfirm() {
      await this.$store.dispatch(`order/${DELETE_ORDER}`, [
        this.selectedOrderId,
        this.getPhysicianId,
      ]);
      this.closeDeleteDialog();
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
      this.selectedOrderId = -1;
    },

    // OrderNotificationDialog
    orderNotification(item: Order) {
      this.permission = this.orderNotificationPermission(item);
      if (this.permission === PERMISSION.frontendCheck.none) return;

      this.prepareDialogLoad(item);
      const that = this;
      const openDialogTimeout = setTimeout(function () {
        that.showNotificationDialog = true;
      }, 200);

      this.fetchOrderNotifications().then(() => {
        clearTimeout(openDialogTimeout);
        this.showNotificationDialog = true;
        this.dataLoading = false;
      });
    },
    async orderNotificationSave(args: any) {
      this.prepareDialogSave();

      let errorOnSave = false;
      const removeLength = args.remove.length;
      for (let i = 0; i < removeLength; i++) {
        const result = await this.$store.dispatch(
          `order/${DELETE_ORDER_NOTIFICATION}`,
          {
            orderId: this.selectedOrderId,
            notificationId: args.remove[i].notificationId,
          }
        );
        if (!errorOnSave && !result) {
          errorOnSave = true;
        }

        if (i % 5 === 0) {
          await promisedTimeout(300);
        }
      }

      let addLength = args.add.length;
      for (let i = 0; i < addLength; i++) {
        const result = await this.$store.dispatch(
          `order/${POST_ORDER_NOTIFICATION}`,
          {
            orderId: this.selectedOrderId,
            notification: args.add[i],
          }
        );
        if (!errorOnSave && !result) {
          errorOnSave = true;
        }

        if (i % 5 === 0) {
          await promisedTimeout(300);
        }
      }

      if (!errorOnSave) {
        this.closeOrderNotificationDialog();
        NotificationMessageService.showSuccess(
          this.$t(`Order notification settings saved!`) as string
        );
      }
      this.errorOnSave = errorOnSave;
      this.saveInProgress = false;
    },
    closeOrderNotificationDialog() {
      this.showNotificationDialog = false;
      this.selectedOrderId = -1;
    },

    // OrderShareDialog
    shareOrder(item: Order) {
      this.prepareDialogLoad(item);
      const that = this;
      const openDialogTimeout = setTimeout(function () {
        that.showOrderShareDialog = true;
      }, 200);

      this.orderUserService
        .getOrderUsers(item.orderId)
        .then((response) => {
          this.currentOrderUsers = response;
          clearTimeout(openDialogTimeout);
          this.showOrderShareDialog = true;
        })
        .finally(() => (this.dataLoading = false));
    },
    async addOrderUser(payload: any) {
      this.dataLoading = true;
      this.saveInProgress = true;
      this.orderUserService
        .postOrderUsers(payload.orderId, payload.email)
        .then(() => {
          this.saveInProgress = false;
          this.orderUserService
            .getOrderUsers(payload.orderId)
            .then((response) => {
              this.currentOrderUsers = response;
              NotificationMessageService.showSuccess(
                this.$t(`Shared user has been added succesful.`) as string
              );
            })
            .finally(() => (this.dataLoading = false));
        })
        .catch(() => {
          NotificationMessageService.showError(
            this.$t("There was an error adding this email.") as string
          );
        })
        .finally(() => {
          this.dataLoading = false;
          this.saveInProgress = false;
        });
    },

    async deleteOrderUser(payload: any) {
      this.dataLoading = true;
      this.saveInProgress = true;
      this.orderUserService
        .deleteOrderUser(payload.data.orderUserId)
        .then(() => {
          this.saveInProgress = false;
          this.orderUserService
            .getOrderUsers(payload.data.orderId)
            .then((response) => {
              this.currentOrderUsers = response;
              NotificationMessageService.showSuccess(
                this.$t(`Shared user has been deleted succesful.`) as string
              );
            })
            .finally(() => (this.dataLoading = false));
        })
        .catch(() => {
          NotificationMessageService.showError(
            this.$t("Please try again later.") as string
          );
        })
        .finally(() => {
          this.dataLoading = false;
          this.saveInProgress = false;
        });
    },

    // ReportManagementDialog
    async reportManagement(item: Order) {
      this.prepareDialogLoad(item);
      const that = this;
      const openDialogTimeout = setTimeout(function () {
        that.showReportManagementDialog = true;
      }, 200);

      await Promise.all([
        this.$store.dispatch(
          `report/${FETCH_ORDER_REPORTS}`,
          this.selectedOrderId
        ),
        this.fetchOrder(),
      ]);

      clearTimeout(openDialogTimeout);
      this.showReportManagementDialog = true;
      this.dataLoading = false;
    },
    async deleteReports(reports: Report[]): Promise<boolean> {
      let errorOnSave = false;
      const removeLength = reports.length;
      for (let i = 0; i < removeLength; i++) {
        const report = reports[i] as Report;
        const result = await this.$store.dispatch(`report/${DELETE_REPORT}`, {
          orderId: report.orderId,
          orderFileId: report.orderFileId,
        });
        if (!errorOnSave && result === false) {
          errorOnSave = true;
        }

        if (i % 5 === 0) {
          await promisedTimeout(300);
        }
      }

      return errorOnSave;
    },
    async addNewReports(reports: any): Promise<boolean> {
      let errorOnSave = false;
      let addLength = reports.length;
      for (let i = 0; i < addLength; i++) {
        const result = await this.$store.dispatch(
          `report/${INSERT_ORDER_REPORT}`,
          { orderId: this.selectedOrderId, report: reports[i] }
        );
        if (!errorOnSave && result === false) {
          errorOnSave = true;
        }

        if (i % 5 === 0) {
          await promisedTimeout(300);
        }
      }

      return errorOnSave;
    },
    async reportDownload(item: Order) {
      this.prepareDialogLoad(item);
      const that = this;
      const openDialogTimeout = setTimeout(function () {
        that.showReportDownloadDialog = true;
      }, 200);

      this.$store
        .dispatch(`report/${FETCH_ORDER_REPORTS}`, this.selectedOrderId)
        .then(() => {
          clearTimeout(openDialogTimeout);
          that.showReportDownloadDialog = true;
          this.dataLoading = false;
        });
    },
    async reportManagementSave(args: any) {
      this.prepareDialogSave();

      Promise.all([
        this.deleteReports(args.remove),
        this.addNewReports(args.add),
        this.$store.dispatch(`order/${PUT_ORDER}`, [
          false,
          this.getPhysicianId,
        ]),
      ]).then((values) => {
        if (values.every((v) => v === false)) {
          this.closeReportManagementDialog();
          NotificationMessageService.showSuccess(
            this.$t("Report Information Saved!") as string
          );
        } else {
          this.errorOnSave = true;
        }
        this.saveInProgress = false;
      });
    },
    closeReportManagementDialog() {
      this.showReportManagementDialog = false;
      this.selectedOrderId = -1;
    },

    // StatusChangeDialog
    statusManagement(item: Order) {
      this.prepareDialogLoad(item);
      const that = this;
      const openDialogTimeout = setTimeout(function () {
        that.showStatusChangeDialog = true;
      }, 200);

      this.fetchOrder()
        .then(() => {
          clearTimeout(openDialogTimeout);
          this.showStatusChangeDialog = true;
          this.dataLoading = false;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    statusManagementSave() {
      this.prepareDialogSave();
      this.$store
        .dispatch(`order/${PUT_ORDER}`, [true, this.getPhysicianId])
        .then((res) => {
          if (res === false) {
            this.closeStatusManagementDialog();
          } else {
            this.errorOnSave = true;
          }
          this.saveInProgress = false;
        });
    },
    closeStatusManagementDialog() {
      this.showStatusChangeDialog = false;
      this.selectedOrderId = -1;
    },

    // OrderTestChangeDialog
    async changeOrderedTest(item: Order) {
      this.prepareDialogLoad(item);
      const that = this;
      const openDialogTimeout = setTimeout(function () {
        that.showOrderTestChangeDialog = true;
      }, 200);

      await this.fetchOrder();
      await this.$store.dispatch(`form/${FETCH_FORM_TESTS}`, this.orderFormId);

      clearTimeout(openDialogTimeout);
      that.showOrderTestChangeDialog = true;
      this.dataLoading = false;
    },
    orderTestChangeSave() {
      this.prepareDialogSave();
      this.$store
        .dispatch(`order/${PUT_ORDER}`, [true, this.getPhysicianId])
        .then((res) => {
          if (res === false) {
            this.closeOrderTestChangeDialog();
          } else {
            this.errorOnSave = true;
          }
          this.saveInProgress = false;
        });
    },
    closeOrderTestChangeDialog() {
      this.showOrderTestChangeDialog = false;
      this.selectedOrderId = -1;
    },

    // SampleReceptionDialog
    async sampleReception(item: Order) {
      this.prepareDialogLoad(item);
      const that = this;
      const openDialogTimeout = setTimeout(function () {
        that.showSampleReceptionDialog = true;
      }, 200);

      await Promise.all([
        this.$store.dispatch(
          `supportingFile/${FETCH_ORDER_REPORTS}`,
          this.selectedOrderId
        ),
        this.fetchOrder(),
      ]);

      clearTimeout(openDialogTimeout);
      that.showSampleReceptionDialog = true;
      this.dataLoading = false;
    },
    closeSampleReceptionDialog() {
      this.showSampleReceptionDialog = false;
      this.selectedOrderId = -1;
    },
    sampleReceptionSave() {
      this.prepareDialogSave();
      this.$store
        .dispatch(`order/${PUT_ORDER}`, [true, this.getPhysicianId])
        .then((res) => {
          if (res === false) {
            this.closeSampleReceptionDialog();
          } else {
            this.errorOnSave = true;
          }
          this.saveInProgress = false;
        });
    },

    // OrderViewDialog
    async orderViewDialog(item: Order) {
      this.prepareDialogLoad(item);
      const that = this;
      const openDialogTimeout = setTimeout(function () {
        that.showOrderViewDialog = true;
      }, 200);

      await Promise.all([
        this.$store.dispatch(
          `supportingFile/${FETCH_ORDER_REPORTS}`,
          this.selectedOrderId
        ),
        this.fetchOrder(),
      ]);

      clearTimeout(openDialogTimeout);
      that.showOrderViewDialog = true;
      this.dataLoading = false;
    },
    viewOrder(item: Order): void {
      this.orderViewDialog(item);
    },

    // ConsentDownloadDialog
    consentDownload(item: Order) {
      this.prepareDialogLoad(item);
      const that = this;
      const openDialogTimeout = setTimeout(function () {
        that.showConsentDownloadDialog = true;
      }, 200);

      this.fetchOrder()
        .then(() => {
          clearTimeout(openDialogTimeout);
          this.showConsentDownloadDialog = true;
          this.dataLoading = false;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },

    // PREPARE DIALOG
    prepareDialogLoad(item: Order): void {
      this.dataLoading = true;
      this.errorOnSave = false;
      this.selectedOrderId = item.orderId;
    },
    prepareDialogSave(): void {
      this.saveInProgress = true;
      this.errorOnSave = false;
    },

    // PERMISSIONS
    hasDeleteOrderPermission(item: Order): boolean {
      return (
        this.hasOrderDeleteAllPermission ||
        (item.statusId === ORDER_STATUS.draft && this.hasOrderDeletePermission)
      );
    },
    hasOrderUserPermission(item: Order): boolean {
      return (
        item.statusId !== ORDER_STATUS.draft &&
        (item.isOwner || this.hasOrderUpdateAllPermission)
      );
    },
    orderNotificationPermission(item: Order): string {
      if (item.statusId !== ORDER_STATUS.draft) {
        const hasReadPermissions =
          this.hasNotificationReadPermission ||
          this.hasNotificationReadAllPermission;

        if (hasReadPermissions) {
          const hasWritePermissions =
            (this.hasOrderCreatePermission ||
              this.hasOrderCreateAllPermission) &&
            (this.hasOrderDeletePermission || this.hasOrderDeleteAllPermission)
              || this.hasRoleExternalSales;

          if (hasWritePermissions) {
            return PERMISSION.frontendCheck.readWrite;
          }
          return PERMISSION.frontendCheck.readOnly;
        }
      }
      return PERMISSION.frontendCheck.none;
    },
    hasOrderReportPermission(): boolean {
      return (
        this.hasOrderReportCreateAllPermission &&
        this.hasOrderReportDeleteAllPermission &&
        this.hasOrderReportReadAllPermission
      );
    },
    hasReports(item: Order): boolean {
      return item.hasReports === true;
    },
    hasSampleReceptionPermission(): boolean {
      return (
        this.hasOrderUpdateAllPermission &&
        this.hasOrderSupportingFileReadAllPermission
      );
    },
    hasStatusManagementPermission(): boolean {
      return this.hasOrderUpdateAllPermission;
    },
    hasChangeOrderTestPermission(item: Order): boolean {
      return (
        this.hasOrderUpdateAllPermission &&
        item.statusId === ORDER_STATUS.orderSubmitted
      );
    },
    hasMoreOptions(item: Order): boolean {
      return (
        this.orderNotificationPermission(item) !== this.permissionNone ||
        this.hasOrderUserPermission(item) ||
        this.hasOrderReportPermission() ||
        this.hasStatusManagementPermission() ||
        this.hasChangeOrderTestPermission(item) ||
        this.hasDeleteOrderPermission(item)
      );
    },
  },
});
