















import Vue from "vue";
import NavBar from "@/components/navigation/NavBar";
import Header from "@/components/navigation/Header";
import Footer from "@/components/navigation/Footer";
import Toaster from "@/components/common/Toaster.vue";

export default Vue.extend({
  name: "App",
  components: {
    NavBar,
    Header,
    Footer,
    Toaster,
  },
  computed: {
    version() {
      return "QA " + process.env.VUE_APP_VERSION
    }
  }
});
