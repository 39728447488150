import { Log } from "../entities/log";
import { ILoggingService } from "./ilogging.service";
import { LoggingApiService } from "./logging-api.service";

export class LoggingService implements ILoggingService {

  private service: LoggingApiService;

  constructor(loggingApiService?: LoggingApiService) {
    this.service = loggingApiService || new LoggingApiService()
  }

  public async postLog(log: Log): Promise<void> {
    await this.service.postLog(log);
  }
}