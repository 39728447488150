import VueRouter, { RouteConfig } from 'vue-router'

import MergeAccount from "@/views/MergeAccount.vue";
import MyOrders from '@/views/MyOrders'
import MyPatients from '@/views/MyPatients'
import MyProfile from '@/views/MyProfile'
import NewOrder from '@/views/NewOrder'
import Order from '@/views/Order'
import Patient from '@/views/Patient'
import StyleGuide from '@/views/StyleGuide'
import Vue from 'vue'
import guardFactory from './roleguard'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: MyOrders
  },
  {
    path: '/myorders/:orderId?',
    name: 'My Orders',
    component: MyOrders
  },
  {
    path: '/order/new/',
    name: 'Select New Order Template',
    component: NewOrder
  },
  {
    path: '/order/new/:formId/:testId',
    name: 'Create Order',
    component: Order
  },
  {
    path: '/order/new/:formId',
    name: 'Create Order Without Test',
    component: Order
  },
  {
    path: '/order/edit/:orderId',
    name: 'Edit Order',
    component: Order
  },
  {
    path: '/mypatients',
    name: 'My Patients',
    component: MyPatients
  },
  {
    path: '/patient/new',
    name: 'Create Patient',
    component: Patient
  },
  {
    path: '/patient/edit/:patientId',
    name: 'Edit Patient',
    component: Patient
  },
  {
    path: '/styleguide',
    name: 'StyleGuide',
    component: StyleGuide
  },
  {
    path: '/myprofile',
    name: 'My Profile',
    component: MyProfile
  },
  {
    path: '/MergeAccount',
    name: 'MergeAccount',
    component: MergeAccount
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(guardFactory(router))

export default router
