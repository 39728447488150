import Vue from "vue";
import i18n from "@/plugins/i18n"
import {OrdersService} from "@/services/orders.service";
import {
  DELETE_ORDER,
  DELETE_ORDER_NOTIFICATION,
  DELETE_ORDER_SUPPORTING_FILE,
  FETCH_ORDER,
  FETCH_ORDER_CONSENT,
  FETCH_ORDER_NOTIFICATIONS,
  FETCH_ORDER_SUPPORTING_FILES,
  FETCH_ORDERS,
  FETCH_ORDERS_BY_PHYSICIANID,
  FETCH_ORDERS_BY_ORDERID,
  FETCH_ORDERS_BY_CEGATID,
  FETCH_ORDERS_STATUS,
  POST_ORDER,
  POST_ORDER_NOTIFICATION,
  POST_ORDER_SUPPORTING_FILE,
  PUT_ORDER,
  PUT_ORDER_SUPPORTING_FILE,
  RESET_ORDER,
  RESET_ORDERS,
  RESET_ORDER_SUPPORTING_FILES, FETCH_ORDER_SUMMARY,
} from "../types/actions.type";
import {
  SET_ORDER,
  SET_ORDER_PATIENT,
  SET_ORDER_NOTIFICATIONS,
  SET_ORDER_SUPPORTING_FILES,
  SET_ORDERS,
  SET_ORDERS_GRID_CONTENT_TYPE,
  SET_ORDERS_SEARCH_INPUT,
  SET_ORDERS_SEARCH_ORDERID,
  SET_ORDERS_SEARCH_CEGATID,
  SET_ORDERS_STATUS,
  INCREMENT_ORDERS_LOADING_COUNT,
  DECREMENT_ORDERS_LOADING_COUNT
} from "../types/mutations.type";
import {Order} from "@/entities/order";
import {OrderStatus} from "@/entities/order-status";
import NotificationMessageService from "@/services/notificationmessage.service";
import {FormTab} from "@/entities/form-tab";
import {FormControl} from "@/entities/form-control";
import {OrderSupportingFile} from "@/entities/order-supporting-file";
import {Notification} from "@/entities/notification";
import { Patient } from "@/entities/patient";
import { FormStep } from "@/entities/form-step";
import DateHelperMixin from "@/mixins/DateHelperMixin";
import LocalizationMixin from "@/mixins/LocalizationMixin";
import {CONSENT_FORM, FORM_FLAGS, ORDER_INFO_PAGE_OPTIONS} from '@/utils/constants'
import {CHECKBOX} from '@/entities/form-control-types'

const service = new OrdersService();
const dateHelperMixin = new DateHelperMixin();
const localizationMixin = new LocalizationMixin();

const initialState: any = {
  searchInput: '',
  searchOrderId: '',
  searchCegatId: '',
  loadingCount: 0,
  gridContentType: 'My Orders',
  orders: [],
  order: {
    orderId: 0,
    cegatId: '',
    orderDate: '',
    selectedTest: '',
    statusId: '',
    referenceId: '',
    labOrderId: '',
    comment: '',
    patientId: null,
    patientDateOfBirth: '',
    patientFirstName: '',
    patientLastName: '',
    ownerFirstName: '',
    ownerLastName: '',
    patientSexId: '',
    isOwner: true,
    formTestId: 0,
    formTestName: '',
    formTestTitle: '',
    form: null
  },
  ordersStatus: [],
  orderNotifications: [],
  supportingFiles: []
};

const state = JSON.parse(JSON.stringify(initialState));

const getters = {
  getOrders: (_state: any) => {
    let filteredOrders = JSON.parse(JSON.stringify(_state.orders));
    if (_state.searchInput) {
      const search = _state.searchInput.toLowerCase();
      filteredOrders = filteredOrders?.filter((order: Order) => {
        return order.orderId.toString().toLowerCase().includes(search) ||
          dateHelperMixin.getLocalTime(order.orderDate).includes(search) ||
          order.referenceId?.toLowerCase().includes(search) ||
          order.cegatId?.toLowerCase().includes(search) ||
          order.patientFirstName.toLowerCase().includes(search) ||
          order.patientLastName.toLowerCase().includes(search) ||
          order.patientDateOfBirth.includes(search) ||
          order.ownerFirstName?.toLowerCase().includes(search) ||
          order.ownerLastName?.toLowerCase().includes(search) ||
          localizationMixin.getPanelName(order, i18n.locale).toLowerCase().includes(search) ||
          i18n.t(_state.ordersStatus.find((o: OrderStatus) => o.id == order.statusId).name).toString().toLowerCase().includes(search)
      });
    }
    const isOwnerFilter = _state.gridContentType === 'My Orders';
    filteredOrders = filteredOrders.filter((order: Order) => order !== null)
    filteredOrders = filteredOrders.filter((order: Order) => order.isOwner === isOwnerFilter);

    return filteredOrders
  },
  getOrder: (_state: any) => {
    return _state.order
  },
  getOrderForm: (_state: any) => {
    return _state.order.form
  },
  getOrderFormTabById: (_state: any) => (tabId: string) => {
    return _state.order.form.tabs?.find((t: FormTab) => t.uniqueId === tabId);
  },
  getOrderFormStepBySortOrder: (_state: any) => (sortOrder: number) => {
    return _state.order.form.steps?.find((s: FormStep) => s.sortOrder === sortOrder);
  },
  getOrderFormControls: (_state: any) => {
    return _state.order.form.controls;
  },
  getOrderFormControlById: (_state: any) => (controlId: string) => {
    return _state.order.form.controls?.find((c: FormControl) => c.uniqueId === controlId);
  },
  getOrderFormControlByName: (_state: any) => (controlName: string) => {
    return _state.order.form.controls?.find((c: FormControl) => c.name === controlName);
  },
  getOrderFormControlsByName: (_state: any) => (controlName: string) => {
    return _state.order.form.controls?.filter((c: FormControl) => c.name === controlName);
  },
  getOrderFormTabsByFlag: (_state: any) => (flag: string) => {
    return _state.order.form.tabs?.filter((t: FormTab) => t.flag === flag);
  },
  getOrdersStatus: (_state: any) => {
    return _state.ordersStatus;
  },
  getOrdersPatient: (_state: any) => {
    const patient: Patient = {
      patientId: _state.order.patientId,
      firstName: _state.order.patientFirstName,
      lastName: _state.order.patientLastName,
      dateOfBirth: _state.order.patientDateOfBirth,
      sexId: _state.order.patientSexId,
      externalId: _state.order.referenceId
    };
    return patient;
  },
  getSearchInput(_state: any) {
    return _state.searchInput;
  },
  getSearchOrderId(_state: any) {
    return _state.searchOrderId;
  },
  getSearchCegatId(_state: any) {
    return _state.searchCegatId;
  },
  getGridContentType(_state: any) {
    return _state.gridContentType;
  },
  getOrderNotifications(_state: any) {
    return _state.orderNotifications
  },
  getOrderSupportingFiles(_state: any) {
    return _state.supportingFiles
  },
  getOrderFormTabByControlId: (_state: any) => (controlId: string) => {
    return _state.order.form.tabs?.find((t: FormTab) => t.controls?.find((c: string) => c === controlId));
  },
  getLoadingCount(_state: any) {
    return _state.loadingCount;
  },
  getOrderInfoPage(_state: any): string {
    const patientInfoTabControl = _state.order.form.controls.find((c: FormControl) =>
      c.type === CHECKBOX &&
      c.orderInfo &&
      c.value === true
    )
    if (patientInfoTabControl !== undefined) {
      return patientInfoTabControl.orderInfo.page
    }
    return ORDER_INFO_PAGE_OPTIONS.default
  },
}

const actions = {
  async [FETCH_ORDERS](context: any, forceUpdate?: boolean) {
    try {
      context.commit(INCREMENT_ORDERS_LOADING_COUNT);

      if (state.searchOrderId) {
        await context.dispatch(FETCH_ORDERS_BY_ORDERID, state.searchOrderId);
      } else if (state.searchCegatId) {
        await context.dispatch(FETCH_ORDERS_BY_CEGATID, state.searchCegatId);
      } else {
        const orders = await service.getOrders(forceUpdate);
        if (orders) {
          context.commit(SET_ORDERS, orders);
        }
      }
    } catch (_) {
      NotificationMessageService.showError(`Error while fetching orders`);
    } finally {
      context.commit(DECREMENT_ORDERS_LOADING_COUNT);
    }
  },
  async [FETCH_ORDERS_BY_PHYSICIANID](context: any, physicianId: string) {
    try {
      context.commit(INCREMENT_ORDERS_LOADING_COUNT);
      const orders = await service.getOrdersByPhysicianId(physicianId)
      if (orders) {
        context.commit(SET_ORDERS, orders);
      }
    } catch (_) {
      NotificationMessageService.showError(`Error while fetching orders`);
    } finally {
      context.commit(DECREMENT_ORDERS_LOADING_COUNT);
    }
  },
  async [FETCH_ORDERS_BY_ORDERID](context: any, orderId: string) {
    try {
      context.commit(INCREMENT_ORDERS_LOADING_COUNT);
      const orders = await service.getOrdersByOrderId(orderId)
      if (orders) {
        context.commit(SET_ORDERS, orders);
      }
    } catch (_) {
      NotificationMessageService.showError("Error while fetching orders!");
    } finally {
      context.commit(DECREMENT_ORDERS_LOADING_COUNT);
    }
  },
  async [FETCH_ORDERS_BY_CEGATID](context: any, cegatId: string) {
    try {
      context.commit(INCREMENT_ORDERS_LOADING_COUNT);
      const orders = await service.getOrdersByCegatId(cegatId)
      if (orders) {
        context.commit(SET_ORDERS, orders);
      }
    } catch (_) {
      NotificationMessageService.showError("Error while fetching orders!");
    } finally {
      context.commit(DECREMENT_ORDERS_LOADING_COUNT);
    }
  },
  async [FETCH_ORDER](context: any, [orderId, physicianId]: [number, string?]) {
    try {
      context.commit(INCREMENT_ORDERS_LOADING_COUNT);
      const order = await service.getOrder(orderId, physicianId);
      if (order) {
        context.commit(SET_ORDER, order);
        return
      }
    } catch (_) {
      if (!state.searchOrderId) NotificationMessageService.showError('Error while fetching order: {orderId}', {orderId: orderId});
    } finally {
      context.commit(DECREMENT_ORDERS_LOADING_COUNT);
    }
    context.dispatch(RESET_ORDER)
  },
  async [FETCH_ORDERS_STATUS](context: any, forceUpdate?: boolean) {
    try {
      const orders = await service.getOrdersStatus(forceUpdate);
      if (orders) {
        context.commit(SET_ORDERS_STATUS, orders);
      }
    } catch (_) {
      NotificationMessageService.showError(`Error while fetching orders status`);
    }
  },
  async [FETCH_ORDER_NOTIFICATIONS](context: any, orderId: number) {
    try {
      const notifications = await service.getOrderNotifications(orderId);
      if (notifications) {
        context.commit(SET_ORDER_NOTIFICATIONS, notifications);
      }
    } catch (_) {
      NotificationMessageService.showError('Error while fetching order notifications - order id: {orderId}', {orderId: orderId});
    }
  },
  async [POST_ORDER](context: any, physicianId?: string) {
    if (!state.order.orderId) {
      try {
        const order = await service.postOrder(state.order, physicianId)
        if (order) {
          NotificationMessageService.showSuccess(`New Order Recorded!`);
          context.commit(SET_ORDER, order);
          if (physicianId) {
            context.dispatch(FETCH_ORDERS_BY_PHYSICIANID, physicianId);
          } else {
            await context.dispatch(FETCH_ORDERS, true);
          }
        }
      } catch (_) {
        NotificationMessageService.showError('Error while creating new order: {order}', {order: state.order.orderId});
      }
    }
  },
  async [POST_ORDER_NOTIFICATION](context: any, postData: any) {
    if (postData && postData.orderId > 0 && postData.notification) {
      try {
        const result = await service.postOrderNotification(postData.orderId, postData.notification)
        if (!result) {
          return true
        }
      } catch (_) {
        NotificationMessageService.showError('Error while posting new order notification - order id: {orderId}', {orderId: postData.orderId});
      }
    }
    return false
  },
  async [PUT_ORDER](context: any, [showNotification, physicianId, recipientGroupIds]: [boolean, string?, number[]?]) {
    if (state.order.orderId > 0) {
      try {
        const order = await service.putOrder(state.order, physicianId, recipientGroupIds)
        if (order) {
          if (showNotification) {
            NotificationMessageService.showSuccess(`Order Information Updated!`);
          }
          if (physicianId) {
            context.dispatch(FETCH_ORDERS_BY_PHYSICIANID, physicianId);
          } else {
            await context.dispatch(FETCH_ORDERS, true);
          }
          return false
        }
      } catch (_) {
        if (showNotification) {
          NotificationMessageService.showError('Error while updating order: {order}', {order: state.order.orderId});
        }
      }
      return true
    }
    return null
  },
  async [DELETE_ORDER](context: any, [orderId, physicianId]: [number, string?]) {
    if (orderId > 0) {
      try {
        const err = await service.deleteOrder(orderId, physicianId)
        if (!err) {
          NotificationMessageService.showSuccess(`Order Deleted!`);
          if (physicianId) {
            context.dispatch(FETCH_ORDERS_BY_PHYSICIANID, physicianId);
          } else {
            await context.dispatch(FETCH_ORDERS, true);
          }
        }
      } catch (_) {
        NotificationMessageService.showError('Error while deleting order - id: {orderId}', {orderId: orderId});
      }
    }
  },
  async [DELETE_ORDER_NOTIFICATION](context: any, postData: any) {
    if (postData && postData.orderId > 0 && postData.notificationId) {
      try {
        const result = await service.deleteOrderNotification(postData.orderId, postData.notificationId)
        if (!result) {
          return true
        }
      } catch (_) {
        NotificationMessageService.showError('Error while deleting notification - id: {notificationId}, of order id: {orderId}', {notificationId: postData.notificationId, orderId: postData.orderId });
      }
    }
    return false
  },
  [SET_ORDERS_SEARCH_INPUT](context: any, searchInput: any) {
    context.commit(SET_ORDERS_SEARCH_INPUT, searchInput);
  },
  [SET_ORDERS_GRID_CONTENT_TYPE](context: any, gridContentType: any) {
    context.commit(SET_ORDERS_GRID_CONTENT_TYPE, gridContentType);
  },
  [SET_ORDER_PATIENT](context: any, patient: Patient) {
    const newOrder = JSON.parse(JSON.stringify(initialState.order));
    newOrder.patientId = patient.patientId;
    newOrder.patientDateOfBirth = patient.dateOfBirth;
    newOrder.patientFirstName = patient.firstName;
    newOrder.patientLastName = patient.lastName;
    newOrder.patientSexId = patient.sexId;
    newOrder.cegatId = patient.patientNumber;
    newOrder.referenceId = patient.externalId;
    context.commit(SET_ORDER, newOrder);
  },
  [RESET_ORDER](context: any) {
    context.commit(SET_ORDER, JSON.parse(JSON.stringify(initialState.order)));
  },
  [RESET_ORDERS](context: any) {
    context.commit(SET_ORDERS, []);
  },
  [RESET_ORDER_SUPPORTING_FILES](context: any) {
    context.commit(SET_ORDER_SUPPORTING_FILES, initialState.supportingFiles);
  },
  // Supporting Files
  async [FETCH_ORDER_SUPPORTING_FILES](context: any, orderId: number) {
    if (orderId > 0) {
      try {
        const files = await service.getOrderSupportingFiles(orderId);
        if (files != null) {
          context.commit(SET_ORDER_SUPPORTING_FILES, files);
        }
      } catch (_) {
        NotificationMessageService.showError('Error while fetching order supporting files - order id: {orderId}', {orderId: orderId});
      }
    } else {
      context.commit(SET_ORDER_SUPPORTING_FILES, []);
    }
  },
  async [POST_ORDER_SUPPORTING_FILE](context: any, data: any) {
    if (data && state.order.orderId > 0 && data.file) {
      try {
        const result = await service.postOrderSupportingFile(state.order.orderId, data.file, data.file.comment)
        if (result) {
          NotificationMessageService.showSuccess('New Supporting File Recorded! ({name})', {name: data.file.name});
          await context.dispatch(FETCH_ORDER_SUPPORTING_FILES, state.order.orderId);
        }
      } catch (_) {
        NotificationMessageService.showError('Error while posting new file - order id: {orderId}', {orderId: state.order.orderId});
      }
    } else {
      NotificationMessageService.showError(`Error while posting new file - expected input not provided`);
    }
  },
  async [PUT_ORDER_SUPPORTING_FILE](context: any, data: any) {
    if (data && state.order.orderId > 0 && data.orderFileId > 0 && data.file) {
      try {
        const result = await service.putOrderSupportingFile(state.order.orderId, data.orderFileId, data.file, data.file.comment)
        if (result) {
          NotificationMessageService.showSuccess(`Supporting File Updated!`);
          await context.dispatch(FETCH_ORDER_SUPPORTING_FILES, state.order.orderId);
        }
      } catch (_) {
        NotificationMessageService.showError('Error while updating file - order id: {orderId}', {orderId: state.order.orderId});
      }
    } else {
      NotificationMessageService.showError(`Error while updating file - expected input not provided`);
    }
  },
  async [DELETE_ORDER_SUPPORTING_FILE](context: any, data: any) {
    if (data && state.order.orderId > 0 && data.orderFileId > 0) {
      try {
        await service.deleteOrderSupportingFile(state.order.orderId, data.orderFileId)
        NotificationMessageService.showSuccess(`Supporting File Deleted!`);
        await context.dispatch(FETCH_ORDER_SUPPORTING_FILES, state.order.orderId);
      } catch (_) {
        NotificationMessageService.showError('Error while deleting file - id: {orderFileId}, of order id: {orderId}', {orderFileId: data.orderFileId, orderId: state.order.orderId});
      }
    } else {
      NotificationMessageService.showError(`Error while deleting file - expected input not provided`);
    }
  },
  async [FETCH_ORDER_CONSENT](context: any, data: any) {
    if (data && data.orderId > 0 && data.type && data.data) {
      try {
        await service.getOrderConsent(data.orderId, data.type, data.data, data.template || CONSENT_FORM.defaultTemplate)
      } catch (_) {
        NotificationMessageService.showError('Error while loading order consent form')
      }
    }
    else {
      NotificationMessageService.showError(`Error while loading order consent form - expected input not provided`)
    }
  },
  async [FETCH_ORDER_SUMMARY](context: any, data: any) {
    if (data && data.orderId > 0) {
      try {
        await service.getOrderSummary(data.orderId, data.languageId ?? 'en')
      } catch (_) {
        NotificationMessageService.showError('Error while loading order summary')
      }
    }
    else {
      NotificationMessageService.showError(`Error while loading order summary - expected input not provided`)
    }
  },

  [SET_ORDERS_SEARCH_ORDERID](context: any, searchOrderId: string) {
    context.commit(SET_ORDERS_SEARCH_ORDERID, searchOrderId);
  },
  [SET_ORDERS_SEARCH_CEGATID](context: any, searchCegatId: string) {
    context.commit(SET_ORDERS_SEARCH_CEGATID, searchCegatId);
  },
}

const mutations = {
  [SET_ORDERS](_context: any, orders: Order[]) {
    state.orders = [];
    for (const f in orders) {
      Vue.set(state.orders, f, orders[f]);
    }
  },
  [SET_ORDER](_context: any, order: Order) {
    Vue.set(state, 'order', order);
  },
  [SET_ORDERS_SEARCH_INPUT](_context: any, searchInput: any) {
    Vue.set(state, 'searchInput', searchInput);
  },
  [SET_ORDERS_SEARCH_ORDERID](_context: any, searchOrderId: any) {
    Vue.set(state, 'searchOrderId', searchOrderId);
  },
  [SET_ORDERS_SEARCH_CEGATID](_context: any, searchCegatId: any) {
    Vue.set(state, 'searchCegatId', searchCegatId);
  },
  [SET_ORDERS_GRID_CONTENT_TYPE](_context: any, gridContentType: any) {
    Vue.set(state, 'gridContentType', gridContentType);
  },
  [SET_ORDERS_STATUS](_context: any, ordersStatus: OrderStatus[]) {
    for (const f in ordersStatus) {
      Vue.set(state.ordersStatus, f, ordersStatus[f]);
    }
  },
  [SET_ORDER_NOTIFICATIONS](_context: any, orderNotifications: Notification[]) {
    if (!orderNotifications || orderNotifications.length === 0) {
      Vue.set(state, 'orderNotifications', []);
      return
    }
    Vue.set(state, 'orderNotifications', orderNotifications);
  },
  // Supporting Files
  [SET_ORDER_SUPPORTING_FILES](_context: any, files: OrderSupportingFile[]) {
    state.supportingFiles = [];
    for (const f in files) {
      Vue.set(state.supportingFiles, f, files[f]);
    }
  },
  // Loading Status
  [INCREMENT_ORDERS_LOADING_COUNT](_context: any) {
    state.loadingCount++;

  },
  [DECREMENT_ORDERS_LOADING_COUNT](_context: any) {
    state.loadingCount--;
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
