





























import Vue from "vue";
import Dialog from "@/components/dialogs/Dialog";
import Dropdown from "@/components/input-components/Dropdown.vue";

export default Vue.extend({
  name: "OrderTestChangeDialog",
  components: { Dialog, Dropdown },
  inheritAttrs: false,
  props: {
    tests: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    dataLoading: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
    order: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
  },
  computed: {
    saveInProgress(): boolean {
      return (this.$attrs && !!this.$attrs["save-in-progress"]) ?? false;
    },
    orderFormTestId(): string | null {
      return this.order?.formTestId ?? null;
    },
  },
});
