export function promisedTimeout(ms: number) {
  return new Promise((r) => setTimeout(r, ms))
}

export function stringEndsWith(str: string, suffix: string) {
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

export function convertFormBuilderSexValueToFrontendValue(sexId: string|undefined|null) {
  if (sexId === "Male") {
    return "m"
  }
  else if (sexId === "Female") {
    return "f"
  }

  return "u"
}

export function convertFrontendSexValueToFormBuilderValue(sexId: string|undefined|null) {
  if (sexId === "m") {
    return "Male"
  }
  else if (sexId === "f") {
    return "Female"
  }

  return "Unknown"
}
