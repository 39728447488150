















import Vue from "vue";
import Button from "@/components/input-components/Button";
import {Order} from "@/entities/order";
import {ORDER_STATUS} from "@/utils/constants";

export default Vue.extend({
  name: "OrderGridActionView",
  props: {
    order: {
      type: Object as () => Order,
      required: true,
    },
  },
  components: {
    Button,
  },
  methods: {
    showViewButton(): boolean {
      return (
        (this.hasOrderReadPermission || this.hasOrderReadAllPermission) && this.order.statusId !== ORDER_STATUS.draft);
    },
  },
});
