import AxiosStatic, { AxiosError } from "axios";
import { ErrorHandlerService } from '@/services/error-handler.service';
import Config from '@/config/config'

export const API_URL = Config.API_BASE_URL;

const axios = AxiosStatic.create()

axios.defaults.baseURL = API_URL

function updateToken(minValidity: number): Promise<boolean> {
  return new Promise((resolve, reject) => {
    axios._keycloak!.updateToken(minValidity).then(function(refreshed) {
      resolve(refreshed)
    }).catch(function () {
      reject()
    });
  });
}

axios.interceptors.request.use(async function (config) {
  if (!axios._keycloak) {
    console.warn('keycloak not set!')
    return config
  }

  if (!axios._keycloak.authenticated) {
    await axios._keycloak.login({locale: 'en'})
    return config
  }

  if (typeof (await axios._keycloak.getPassphrase()) === 'undefined') {
    await axios._keycloak.fetchPassphrase()
    // forced fetchPermissionsToken
    await axios._keycloak.getPermissionsToken(true)
  }

  // check token validity prior to sending request
  const forceUpdateToken = await updateToken(30);

  config.headers = { 'Authorization': `Bearer ${await axios._keycloak.getPermissionsToken(forceUpdateToken)}` }
  return config
})
axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err: AxiosError) => {
    /**
     * avoid infinite loop by skipping the handling/logging of
     * any error regarding the Logging Service
     */
    if (!err.request.responseURL.includes('/api/logs') && err?.config?.url !== '/logs') {
      ErrorHandlerService.handleError(err?.response?.data);
    }
    return Promise.reject(err?.response?.data);
  }
)
export default axios
