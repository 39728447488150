





































































import Vue from "vue";
import OrderConsentDownload from "@/components/orders/OrderConsentDownload";
import {ORDER_INFO_PAGE_SUFFIXES} from '@/utils/constants'

export default Vue.extend({
  name: "OrderSubmittedUsForm",
  computed: {
    ORDER_INFO_PAGE_SUFFIXES() {
      return ORDER_INFO_PAGE_SUFFIXES
    }
  },
  components: {
    OrderConsentDownload
  },
});
