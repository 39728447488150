import Vue from "vue";
import Vuex from "vuex";

import form from '@/store/modules/form.store'
import patient from '@/store/modules/patients.store'
import profile from '@/store/modules/profile.store'
import order from '@/store/modules/orders.store'
import notification from '@/store/modules/notification.store'
import orderNotification from '@/store/modules/order-notification.store'
import report from '@/store/modules/reports.store'
import supportingFile from '@/store/modules/supportingFiles.store'
import physician from '@/store/modules/physicians.store'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    form,
    patient,
    profile,
    order,
    notification,
    orderNotification,
    report,
    supportingFile,
    physician
  },
});
