import { ApiResponse } from "@/entities/api-response";
import { FormTestCategory } from "@/entities/form-test-category";
import { FormCategory } from "@/entities/form-category";
import { FormSimple } from "@/entities/form-simple";
import { FormSubcategory } from "@/entities/form-subcategory";
import { FormTest } from "@/entities/form-test";
import { JsonForm } from "@/entities/json-form";
import { FormsApiService } from "./forms-api.service";
import { IFormsService } from "./iforms.service";
import { FormTestSubcategory } from "@/entities/form-test-subcategory";
import { FormTestItem } from "@/entities/form-test-item";
import { FormControl } from "@/entities/form-control";
import { CHECKBOX } from "@/entities/form-control-types";
import {CONSENT_FORM} from '@/utils/constants'

export class FormsService implements IFormsService {

  private subcategories: Promise<FormCategory[]> | null = null;
  private formsCategories: Promise<FormTestCategory[]> | null = null;
  private service: FormsApiService;

  constructor(formsApiService?: FormsApiService) {
    this.service = formsApiService || new FormsApiService()
  }

  getForm(formId: string, testId: string): Promise<JsonForm> {
    return this.service.getForm(formId).then((res: ApiResponse) => {
      return res.content ? this.convertFormJsonApiData(res.content, testId) : res.content;
    });
  }
  getFormTests(formId: string): Promise<FormTest[]> {
    return this.service.getFormTests(formId).then((res: ApiResponse) => {
      return res.content ? this.sortTests(res.content) : res.content;
    });
  }
  getSubCategories(forceUpdate?: boolean): Promise<FormSubcategory[]> {
    if (!this.subcategories || forceUpdate) {
      this.subcategories = this.service.getSubCategories().then((res: ApiResponse) => res.content);
    }
    return this.subcategories
  }
  async getFormsCategories(forceUpdate?: boolean): Promise<FormTestCategory[]> {
    if (!this.formsCategories || forceUpdate) {
      return this.service.getFormsCategories().then((res: ApiResponse) => this.fixFormsCategoriesApiData(res.content));
    }
    return this.formsCategories;
  }

  private convertFormJsonApiData(data: any, testId: string): JsonForm {
    const convertedForm: JsonForm = {
      formConfig: {...data.formConfig, completedStep: 0},
      steps: [],
      tabs: [],
      controls: []
    };

    for (const key in data.sections) { // sections -> steps
      const s = data.sections[key];
      convertedForm.steps?.push({
        uniqueId: s.uniqueId,
        name: s.name,
        sortOrder: s.sortOrder,
        tabs: s.rows,
        valid: true,
        localizations: s.localizations || {},
      })
    }

    convertedForm.steps.sort((a: any, b: any) => {
      return a.sortOrder - b.sortOrder
    })

    for (const key in data.rows) { // rows -> tabs
      const r = data.rows[key];
      convertedForm.tabs?.push({
        uniqueId: r.uniqueId,
        name: r.name,
        flag: r.flag,
        controls: r.controls,
        localizations: r.localizations || {},
        showIf: r.showIf || [],
        template: r.template || CONSENT_FORM.defaultTemplate
      })
    }

    for (const key in data.controls) { // rows/tabs
      const c = data.controls[key];
      convertedForm.controls?.push({
        uniqueId: c.uniqueId,
        value: this.getTestDefaultValue(c, testId),
        gridValues: [],
        name: c.name,
        type: c.type,
        label: c.label || '',
        placeholderText: c.placeholderText || '',
        comment: c.comment || '',
        containerClass: c.containerClass || '',
        buttonType: c.buttonType || '',
        source: c.source || '',
        items: c.items || [],
        validations: c.validations || [],
        showIf: c.showIf || [],
        testLevelDefaults: c.testLevelDefaults || [],
        localizations: c.localizations || {},
        notificationTriggers: c.notificationTriggers || [],
        orderInfo: c.orderInfo,
        recipientGroups: c.recipientGroups || [],
      })
    }

    return convertedForm;
  }
  private getTestDefaultValue(c: FormControl, testId: string): any {
    const returnValue = c.type === CHECKBOX ? false : '';

    if (c.testLevelDefaults?.length <= 0) return returnValue
    return c.testLevelDefaults?.find((t: any) => t.testId === testId)?.value ?? returnValue;
  }
  private fixFormsCategoriesApiData(data: FormCategory[]): any {
    const formTestCategories: FormTestCategory[] = [];
    data.forEach((c: FormCategory) => { //categories
      const formTestCat: FormTestCategory = {
        categoryId: c.categoryId,
        name: c.name,
        subcategories: []
      }
      c.children?.forEach((s: any) => { //subcategories
        const formTestSub: FormTestSubcategory = {
          subcategoryId: s.categoryId,
          name: s.name,
          formTestItems: []
        }
        s.children.forEach((f: FormSimple) => { //form-test items

          f.tests = this.sortTests(f.tests);

          f.tests.forEach((t: FormTest) => {
            const formTestItem: FormTestItem = {
              formId: f.formId,
              formName: f.name,
              testId: t.formTestId,
              testName: t.name,
              testTitle: t.title,
            }
            formTestSub.formTestItems.push(formTestItem)
          })
        })
        if (formTestSub.formTestItems.length > 0) {
          formTestCat.subcategories.push(formTestSub)
        }
      })
      if (formTestCat.subcategories.length > 0) {
        formTestCategories.push(formTestCat)
      }
    })
    return formTestCategories;
  }

  private sortTests(tests: any[]): any[] {
    return tests.sort((a: FormTest, b: FormTest) => {
      return a.formTestId - b.formTestId
    })
  }
}
