

























import Vue from "vue";

export default Vue.extend({
  name: "Pagination",
  props: {
    value: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
  },
});
