//--Forms
export const FETCH_FORM = "fetchForm";
export const FETCH_FORM_TESTS = "fetchFormTests";
export const FETCH_FORMS_SUBCATEGORIES = "fetchFormSubcategories";
export const FETCH_FORMS_CATEGORIES = "fetchFormCategories";
export const RESET_FORM = "resetState";
//--Patients
export const FETCH_PATIENTS = "fetchPatients";
export const FETCH_PATIENTS_BY_PHYSICIANID = "fetchPatientsByPhysicianId";
export const FETCH_PATIENTS_BY_CEGATID = "fetchPatientsByCegatId";
export const FETCH_PATIENT = "fetchPatient";
export const PUT_PATIENT = "putPatient";
export const POST_PATIENT = "postPatient";
export const DELETE_PATIENT = "deletePatient";
export const FETCH_PATIENTS_SEXES = "fetchPatientsSexes";
export const RESET_PATIENT = "resetPatient";
export const RESET_PATIENTS = "resetPatients";
//--Physicians
export const FETCH_PHYSICIANS = "fetchPhysicians";
export const RESET_PHYSICIAN = "resetPhysician";
//--Orders
export const FETCH_ORDERS = "fetchOrders";
export const FETCH_ORDERS_BY_PHYSICIANID = "fetchOrdersByPhysicianId";
export const FETCH_ORDERS_BY_ORDERID = "fetchOrdersByOrderId";
export const FETCH_ORDERS_BY_CEGATID = "fetchOrdersByCegatId";
export const FETCH_ORDERS_STATUS = "fetchOrdersStatus";
export const FETCH_ORDER = "fetchOrder";
export const FETCH_ORDER_NOTIFICATIONS = "fetchOrderNotifications";
export const FETCH_ORDER_REPORT = "fetchOrderReport";
export const FETCH_ORDER_REPORT_LOADED_VALUE = "fetchOrderReportLoadedValue";
export const FETCH_ORDER_REPORTS = "fetchOrderReports";
export const PUT_ORDER = "putOrder";
export const POST_ORDER = "postOrder";
export const POST_ORDER_NOTIFICATION = "postOrderNotification";
export const INSERT_ORDER_REPORT = "insertOrderReport";
export const DELETE_ORDER = "deleteOrder";
export const DELETE_ORDER_NOTIFICATION = "deleteOrderNotification";
export const DELETE_REPORT = "deleteReport";
export const RESET_ORDER = "resetOrder";
export const RESET_ORDERS = "resetOrders";
export const FETCH_ORDER_CONSENT = "fetchOrderConsent";
export const FETCH_ORDER_SUMMARY = "fetchOrderSummary";

//--Order Supporting Files
export const FETCH_ORDER_SUPPORTING_FILES = "fetchOrderSupportingFiles";
export const POST_ORDER_SUPPORTING_FILE = "postOrderSupportingFile";
export const PUT_ORDER_SUPPORTING_FILE = "putOrderSupportingFile";
export const DELETE_ORDER_SUPPORTING_FILE = "deleteOrderSupportingFile";
export const RESET_ORDER_SUPPORTING_FILES = "resetOrderSupportingFiles";
//--Notifications
export const FETCH_NOTIFICATIONS = "fetchNotifications";
//--Profile
export const FETCH_PROFILE = "fetchProfile";
