import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes:
    {
      light: {
        primary: {
          base: '#1d4289',
          darken1: '#142f61',
        },
        secondary: {
          base: '#f7f7f7',
        },
        error: '#c93c2b',
        success: '#1d8955',
        red: '#c93c2b',
        white: '#fff',
        grey: '#f7f7f7',
        'grey-stroke': '#e1e1e1',
        'black-50': '#7c7c7c',
        'black-75': '#3e3e3e',
        black: '#000'
      }
    },
  }
})
