import axios from '@/config/api-config';
import {IOrderUsersService} from "@/services/iorderusers.service";

const orderUsersEndpoint = '/orderusers';

export class OrderUsersApiService implements IOrderUsersService {
  getOrderUsers(orderId: number): Promise<any> {
    return axios.get(`${orderUsersEndpoint}/${orderId}`).then(res => res.data)
  }

  postOrderUsers(orderId: number, email: string): Promise<any> {
    return axios.post(orderUsersEndpoint, {
      'orderId': orderId,
      'doctorEmail': email
    }).then(res => res.data)
  }

  deleteOrderUser(orderUserId: number): Promise<any> {
    return axios.delete(`${orderUsersEndpoint}/${orderUserId}`).then(res => res.data)
  }
}
