























































import Vue from "vue";
import Button from "@/components/input-components/Button";
import InputText from "@/components/input-components/InputText";
import PhysicianPickerWindow from "@/components/patients/PhysicianPickerWindow.vue";
import {
  FETCH_ORDERS,
  FETCH_ORDERS_BY_PHYSICIANID,
  FETCH_ORDERS_BY_ORDERID,
  FETCH_ORDERS_BY_CEGATID,
  RESET_PHYSICIAN,
  RESET_ORDERS,
  FETCH_PATIENTS_BY_PHYSICIANID,
} from "@/store/types/actions.type";
import {
  SET_PHYSICIAN,
  SET_ORDERS_SEARCH_ORDERID,
  SET_ORDERS_SEARCH_CEGATID,
  SET_ORDERS_SEARCH_INPUT,
} from "@/store/types/mutations.type";
import { Physician } from "@/entities/physician";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "OrdersToolbarInternal",
  components: {
    PhysicianPickerWindow,
    InputText,
    Button,
  },
  props: {
    orderIdPreFilter: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      orderId: this.orderIdPreFilter,
      cegatId: "",
      orderIdFocus: false,
    };
  },
  async mounted() {
    let physician = this.selectedPhysician;
    if (physician.userId !== "") {
      this.loadAllPhysicansAndPatients(physician);
    }

    if (this.orderId) {
      this.loadOrdersByOrderId();
    } else {
      this.orderId = this.getSearchOrderId;
      this.cegatId = this.getSearchCegatId;
      await this.$store.dispatch(`order/${FETCH_ORDERS}`);
    }
  },
  computed: {
    ...mapGetters("physician", ["getPhysician"]),
    ...mapGetters("order", ["getSearchOrderId", "getSearchCegatId"]),
    selectedPhysician(): Physician {
      return this.getPhysician;
    },
  },
  methods: {
    watchUserSubmit(event: KeyboardEvent) {
      if (event.key === "Enter") {
        this.loadOrdersByOrderId();
      }
    },
    watchUserSubmitCegatId(event: KeyboardEvent) {
      if (event.key === "Enter") {
        this.orderIdFocus
          ? this.loadOrdersByOrderId()
          : this.loadOrdersByCegatId();
      }
    },
    async loadAllPhysicansAndPatients(physician: any) {
      this.clearOrderId();
      this.clearCegatId();
      await this.$store.dispatch(
        `patient/${FETCH_PATIENTS_BY_PHYSICIANID}`,
        physician.userId
      );
      this.$store.dispatch(`physician/${SET_PHYSICIAN}`, physician);

      await this.$store.dispatch(
        `order/${FETCH_ORDERS_BY_PHYSICIANID}`,
        physician.userId
      );
    },
    async loadOrdersByOrderId(): Promise<void> {
      this.clearCegatId();
      this.clearPhysician();

      if (this.orderId) {
        await this.$store.dispatch(
          `order/${SET_ORDERS_SEARCH_ORDERID}`,
          this.orderId
        );
        await this.$store.dispatch(`order/${FETCH_ORDERS_BY_ORDERID}`, [this.orderId]);
      }
    },
    async loadOrdersByCegatId(): Promise<void> {
      this.orderIdFocus = false;
      this.clearPhysician();
      this.clearOrderId();
      if (this.cegatId) {
        await this.$store.dispatch(
          `order/${SET_ORDERS_SEARCH_CEGATID}`,
          this.cegatId
        );
        await this.$store.dispatch(`order/${FETCH_ORDERS_BY_CEGATID}`, [
          this.cegatId,
        ]);
      }
    },
    async onPhysicianSelected(physician: Physician): Promise<void> {
      this.loadAllPhysicansAndPatients(physician);
    },
    clearPhysician(): void {
      this.$store.dispatch(`physician/${RESET_PHYSICIAN}`);
      this.$store.dispatch(`order/${RESET_ORDERS}`);
      this.$store.dispatch(`order/${SET_ORDERS_SEARCH_INPUT}`, "");
    },
    clearOrderId(): void {
      this.orderId = "";
      this.$store.dispatch(`order/${SET_ORDERS_SEARCH_ORDERID}`, this.orderId);
    },
    clearCegatId(): void {
      this.cegatId = "";
      this.$store.dispatch(`order/${SET_ORDERS_SEARCH_CEGATID}`, this.cegatId);
    },
  },
});
