







































import Vue from "vue";
import Dialog from "@/components/dialogs/Dialog";
import Button from "@/components/input-components/Button.vue";
export default Vue.extend({
  name: "SubmitOrderShareDialog",
  components: { Dialog, Button },
  props: {
    allSharedUsers: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    selectedUser: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "controls",
          sortable: false,
        },
        {
          text: this.$t("Salutation") as string,
          value: "salutation",
          sortable: false,
        },
        {
          text: this.$t("Title") as string,
          value: "title",
          sortable: false,
        },
        {
          text: this.$t("First name") as string,
          value: "firstName",
          sortable: false,
        },
        {
          text: this.$t("Last name") as string,
          value: "lastName",
          sortable: false,
        },
        {
          text: this.$t("Email") as string,
          value: "email",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    selectedUsers(): any[] {
      return this.allSharedUsers.filter((u: any) => this.selectedUser.find(u2 => u2 === u.email || u2 === u.doctorEmail)).map((user: any) => ({
        salutation: user.doctorSalutation ? user.doctorSalutation : user.salutation,
        title: user.doctorTitle ? user.doctorTitle : user.title,
        firstName: user.doctorFirstName ? user.doctorFirstName : user.firstName,
        lastName: user.doctorLastName ? user.doctorLastName : user.lastName,
        email: user.doctorEmail ? user.doctorEmail : user.email
      }));
    }
  },
});
