

































import Vue from "vue";
import Dialog from "@/components/dialogs/Dialog";
import OrderReportDownload from "@/components/orders/OrderReportDownload.vue";
import OrderSummaryDownload from "@/components/orders/OrderSummaryDownload.vue";

export default Vue.extend({
  name: "OrderViewDialog",
  components: { OrderReportDownload, Dialog, OrderSummaryDownload },
  inheritAttrs: false,
  props: {
    dataLoading: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
    order: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
    reports: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    loadedValue: {
      type: Number,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      reportIsLoading: false as boolean,
    };
  },
  computed: {
    saveInProgress(): boolean {
      return (this.$attrs && !!this.$attrs["save-in-progress"]) ?? false;
    },
    orderStatusId(): string | null {
      return this.order?.statusId ?? null;
    },
  },
  methods: {
    loading(isLoading: boolean) {
      this.reportIsLoading = isLoading;
    },
  },
});
