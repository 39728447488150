














import Vue from "vue";

export default Vue.extend({
  name: "Button",
  inheritAttrs: false,
  props: {
    iconBtn: {
      type: Boolean,
      default: false,
      required: false,
    },
    iconWithBackground: {
      type: Boolean,
      default: false,
      required: false,
    },
    scroll: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    getColor(): string {
      let color = this.$attrs?.color;
      if (!color) {
        color = "primary";
      }

      if (color === "secondary") {
        color += " primary--text";
      } else if (color === "grey-stroke") {
        color += " black-75--text";
      }

      return color;
    },
    getElevation(): number {
      let elevation = this.$attrs?.elevation;
      if (!elevation) {
        elevation = this.iconBtn ? "0" : "1";
      }

      return parseInt(elevation);
    },
  },
  methods: {
    scrollToTop() {
      let ele = document.getElementById("main");
      window.scrollTo({ top: ele!.offsetTop, behavior: "smooth" });
    },
  },
});
