var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inner-content"},[_c('div',{staticClass:"submitted-content--bold mt-10",domProps:{"innerHTML":_vm._s(
      _vm.$t(
        'OrderInfo_information_on_next_steps' + _vm.ORDER_INFO_PAGE_SUFFIXES.us_form
      )
    )}}),_c('div',{domProps:{"innerHTML":_vm._s(
      _vm.$t(
        'OrderInfo_send_samples_to_address' + _vm.ORDER_INFO_PAGE_SUFFIXES.us_form
      )
    )}}),_c('div',{domProps:{"innerHTML":_vm._s(
      _vm.$t(
        'OrderInfo_address' + _vm.ORDER_INFO_PAGE_SUFFIXES.us_form
      )
    )}}),_c('div',{staticClass:"mt-10",domProps:{"innerHTML":_vm._s(
      _vm.$t(
        'OrderInfo_download_consent' + _vm.ORDER_INFO_PAGE_SUFFIXES.us_form
      )
    )}}),_c('div',[_c('OrderConsentDownload',{attrs:{"translation-key-suffix":_vm.ORDER_INFO_PAGE_SUFFIXES.us_form}})],1),_c('div',{staticClass:"submitted-content--bold mt-10",domProps:{"innerHTML":_vm._s(
      _vm.$t('OrderInfo_information_on_the_further_process' + _vm.ORDER_INFO_PAGE_SUFFIXES.us_form)
    )}}),_c('div',{domProps:{"innerHTML":_vm._s(
      _vm.$t(
        'OrderInfo_once_we_have_received_your_complete_order' + _vm.ORDER_INFO_PAGE_SUFFIXES.us_form
      )
    )}}),_c('div',{domProps:{"innerHTML":_vm._s(
      _vm.$t(
        'OrderInfo_you_will_receive_a_notification' + _vm.ORDER_INFO_PAGE_SUFFIXES.us_form
      )
    )}}),_c('div',{staticClass:"submitted-content--bold mt-10",domProps:{"innerHTML":_vm._s(
      _vm.$t('OrderInfo_need_help' + _vm.ORDER_INFO_PAGE_SUFFIXES.us_form)
    )}}),_c('div',{domProps:{"innerHTML":_vm._s(
      _vm.$t(
        'OrderInfo_in_case_of_any_questions' + _vm.ORDER_INFO_PAGE_SUFFIXES.us_form
      )
    )}})])}
var staticRenderFns = []

export { render, staticRenderFns }