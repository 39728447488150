










































import Vue from "vue";
import Button from "@/components/input-components/Button.vue";
import FileTooltip from "@/components/common/FileTooltip";
import {Report} from "@/entities/report";
import {FETCH_ORDER_REPORT, FETCH_ORDER_REPORT_LOADED_VALUE} from "@/store/types/actions.type";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;

export default Vue.extend({
  name: "OrderReportDownload",
  components: {Button, FileTooltip},
  props: {
    reports: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    loadedValue: {
      type: Number,
      required: false,
      default() {
        return null
      }
    },
    reportType: {
      type: String,
      required: false,
      default() {
        return 'report'
      }
    }
  },
  data() {
    return {
      loadingOrderFileId: null as number|null
    }
  },
  computed: {
    reportIsLoading(): boolean {
      const isLoading = this.loadingOrderFileId !== null
      this.$emit('isLoading', isLoading)
      return isLoading
    },
    getLoadedValue(): TranslateResult {
      return this.loadedValue < 1000 ? this.$t('Loaded: {loaded} kB', {loaded: this.loadedValue}) : this.$t('Loaded: {loaded} MB', {loaded: this.loadedValue / 1000})
    }
  },
  methods: {
    async downloadReport(report: Report) {
      this.loadingOrderFileId = report.orderFileId
      const that = this
      const interval = setInterval(function() {
        that.$store.dispatch(`${that.reportType}/${FETCH_ORDER_REPORT_LOADED_VALUE}`)
      }, 500)
      await this.$store.dispatch(`${that.reportType}/${FETCH_ORDER_REPORT}`, {orderId: report.orderId, orderFileId: report.orderFileId, fileName: report.fileName})
      clearInterval(interval)
      this.$store.dispatch(`${that.reportType}/${FETCH_ORDER_REPORT_LOADED_VALUE}`)
      this.loadingOrderFileId = null
    }
  }
});
