



































































import Vue from "vue";
import OrderConsentDownload from "@/components/orders/OrderConsentDownload";

export default Vue.extend({
  name: "OrderSubmittedDefault",
  components: {
    OrderConsentDownload
  },
});
