import { render, staticRenderFns } from "./ProfileDataSharing.vue?vue&type=template&id=5ef8b400&"
import script from "./ProfileDataSharing.vue?vue&type=script&lang=ts&"
export * from "./ProfileDataSharing.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileDataSharing.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VDivider,VIcon})
