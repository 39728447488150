import { Patient } from "@/entities/patient";
import { Sex } from "@/entities/sex";
import { IPatientsService } from "./ipatients.service";
import { PatientsApiService } from '@/services/patients-api.service';
import { ApiResponse } from "@/entities/api-response";

export class PatientsService implements IPatientsService {

  private sexes: Promise<Sex[]> | null = null;
  private patients: Promise<Patient[]> | null = null;
  private service: PatientsApiService;

  constructor(patientApiService?: PatientsApiService) {
    this.service = patientApiService || new PatientsApiService()
  }

  public getPatients(forceUpdate?: boolean): Promise<Patient[]> {
    if (!this.patients || forceUpdate) {
      this.patients = this.service.getPatients().then((res: ApiResponse) => res.content);
    }
    return this.patients
  }
  public getPatientsByCegatId(cegatId: string): Promise<Patient[]> {
    return this.service.getPatientsByCegatId(cegatId).then((res: ApiResponse) => res.content);
  }
  public getPatientsByPhysicianId(physicianId: string): Promise<Patient[]> {
    return this.service.getPatientsByPhysicianId(physicianId).then((res: ApiResponse) => res.content);
  }
  public getPatient(patientId: number, physicianId?: string): Promise<Patient> {
    return this.service.getPatient(patientId, physicianId).then((res: ApiResponse) => res.content);
  }
  public postPatient(patient: Patient, physicianId?: string): Promise<Patient> {
    return this.service.postPatient(patient, physicianId).then((res: ApiResponse) => res.content);
  }
  public putPatient(patient: Patient, physicianId?: string): Promise<Patient> {
    return this.service.putPatient(patient, physicianId).then((res: ApiResponse) => res.content);
  }
  public deletePatient(patientId: number, physicianId?: string): Promise<any> {
    return this.service.deletePatient(patientId, physicianId).then((res: ApiResponse) => res.content);
  }
  public getSexes(forceUpdate?: boolean): Promise<Sex[]> {
    if (!this.sexes || forceUpdate) {
      this.sexes = this.service.getSexes().then((res: ApiResponse) => res.content);
    }
    return this.sexes
  }
}
