import Vue from "vue";
import { PhysiciansService } from "@/services/physicians.service";
import { Physician } from '@/entities/physician';
import {
  FETCH_PHYSICIANS,
  RESET_PHYSICIAN,
} from "@/store/types/actions.type";
import {
  SET_PHYSICIANS_SEARCH_INPUT,
  SET_PHYSICIANS,
  SET_PHYSICIAN
} from "@/store/types/mutations.type";
import NotificationMessageService from "@/services/notificationmessage.service";

const service = new PhysiciansService();

const initialState: any = {
  searchInput: '',
  physicians: [],
  physician: {
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    city: '',
    isVerified: false,
  }
};

const state = JSON.parse(JSON.stringify(initialState));

const getters = {
  getPhysicians(_state: any) {
    if (_state.searchInput) {
      const search = _state.searchInput.toLowerCase();
      return _state.physicians?.filter((p: Physician) => {
        return p.firstName?.toLowerCase().includes(search) ||
          p.lastName?.toLowerCase().includes(search) ||
          p.email.toLowerCase().includes(search) ||
          p.city?.toLowerCase().includes(search)
      });
    } else {
      return _state.physicians
    }
  },
  getPhysician(_state: any) {
    return _state.physician
  },
  getSearchInput(_state: any) {
    return _state.searchInput;
  }
};

const actions = {
  async [FETCH_PHYSICIANS]({ commit }: any, forceUpdate?: boolean) {
    try {
      const physicians = await service.getPhysicians(forceUpdate);
      commit(SET_PHYSICIANS, physicians);
    } catch (_) {
      NotificationMessageService.showError("Error while fetching physicians!");
    }
  },
  [SET_PHYSICIAN]({ commit }: any, physician: Physician) {
    commit(SET_PHYSICIAN, physician);
  },
  [SET_PHYSICIANS_SEARCH_INPUT]({ commit }: any, searchInput: any) {
    commit(SET_PHYSICIANS_SEARCH_INPUT, searchInput);
  },
  [RESET_PHYSICIAN]({ commit }: any) {
    commit(SET_PHYSICIAN, initialState.physician);
  }
};

const mutations = {
  [SET_PHYSICIANS](_context: any, physicians: Physician[]) {
    state.physicians = [];
    for (const f in physicians) {
      Vue.set(state.physicians, f, physicians[f]);
    }
  },
  [SET_PHYSICIAN](_context: any, physician: Physician) {
    Vue.set(state, 'physician', physician);
  },
  [SET_PHYSICIANS_SEARCH_INPUT](_context: any, searchInput: string) {
    Vue.set(state, 'searchInput', searchInput);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
