































import Vue from "vue";

export default Vue.extend({
  name: "OrderNotification",
  props: {
    orderNotifications: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    notifications: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selectedNotificationState: this.orderNotifications?.length > 0,
      selectedNotifications: this.orderNotifications ?? [] as any,
      selectedNotificationsPlaceholder: [] as any,
    };
  },
  mounted() {
    this.selectedNotificationState = this.orderNotifications?.length > 0;
    this.selectedNotifications = this.getSelectedNotification();
    this.selectedNotificationsPlaceholder = this.selectedNotifications;
  },
  methods: {
    save(item: { notificationId: any; name: any; }) {
      let remove = {};
      let add = {};
      if (this.selectedNotificationsPlaceholder.includes(item.notificationId)) {
        remove = { name: item.name, notificationId: item.notificationId };
      } else {
        add = { name: item.name, notificationId: item.notificationId };
      }
      this.selectedNotificationsPlaceholder = this.selectedNotifications;
      this.selectedNotificationState = this.selectedNotificationsPlaceholder?.length > 0;
      this.$emit("save", { remove, add });
    },
    removeAllNotifications() {
      let remove : object[] = [];
      this.notifications.forEach((n: any) => {
        if (this.selectedNotificationsPlaceholder.includes(n.notificationId)) {
          remove.push({ name: n.name, notificationId: n.notificationId });
        }
      });
      this.selectedNotifications = this.getSelectedNotification();
      this.selectedNotificationState = this.selectedNotificationsPlaceholder?.length > 0;
      this.$emit("removeAllNotifications", remove);
    },
    getSelectedNotification(): any {
      return (this.orderNotifications?.map((n: any) => {
          return n.notificationId;
        }) ?? []
      );
    }
  },
  watch: {
    orderNotifications() {
      this.selectedNotificationsPlaceholder = this.selectedNotifications;
      this.selectedNotifications = this.getSelectedNotification();
    },
  },
});
