import {IOrderUsersService} from "@/services/iorderusers.service";
import {OrderUser} from "@/entities/order-user";
import {OrderUsersApiService} from "@/services/orderusers-api.service";
import {ApiResponse} from "@/entities/api-response";

export class OrderUsersService implements IOrderUsersService {
  private service: OrderUsersApiService;

  constructor(ordersApiService?: OrderUsersApiService) {
    this.service = ordersApiService || new OrderUsersApiService()
  }

  getOrderUsers(orderId: number): Promise<OrderUser[]> {
    return this.service.getOrderUsers(orderId).then((res: ApiResponse) => res.content)
  }

  postOrderUsers(orderId: number, email: string): Promise<any> {
    return this.service.postOrderUsers(orderId, email).then((res: ApiResponse) => res.content)
  }

  deleteOrderUser(orderUserId: number): Promise<any> {
    return this.service.deleteOrderUser(orderUserId).then((res: ApiResponse) => res.content)
  }
}
