

















import Vue from "vue";
import Radio from "@/components/input-components/Radio.vue";
import RadioGroup from "@/components/input-components/RadioGroup.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ToolbarContentSelector",
  components: {
    Radio,
    RadioGroup,
  },
  props: {
    options: {
      type: Array,
      required: true,
      validator: function (value: any) {
        return value.every((s: any) => typeof s == "string");
      },
    },
  },
  computed: {
    ...mapGetters("order", [
      "getGridContentType",
    ]),
    contentType(): string {
      return this.getGridContentType;
    },
  },
});
