import Config from "@/config/config"

export const supportedLanguages = ['en', 'de']

export default function getLanguageFromDomain() : string {
  let determinedLanguage = 'en'
  if (window.location.href && /(.*)\.com\/de(\/(.*))?$/.test(window.location.href)) {
    determinedLanguage = 'de'
  }

  if (Config.LANG && typeof(Config.LANG) === 'string' && supportedLanguages.indexOf(Config.LANG) >= 0) {
    determinedLanguage = Config.LANG
  }

  if (window.location.search) {
    const queryParams = new window.URLSearchParams(window.location.search)
    const langParam = queryParams.get('_lang')
    if (langParam && supportedLanguages.indexOf(langParam) >= 0) {
      determinedLanguage = langParam
    }
  }

  return determinedLanguage
}
