



































































































import { JsonForm } from "@/entities/json-form";
import Button from "@/components/input-components/Button.vue";
import Vue from "vue";
import { mapGetters } from "vuex";
import { FormTab } from "@/entities/form-tab";
import { FormStep } from "@/entities/form-step";
import { FormControl } from "@/entities/form-control";
import { Order } from "@/entities/order";
import { ORDER_STATUS } from "@/utils/constants";
import OrderSummaryDownload from "@/components/orders/OrderSummaryDownload.vue";
import { DROPDOWN, RADIO } from "@/entities/form-control-types";
import ControlRendererMixin from "@/mixins/ControlRendererMixin";
export default Vue.extend({
  name: "OrderSummary",
  components: {
    Button,
    OrderSummaryDownload
  },
  data() {
    return {
      activeTab: 0,
      selectedStepOrder: 1 as number,
      controlRendererMixin: new ControlRendererMixin(),
    };
  },
  computed: {
    ...mapGetters("order", [
      "getOrder",
      "getOrderForm",
      "getOrderFormTabById",
      "getOrderFormControlById",
      "getOrderFormStepBySortOrder"
    ]),
    order(): Order {
      return this.getOrder;
    },
    form(): JsonForm {
      return this.getOrderForm;
    },
    submitButtonText(): string {
      return this.isDraft ? "Submit order" : "Check order info";
    },
    isDraft(): boolean {
      return this.order.statusId === ORDER_STATUS.draft;
    },
  },
  methods: {
    removeColon(s: string): string {
      return s.endsWith(":") ? s.substring(0, s.length - 1) : s;
    },
    insertColon(control: FormControl): string {
      if (
        control.value.toString().toLowerCase() !== "true" &&
        control.label != ""
      ) {
        return ":";
      }
      return "";
    },
    getStepName(step: FormStep): string {
      return this.isLocalizationAvailable(step)
        ? step.localizations[this.$i18n.locale].name
        : step.name;
    },
    getTabName(tab: FormTab): string {
      return this.isLocalizationAvailable(tab)
        ? tab.localizations[this.$i18n.locale].name
        : tab.name;
    },
    getControlName(control: FormControl): string {
      return this.isLabelLocalizationAvailable(control)
        ? control.localizations[this.$i18n.locale].label
        : control.label;
    },
    getControlValue(control: FormControl): string {
      let isList =
        control.type.toLowerCase() == RADIO ||
        control.type.toLowerCase() == DROPDOWN;

      if (this.isLocalizationAvailable(control) && isList) {
        let index = control.items.findIndex(
          (item: any) => item === control.value
        );

        let localizedItem = control.localizations[this.$i18n.locale]?.items[index];
        if (localizedItem !== null) {
          return localizedItem;
        }
      }

      return control.value;
    },
    isLabelLocalizationAvailable(object: FormControl | FormTab): boolean {
      return (
        this.$i18n.locale !== "en" &&
        object.localizations[this.$i18n.locale]?.label !== undefined
      );
    },
    isLocalizationAvailable(object: FormControl | FormTab | FormStep): boolean {
      return (
        this.$i18n.locale !== "en" &&
        object.localizations[this.$i18n.locale] !== undefined
      );
    },
    showControl(control: FormControl): boolean {
      return this.controlRendererMixin.showControl(control);
    },
    showTab(tab: FormTab): boolean {
      return this.controlRendererMixin.showTab(tab);
    },
    showValue(controlId: string): boolean {
      const control: FormControl = this.getOrderFormControlById(controlId);
      if (!control) return false;
      return control.value.toString().toLowerCase() === "true" ? false : true;
    },
    cancelOrder(): void {
      this.$emit("cancelOrder");
    },
  },
});
