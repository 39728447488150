let windowConfig:any = {}
const untypedWindow : any = (window as any)
if (untypedWindow.__mycegat && untypedWindow.__mycegat.config) {
  windowConfig = untypedWindow.__mycegat.config
}

const config : CeGaTConfig = {
  API_BASE_URL: windowConfig.API_URL || process.env.VUE_APP_API_URL || undefined,
  KEYCLOAK_BACKENDCLIENTID: windowConfig.KEYCLOAK_BACKENDCLIENTID || process.env.VUE_APP_KEYCLOAK_BACKENDCLIENTID || undefined,
  KEYCLOAK_CLIENTID: windowConfig.KEYCLOAK_CLIENTID || process.env.VUE_APP_KEYCLOAK_CLIENTID || undefined,
  KEYCLOAK_BASE_URL: windowConfig.KEYCLOAK_URL || process.env.VUE_APP_KEYCLOAK_URL || undefined,
  KEYCLOAK_REALM_NAME: windowConfig.KEYCLOAK_REALM || process.env.VUE_APP_KEYCLOAK_REALM || undefined,
  KEYCLOAK_REALM_URL: windowConfig.KEYCLOAK_REALM_URL || process.env.VUE_APP_KEYCLOAK_REALMS_URL || undefined,
  LANG: windowConfig.LANG || "undefined"
}

const missingConfigKeys = Object.entries(config).filter(([_,v]) => !v).map(([k,_]) => k)
if (missingConfigKeys.length) {
  const el = window.document.getElementById('app')
  const message = 'MISSING CONFIGURATION: '+missingConfigKeys.join(', ')
  if (el) {
    el.innerHTML = '<div style="font-weight: bold; color: #c00;">'+message+'</div>'
  }
  throw new Error(message)
}

export default config
