













import Vue from "vue";
import InputText from "@/components/input-components/InputText";

export default Vue.extend({
  name: "ToolbarSearchInput",
  components: {
    InputText,
  },
  props: {
    placeholder: {
      type: String,
      required: false,
      default: "Search...",
    },
    searchInput: {
      type: String,
    },
  },
});
