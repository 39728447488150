import Vue from "vue";
import {PERMISSION, ROLES} from "@/utils/constants";

declare module 'vue/types/vue' {
  interface Vue {
    hasPermission(rsName: string, scope: string): boolean
    hasRole(roleName: string): boolean
    //Orders
    hasOrderReadPermission: boolean,
    hasOrderReadAllPermission: boolean,
    hasOrderCreatePermission: boolean
    hasOrderCreateAllPermission: boolean
    hasOrderUpdatePermission: boolean,
    hasOrderUpdateAllPermission: boolean,
    hasOrderDeletePermission: boolean
    hasOrderDeleteAllPermission: boolean
    //Orders Reports
    hasOrderReportReadAllPermission: boolean,
    hasOrderReportCreateAllPermission: boolean
    hasOrderReportDeleteAllPermission: boolean
    //Orders Supporting Files
    hasOrderSupportingFileReadAllPermission: boolean,
    //Patients
    hasPatientReadPermission: boolean,
    hasPatientReadAllPermission: boolean,
    hasPatientCreatePermission: boolean,
    hasPatientCreateAllPermission: boolean,
    hasPatientUpdatePermission: boolean,
    hasPatientUpdateAllPermission: boolean,
    hasPatientDeletePermission: boolean,
    hasPatientDeleteAllPermission: boolean,
    //Notifications
    hasNotificationReadPermission: boolean,
    hasNotificationReadAllPermission: boolean,
    //Roles
    hasRoleExternalUser: boolean,
    hasRoleExternalSales: boolean,
    hasRoleCegatVerified: boolean,
    hasRoleReportRelease: boolean
  }
}

export default Vue.extend({
  methods: {
    hasPermission(rsName: string, scope: string): boolean {
      const permissions = this.$keycloak.permissions_token_parsed?.authorization?.permissions ?? null
      if (!permissions) {
        return false
      }

      const permissionRs = permissions.find((p: any) => p.rsname === rsName)
      if (!permissionRs) {
        return false
      }

      return permissionRs.scopes.some((s: string) => s === scope)
    },
    hasRole(roleName: string): boolean {
      const roles = this.$keycloak.tokenParsed?.realm_access?.roles ?? null
      return roles?.some((p: string) => p === roleName) ?? false
    }
  },
  computed: {
    // Orders
    hasOrderReadPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.order, PERMISSION.scopes.read)
    },
    hasOrderReadAllPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.order, PERMISSION.scopes.readAll)
    },
    hasOrderCreatePermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.order, PERMISSION.scopes.create)
    },
    hasOrderCreateAllPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.order, PERMISSION.scopes.createAll)
    },
    hasOrderUpdatePermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.order, PERMISSION.scopes.update)
    },
    hasOrderUpdateAllPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.order, PERMISSION.scopes.updateAll)
    },
    hasOrderDeletePermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.order, PERMISSION.scopes.delete)
    },
    hasOrderDeleteAllPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.order, PERMISSION.scopes.deleteAll)
    },
    // Orders Reports
    hasOrderReportReadAllPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.orderReport, PERMISSION.scopes.readAll)
    },
    hasOrderReportCreateAllPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.orderReport, PERMISSION.scopes.createAll)
    },
    hasOrderReportDeleteAllPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.orderReport, PERMISSION.scopes.deleteAll)
    },
    // Orders Supporting Files
    hasOrderSupportingFileReadAllPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.orderSupportingFile, PERMISSION.scopes.readAll)
    },

    // Patients
    hasPatientReadPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.patient, PERMISSION.scopes.read)
    },
    hasPatientReadAllPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.patient, PERMISSION.scopes.readAll)
    },
    hasPatientCreatePermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.patient, PERMISSION.scopes.create)
    },
    hasPatientCreateAllPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.patient, PERMISSION.scopes.createAll)
    },
    hasPatientUpdatePermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.patient, PERMISSION.scopes.update)
    },
    hasPatientUpdateAllPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.patient, PERMISSION.scopes.updateAll)
    },
    hasPatientDeletePermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.patient, PERMISSION.scopes.delete)
    },
    hasPatientDeleteAllPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.patient, PERMISSION.scopes.deleteAll)
    },

    // Notifications
    hasNotificationReadPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.notification, PERMISSION.scopes.read)
    },
    hasNotificationReadAllPermission(): boolean {
      return this.hasPermission(PERMISSION.rsNames.notification, PERMISSION.scopes.readAll)
    },

    // Roles
    hasRoleExternalUser(): boolean {
      return this.hasRole(ROLES.externalUser)
    },
    hasRoleExternalSales(): boolean {
      return this.hasRole(ROLES.externalSales)
    },
    hasRoleCegatVerified(): boolean {
      return this.hasRole(ROLES.cegatVerified)
    },
    hasRoleReportRelease(): boolean {
      return this.hasRole(ROLES.reportRelease)
    }
  }
})
