















import Vue from "vue";
import PatientsToolbar from "@/components/patients/PatientsToolbar.vue";
import PatientsGrid from "@/components/patients/PatientsGrid";
import PatientsToolbarInternal from "@/components/patients/PatientsToolbarInternal.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MyPatients",
  components: {
    PatientsToolbarInternal,
    PatientsToolbar,
    PatientsGrid,
  },
  computed: {
    ...mapGetters("physician", ["getPhysician"]),
    ...mapGetters("patient", ["getSearchCegatId"]),
    isSearchingByPhysician(): boolean {
      return (
        this.getPhysician?.userId !== "" && this.getPhysician?.userId !== null
      );
    },
    isSearchingByCegatId(): boolean {
      return this.getSearchCegatId !== "" && this.getSearchCegatId !== null;
    },
    showPatientsGrid(): boolean {
      return (
        (this.hasPatientReadAllPermission && this.isSearchingByPhysician) ||
        (this.hasPatientReadAllPermission && this.isSearchingByCegatId) ||
        (this.hasPatientReadPermission && !this.hasPatientReadAllPermission)
      );
    },
  },
  methods: {
    showInternalToolbar(): boolean {
      return this.hasPatientReadAllPermission;
    },
  },
});
