

































































import Vue from "vue";
import Dialog from "@/components/dialogs/Dialog";
import { Notification } from "@/entities/notification";
import { PERMISSION } from "@/utils/constants";

export default Vue.extend({
  name: "OrderNotificationDialog",
  components: { Dialog },
  inheritAttrs: false,
  props: {
    statuses: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    dataLoading: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
    orderNotifications: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    notifications: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    permission: {
      type: String,
      required: false,
      default() {
        return PERMISSION.frontendCheck.none;
      },
    },
  },
  data() {
    return {
      selectedNotificationState: this.orderNotifications?.length > 0,
      selectedNotifications: this.orderNotifications ?? [],
    };
  },
  methods: {
    save() {
      const remove: Notification[] = [];
      const add: Notification[] = [];
      this.notifications.forEach((n: any) => {
        const inCurrentOrderNotifications =
          this.orderNotifications.find(
            (on: any) => on.notificationId === n.notificationId
          ) !== undefined;
        const inNewSelectedNotifications =
          this.selectedNotificationState &&
          this.selectedNotifications.find((s) => s === n.notificationId) !==
            undefined;
        if (inCurrentOrderNotifications && !inNewSelectedNotifications) {
          remove.push(n);
        } else if (!inCurrentOrderNotifications && inNewSelectedNotifications) {
          add.push(n);
        }
      });

      this.$emit("save", { remove, add });
    },
  },
  computed: {
    hasNoPermission(): boolean {
      return this.permission === PERMISSION.frontendCheck.none;
    },
    hasWritePermission(): boolean {
      return this.permission === PERMISSION.frontendCheck.readWrite;
    },
    saveInProgress(): boolean {
      return (this.$attrs && !!this.$attrs["save-in-progress"]) ?? false;
    },
  },
  watch: {
    orderNotifications() {
      this.selectedNotificationState = this.orderNotifications?.length > 0;
      this.selectedNotifications =
        this.orderNotifications?.map((n: any) => {
          return n.notificationId;
        }) ?? [];
    },
  },
});
