import axios from '@/config/api-config';
import { ApiResponse } from '@/entities/api-response';
import { Log } from "../entities/log";
import { ILoggingService } from "./ilogging.service";

const loggingApi = '/logs';

export class LoggingApiService implements ILoggingService {
  constructor() { }

  public postLog(log: Log): Promise<ApiResponse> {
    return axios.post(`${loggingApi}`, log).then(res => res.data);
  }
}