















































import Vue from "vue";
import Button from "@/components/input-components/Button.vue";
import { Notification } from "@/entities/notification";

export default Vue.extend({
  name: "ProfileNotification",
  components: { Button },
  props: {
    notifications: {
      type: Array as () => Array<Notification>,
      required: false,
      default() {
        return [];
      },
    },
    allNotifications: {
      type: Array as () => Array<Notification>,
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      currentNotifications: [],
    };
  },
  mounted() {
    this.$emit("reload-notifications");
  },
  methods: {
    handleCheckboxClick(payload: any): void {
      if (payload.checked) {
        this.$emit("add-notification", payload.notification);
      } else {
        this.$emit("remove-notification", payload.notification);
      }
    },
  },
  watch: {
    notifications: {
      immediate: true,
      handler(newValues) {
        let newArray = [] as any;
        newValues.forEach((notification: Notification) => {
          newArray[notification.notificationId] = true;
        });
        this.currentNotifications = newArray;
      },
    },
  },
});
