























import Vue from "vue";
import ToolbarSearchInput from "@/components/common/ToolbarSearchInput.vue";
import ToolbarContentSelector from "@/components/common/ToolbarContentSelector.vue";
import Button from "@/components/input-components/Button";
import { RESET_ORDER, RESET_ORDER_SUPPORTING_FILES } from "@/store/types/actions.type";
import { mapGetters } from "vuex";
import {
  SET_ORDERS_GRID_CONTENT_TYPE,
  SET_ORDERS_SEARCH_INPUT,
} from "@/store/types/mutations.type";

export default Vue.extend({
  name: "OrdersToolbar",
  components: {
    ToolbarSearchInput,
    ToolbarContentSelector,
    Button,
  },
  computed: {
    ...mapGetters("order", ["getSearchInput"]),
    ...mapGetters("physician", ["getPhysician"]),
    searchInput(): string {
      return this.getSearchInput;
    },
    isSearchingByPhysician(): boolean {
      return (
        this.getPhysician?.userId !== "" && this.getPhysician?.userId !== null
      );
    },
    showCreateOrderButton(): boolean {
      return (
        (this.hasOrderReadAllPermission && this.isSearchingByPhysician) ||
        (this.hasOrderReadPermission && !this.hasOrderReadAllPermission)
      );
    },
    hasOrderNewPermission(): boolean {
      return this.hasOrderCreatePermission || this.hasOrderCreateAllPermission;
    },
  },
  methods: {
    getGridSelectorOptions(): string[] {
      if (this.hasRoleCegatVerified || this.hasOrderReadAllPermission) {
        return ["My Orders", "Shared Orders"];
      } else {
        return ["My Orders"];
      }
    },
    createNew(): void {
      this.$store.dispatch(`order/${RESET_ORDER_SUPPORTING_FILES}`);
      this.$store.dispatch(`order/${RESET_ORDER}`).then(() => {
        this.$router.push({ path: `/order/new` });
      });
    },
    updateSearchInput(value: string): void {
      this.$store.dispatch(`order/${SET_ORDERS_SEARCH_INPUT}`, value);
    },
    selectGridContent(selection: string): void {
      if (this.getGridSelectorOptions().includes(selection)) {
        this.$store.dispatch(
          `order/${SET_ORDERS_GRID_CONTENT_TYPE}`,
          selection
        );
      }
    },
  },
});
