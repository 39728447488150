


















import Vue from "vue";
import ToolbarSearchInput from "@/components/common/ToolbarSearchInput.vue";
import Button from "@/components/input-components/Button";
import { mapGetters } from "vuex";
import { SET_PATIENTS_SEARCH_INPUT } from "@/store/types/mutations.type";
import { RESET_PATIENT } from "@/store/types/actions.type";

export default Vue.extend({
  name: "PatientsToolbar",
  components: {
    ToolbarSearchInput,
    Button,
  },
  computed: {
    ...mapGetters("patient", ["getSearchInput"]),
    ...mapGetters("physician", ["getPhysician"]),
    searchInput(): string {
      return this.getSearchInput;
    },
    isSearchingByPhysician(): boolean {
      return (
        this.getPhysician?.userId !== "" && this.getPhysician?.userId !== null
      );
    },
    showCreatePatientButton(): boolean {
      return (
        (this.hasPatientReadAllPermission && this.isSearchingByPhysician) ||
        (this.hasPatientReadPermission && !this.hasPatientReadAllPermission)
      );
    },
    hasPatientNewPermission(): boolean {
      return (
        this.hasPatientCreatePermission || this.hasPatientCreateAllPermission
      );
    },
  },
  methods: {
    updateSearchInput(value: any): void {
      this.$store.dispatch(`patient/${SET_PATIENTS_SEARCH_INPUT}`, value);
    },
    createNewPatient(): void {
      this.$store.dispatch(`patient/${RESET_PATIENT}`).then(() => {
        this.$router.push({ path: `/patient/new` });
      });
    },
  },
});
