import Vue from "vue";
import {ReportsService} from "@/services/reports.service";
import {
  DELETE_REPORT,
  FETCH_ORDER_REPORT,
  FETCH_ORDER_REPORT_LOADED_VALUE,
  FETCH_ORDER_REPORTS,
  INSERT_ORDER_REPORT
} from "../types/actions.type";
import {SET_ORDER_REPORT, SET_ORDER_REPORT_LOADED_VALUE, SET_ORDER_REPORTS} from "../types/mutations.type";
import {Report} from "@/entities/report";
import NotificationMessageService from "@/services/notificationmessage.service";

const service = new ReportsService();

const initialState: any = {
  reports: [],
  report: null,
  loadedValue: null
};

const state = JSON.parse(JSON.stringify(initialState));

const getters = {
  getReports: (_state: any) => {
    return _state.reports
  },
  getLoadedValue: (_state: any) => {
    return _state.loadedValue
  }
}

const actions = {
  async [FETCH_ORDER_REPORTS](context: any, orderId: number) {
    try {
      const reports = await service.getReports(orderId);
      if (reports) {
        context.commit(SET_ORDER_REPORTS, reports);
      }
    } catch (_) {
      console.warn(`error while fetching reports`)
    }
  },
  async [FETCH_ORDER_REPORT](context: any, fetchData: any) {
    if (fetchData && fetchData.orderId > 0 && fetchData.orderFileId > 0) {
      try {
        await service.getReport(fetchData.orderId, fetchData.orderFileId, fetchData.fileName)
      } catch (err) {
        err.status === 403 ? NotificationMessageService.showWarning("Not Enough Permissions"):NotificationMessageService.showError('Error while loading report')
        console.warn(`error while fetching file id ${fetchData.orderFileId} of order ${fetchData.orderId}`)
      }
    }
  },
  async [DELETE_REPORT](context: any, deleteData: any) {
    if (deleteData && deleteData.orderId > 0 && deleteData.orderFileId > 0) {
      try {
        const err = await service.deleteReport(deleteData.orderId, deleteData.orderFileId)
        if(!err) {
          return true
        }
      } catch (_) {
        console.warn(`error while deleting file id ${deleteData.orderFileId} of order ${deleteData.orderId}`)
      }
      return false
    }
    return null
  },
  async [INSERT_ORDER_REPORT](context: any, reportData: any) {
    if (reportData && reportData.orderId > 0 && reportData.report) {
      try {
        const err = await service.insertReport(reportData.orderId, reportData.report)
        if (err) {
          return true
        }
      } catch (_) {
        console.warn(`error while inserting report of order ${reportData.orderId}`)
      }
      return false
    }
    return null
  },
  [FETCH_ORDER_REPORT_LOADED_VALUE](context: any) {
    context.commit(SET_ORDER_REPORT_LOADED_VALUE, service.getLoadedValue())
  }
}

const mutations = {
  [SET_ORDER_REPORTS](_context: any, reports: Report[]) {
    state.reports = [];
    for (const f in reports) {
      Vue.set(state.reports, f, reports[f]);
    }
  },
  [SET_ORDER_REPORT](_context: any, report: Report) {
    Vue.set(state, 'report', report ?? null);
  },
  [SET_ORDER_REPORT_LOADED_VALUE](_context: any, loadedValue: number|null) {
    Vue.set(state, 'loadedValue', loadedValue ?? null);
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
