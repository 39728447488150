










































import Vue from "vue";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import Button from "@/components/input-components/Button.vue";
import { ProfileService } from "@/services/profile.service";
import NotificationMessageService from "@/services/notificationmessage.service";

export default Vue.extend({
  name: "ProfileAccountManagement",
  components: { ConfirmationDialog, Button },
  data() {
    return {
      deletionConfirmationDialog: false,
      profileService: new ProfileService(),
    };
  },
  methods: {
    handleDeletion() {
      if (this.$keycloak?.subject) {
        this.profileService
          .deleteUser(this.$keycloak.subject)
          .then(() => {
            NotificationMessageService.showSuccess(
              this.$t(
                "Deletion successfully. You will be logged out."
              ) as string
            );
            this.$keycloak.logout();
          })
          .catch(() => {
            NotificationMessageService.showError(
              this.$t("Something went wrong. Please try again later.") as string
            );
          });
      } else {
        NotificationMessageService.showError(
          this.$t("Something went wrong. Please try again later.") as string
        );
      }
    },
  },
});
