import Vue from "vue";
import {
  FETCH_PROFILE,
} from "../types/actions.type";
import {
  SET_PROFILE,
} from "../types/mutations.type";
import { ProfileService } from "@/services/profile.service";
import NotificationMessageService from "@/services/notificationmessage.service";
import { User } from "@/entities/user";

const service = new ProfileService();

const state: any = {
  profile: {},
};

const getters = {
  getProfile: (_state: any) => {
    return _state.profile;
  },
}

const actions = {
  async [FETCH_PROFILE](context: any, userId: string) {
    try {
      const profile = await service.getProfile(userId)
      if (profile) {
        context.commit(SET_PROFILE, profile);
      }
    } catch (_) {
      NotificationMessageService.showError("Profile data could not be loaded.");
    }
  }
}

const mutations = {
  [SET_PROFILE](_context: any, profile: User) {
    Vue.set(state, 'profile', profile);
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
