import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from "@/store";
import router from './router'
import i18n from './plugins/i18n'
import { CegatKeycloak } from './plugins/authentication'
import './scss/index.scss'
import permissions from "@/plugins/permissions";
import { LoggingService } from "@/services/logging.service";

const logService = new LoggingService();

const externalScript = document.createElement('script');
externalScript.setAttribute('src', './iframeResizer.contentWindow.min.js');
const internalScript = document.createElement("script");
internalScript.innerHTML = "window.iFrameResizer={targetOrigin: 'https://dev.cegat.com'}";
document.body.appendChild(internalScript);
document.body.appendChild(externalScript);

Vue.config.productionTip = false
Vue.config.errorHandler = function (err, vm, info) {
  const message = `Error: ${err.message}; At ${info} - ${(vm.$options as any)._componentTag}; ${err.stack}`;
  logService.postLog({ logLevel: "error", message: message });
}
Vue.mixin(permissions)

CegatKeycloak.init({
  onLoad: 'login-required',
  enableLogging: true
})
  .then(() => new Vue({
    router,
    vuetify,
    i18n,
    store,
    render: h => h(App)
  }).$mount('#app')
  )
  .catch((e) => {
    console.error(e)
  })
