var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isListScreenType)?[_c('div',{staticClass:"form-title"},[_vm._v(" "+_vm._s(_vm.$t("Share order title"))+" ")]),_c('div',{staticClass:"pa-2"})]:_vm._e(),(_vm.isLoading)?[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]:_c('div',{staticClass:"submitted-content-share-order"},[_c('div',{staticClass:"intro-container"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('Select whom to share order' + _vm.translationKeySuffix))}}),_c('div',{staticClass:"d-flex mt-5"},[_c('InputText',{staticClass:"col-8 mr-3 pa-0",attrs:{"label":_vm.$t('Email address'),"rules":_vm.emailRules,"required":""},model:{value:(_vm.emailAdressToAdd),callback:function ($$v) {_vm.emailAdressToAdd=$$v},expression:"emailAdressToAdd"}}),_c('Button',{attrs:{"color":"primary","disabled":!_vm.validEmail},on:{"click":function($event){return _vm.addEmail()}}},[_vm._v(" "+_vm._s(_vm.$t("Add"))+" ")])],1)]),_c('div',{staticClass:"sub-title mb-5"},[_vm._v(" "+_vm._s(_vm.$t("Quick Selection List"))+" ")]),_c('v-data-table',{staticClass:"share-order-table",attrs:{"headers":_vm.headers,"items":_vm.allSharedUsers,"items-per-page":-1,"mobile-breakpoint":"-1","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":item.isOwner,"value":item.doctorEmail ? item.doctorEmail : item.email},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})]}},{key:"item.salutation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.salutation ? item.salutation : item.doctorSalutation)+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title ? item.title : item.doctorTitle)+" ")]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName ? item.firstName : item.doctorFirstName)+" ")]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastName ? item.lastName : item.doctorLastName)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email ? item.email : item.doctorEmail)+" ")]}}])}),(_vm.isListScreenType)?_c('div',[_c('Button',{staticClass:"mt-6",attrs:{"color":"primary"},on:{"click":function($event){return _vm.closeSubmitOrderShareDialog('finish')}}},[_vm._v(" "+_vm._s(_vm.$t("Confirm consent & share order"))+" ")])],1):_vm._e(),[_c('SubmitOrderShareDialog',{attrs:{"title":_vm.$t('Confirm Share Order - Order {orderId}', {
            orderId: _vm.orderId.toString(),
          }),"allSharedUsers":_vm.allSharedUsers,"selectedUser":_vm.selectedUser,"width":'800'},on:{"cancel":_vm.closeSubmitOrderShareDialog,"closePopup":function($event){return _vm.closeSubmitOrderShareDialog('finish')}},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}})]],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }