import {ApiResponse} from "@/entities/api-response";
import {Order} from "@/entities/order";
import {OrderStatus} from "@/entities/order-status";
import {IOrdersService} from "./iorders.service";
import {OrdersApiService} from "./orders-api.service";
import {OrderSupportingFile} from "@/entities/order-supporting-file";
import {Notification} from "@/entities/notification";

export class OrdersService implements IOrdersService {

  private orders: Promise<Order[]> | null = null;
  private ordersStatus: Promise<OrderStatus[]> | null = null;
  private service: OrdersApiService;

  constructor(ordersApiService?: OrdersApiService) {
    this.service = ordersApiService || new OrdersApiService()
  }

  getOrders(forceUpdate?: boolean): Promise<Order[]> {
    if (!this.orders || forceUpdate) {
      this.orders = this.service.getOrders().then((res: ApiResponse) => res.content);
    }
    return this.orders
  }
  getOrdersByPhysicianId(physicianId: string): Promise<Order[]> {
    return this.service.getOrdersByPhysicianId(physicianId).then((res: ApiResponse) => res.content);
  }
  getOrdersByOrderId(orderId: string): Promise<Order[]> {
    return this.service.getOrdersByOrderId(orderId).then((res:ApiResponse) => res.content);
  }
  getOrdersByCegatId(cegatId: string): Promise<Order[]> {
    return this.service.getOrdersByCegatId(cegatId).then((res:ApiResponse) => res.content);
  }
  getOrder(orderId: number, physicianId?: string): Promise<Order> {
    return this.service.getOrder(orderId, physicianId).then((res: ApiResponse) => res.content);
  }
  postOrder(order: Order, physicianId?: string): Promise<Order> {
    return this.service.postOrder(order, physicianId).then((res: ApiResponse) => res.content);
  }
  postOrderNotification(orderId: number, notification: Notification): Promise<Order> {
    return this.service.postOrderNotification(orderId, notification).then((res: ApiResponse) => res.content);
  }
  putOrder(order: Order, physicianId?: string, recipientGroupIds?: number[]): Promise<Order> {
    return this.service.putOrder(order, physicianId, recipientGroupIds).then((res: ApiResponse) => res.content);
  }
  deleteOrder(orderId: number, physicianId?: string): Promise<any> {
    return this.service.deleteOrder(orderId, physicianId).then((res: ApiResponse) => res.content);
  }
  deleteOrderNotification(orderId: number, notificationId: string): Promise<any> {
    return this.service.deleteOrderNotification(orderId, notificationId).then((res: ApiResponse) => res.content);
  }
  getOrdersStatus(forceUpdate?: boolean): Promise<OrderStatus[]> {
    if (!this.ordersStatus || forceUpdate) {
      this.ordersStatus = this.service.getOrdersStatus().then((res: ApiResponse) => res.content);
    }
    return this.ordersStatus
  }
  getOrderNotifications(orderId: number): Promise<Notification[]> {
    return this.service.getOrderNotifications(orderId).then((res: ApiResponse) => res.content);
  }
  getOrderSupportingFiles(orderId: number): Promise<OrderSupportingFile[]> {
    return this.service.getOrderSupportingFiles(orderId).then((res: ApiResponse) => res.content);
  }
  getOrderSupportingFile(orderId: number, orderFileId: number): Promise<OrderSupportingFile> {
    return this.service.getOrderSupportingFile(orderId, orderFileId).then((res: ApiResponse) => res.content);
  }
  postOrderSupportingFile(orderId: number, file: File, comment?: string): Promise<OrderSupportingFile> {
    return this.service.postOrderSupportingFile(orderId, file, comment).then((res: ApiResponse) => res.content);
  }
  putOrderSupportingFile(orderId: number, orderFileId: number, file: File, comment?: string): Promise<OrderSupportingFile> {
    return this.service.putOrderSupportingFile(orderId, orderFileId, file, comment).then((res: ApiResponse) => res.content);
  }
  deleteOrderSupportingFile(orderId: number, orderFileId: number): Promise<any> {
    return this.service.deleteOrderSupportingFile(orderId, orderFileId).then((res: ApiResponse) => res.content);
  }
  getOrderConsent(orderId: number, type: string, data: string, template: string): Promise<void> {
    return this.service.getOrderConsent(orderId, type, data, template);
  }
  getOrderSummary(orderId: number, languageId: string = 'en'): Promise<void> {
    return this.service.getOrderSummary(orderId, languageId);
  }
}
