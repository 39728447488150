





















































import Vue from "vue";
import Button from "@/components/input-components/Button";
import Dialog from "@/components/dialogs/Dialog";
import InputText from "@/components/input-components/InputText";

export default Vue.extend({
  name: "ConfirmationDialog",
  components: { Dialog, Button, InputText },
  inheritAttrs: false,
  props: {
    message: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    deleteButton: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    confirmText: {
      type: String,
      required: false,
      default: "Ok",
    },
    cancelText: {
      type: String,
      required: false,
      default: "Cancel",
    },
    hasDeletionCheck: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      deleteConfirmation: "",
      rules: {
        deletioncheck: (value: string) =>
          (this.$t("deleteSecurityWord") as string) === value,
      },
    };
  },
  methods: {
    confirmDeletion(): void {
      if (this.deletionCheck) {
        this.deleteConfirmation = "";
        this.$emit("confirm");
      }
    },
  },
  computed: {
    deletionCheck(): boolean {
      return (
        (this.$t("deleteSecurityWord") as string) === this.deleteConfirmation
      );
    },
  },
});
