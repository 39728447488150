
































import Vue from "vue";
import Dialog from "@/components/dialogs/Dialog";
import OrderStatus from "@/components/orders/OrderStatus.vue";

export default Vue.extend({
  name: "StatusChangeDialog",
  components: {OrderStatus, Dialog},
  inheritAttrs: false,
  props: {
    statuses: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    dataLoading: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
    order: {
      type: Object,
      required: true,
      default() {
        return null
      }
    }
  },
  computed: {
    orderStatusId(): string|null {
      return this.order?.statusId ?? null
    },
    saveInProgress(): boolean {
      return (this.$attrs && !!this.$attrs['save-in-progress']) ?? false
    }
  }
});
