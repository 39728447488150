




































import Vue from "vue";
import Dialog from "@/components/dialogs/Dialog";
import Button from "@/components/input-components/Button.vue";
import OrderReportDownload from "@/components/orders/OrderReportDownload.vue";

export default Vue.extend({
  name: "ReportDownloadDialog",
  components: {OrderReportDownload, Button, Dialog},
  inheritAttrs: false,
  props: {
    dataLoading: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
    reports: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    loadedValue: {
      type: Number,
      required: false,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      reportIsLoading: false
    }
  },
  methods: {
    loading(isLoading: boolean) {
      this.reportIsLoading = isLoading
    }
  }
});
