




















import UserMenu from "@/components/navigation/UserMenu";
import Vue from "vue";
import { RESET_PHYSICIAN } from "@/store/types/actions.type";

export default Vue.extend({
  name: "NavBar",
  components: {
    UserMenu,
  },
  methods: {
    clearPhysician(): void {
      this.$store.dispatch(`physician/${RESET_PHYSICIAN}`);
    },
  },
  computed: {
    tab: {
      set() {},
      get() {
        if (
          this.$route.name === "Create Patient" ||
          this.$route.name === "Edit Patient" ||
          this.$route.name === "My Patients"
        ) {
          return "/MyPatients";
        } else {
          return "/MyOrders";
        }
      },
    },
    activeTab() {
      return this.$route.name === "My Profile";
    },
  },
});
