




































import Vue from "vue";
import {ORDER_INFO_PAGE_SUFFIXES} from '@/utils/constants'

export default Vue.extend({
  name: "OrderSubmittedNoSample",
  computed: {
    ORDER_INFO_PAGE_SUFFIXES() {
      return ORDER_INFO_PAGE_SUFFIXES
    }
  },
});
