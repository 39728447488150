var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.patients,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"loading":_vm.isLoading,"loading-text":_vm.$t('Loading Content'),"multi-sort":"","hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('ConfirmationDialog',{attrs:{"id":"deleteDialog","has-deletion-check":true,"title":_vm.$t('Delete - Patient {patientFullName}', {
              patientFullName: _vm.selectedPatientFullName,
            }),"message":_vm.$t("Are you sure you want to delete* this patient?")},on:{"confirm":_vm.deleteItemConfirm,"cancel":_vm.closeDeleteDialog},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}})]},proxy:true},{key:"item.firstName",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.sexId",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.getSexById(item.sexId).name))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('Button',{staticClass:"ma-1",attrs:{"color":"primary--text","title":_vm.$t('Edit patient'),"icon-btn":true,"icon-with-background":true,"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('Button',{staticClass:"ma-1",attrs:{"color":"primary--text","title":_vm.$t('Delete patient'),"icon-btn":true,"icon-with-background":true,"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1),(_vm.showStartPatientOrder)?_c('Button',{staticClass:"ma-1",attrs:{"color":"primary--text","title":_vm.$t('Start order'),"icon-btn":true,"icon-with-background":true,"small":""},on:{"click":function($event){return _vm.startPatientOrder(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-clipboard-plus ")])],1):_vm._e()]}}])}),_c('Pagination',{attrs:{"pageCount":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }