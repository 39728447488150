





























































































































































import Vue from "vue";
import Button from "@/components/input-components/Button.vue";
import InputText from "@/components/input-components/InputText.vue";
import Config from "@/config/config";
import NotificationMessageService from "@/services/notificationmessage.service";
import { EMAIL_REG_EXP } from "@/utils/constants";
import { ProfileService } from "@/services/profile.service";

export default Vue.extend({
  name: "ProfileAuthentication",
  components: { Button, InputText },
  props: {
    userProfile: {
      type: Object,
      required: true,
      default() {
        return {
          email: "",
        };
      },
    },
  },
  mounted() {
    window.addEventListener("message", this.closeEditPasswordDialog);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.closeEditPasswordDialog);
  },
  data() {
    return {
      userEditPasswordDialog: false,
      userEditEmailDialog: false,
      additionalEmailAdressToAdd: "",
      emailRules: [
        (v: string) =>
          !v ||
          EMAIL_REG_EXP.test(v) ||
          this.$t("This field must contain a valid email"),
      ],
      profileService: new ProfileService(),
    };
  },
  computed: {
    passwordPage(): string {
      if (this.userEditPasswordDialog) {
        let url = new URL("account/password", Config.KEYCLOAK_REALM_URL);
        url.searchParams.append("kc_locale", this.$i18n.locale);
        let parentUrl = new URL(parent.document.URL);
        url.searchParams.append("url", parentUrl.origin);
        return url.href;
      }

      return "about:blank";
    },
    emailPage(): string {
      if (this.userEditEmailDialog) {
        let url = new URL("request-email-change/", Config.KEYCLOAK_REALM_URL);
        url.searchParams.append("kc_locale", this.$i18n.locale);
        return url.href;
      }

      return "about:blank";
    },
    validAdditionalEmail(): boolean {
      return EMAIL_REG_EXP.test(this.additionalEmailAdressToAdd);
    },
    additionalEmails(): Array<string> {
      return this.userProfile.additionalEmail ?? [];
    },
  },
  watch: {
    userEditPasswordDialog: {
      immediate: false,
      handler(newValue: any) {
        if (!newValue) {
          this.$emit("reload-profile");
        }
      },
    },
    userEditEmailDialog: {
      immediate: false,
      handler(newValue: any) {
        if (!newValue) {
          this.$emit("reload-profile");
        }
      },
    },
  },
  methods: {
    closeEditPasswordDialog(event: any) {
      let url = new URL(Config.KEYCLOAK_BASE_URL);

      if (event.origin !== url.origin) return;

      if (event.data === "success") {
        this.userEditPasswordDialog = false;
        NotificationMessageService.showSuccess(
          "Your password has been updated."
        );
      }
    },
    addAdditionalEmail() {
      this.profileService
        .postAdditionalEmails(this.additionalEmailAdressToAdd)
        .then(() => {
          NotificationMessageService.showSuccess(
            this.$t(
              "You will receive an E-Mail. There you can add this E-Mail to your Account."
            ) as string
          );
          this.additionalEmailAdressToAdd = "";
        })
        .catch(() => {
          NotificationMessageService.showError(
            this.$t("The e-mail you entered is already registered.") as string
          );
        });
    },
    deleteAdditionalEmail(email: string) {
      this.profileService
        .deleteAdditionalEmails(email)
        .then(() => {
          NotificationMessageService.showSuccess(
            this.$t("E-Mail has been deleted!") as string
          );
          this.$emit("reload-profile");
        })
        .catch(() => {
          NotificationMessageService.showError(
            this.$t("Could not delete additional E-Mail.") as string
          );
        });
    },
  },
});
