//--Forms
export const SET_FORM = "setForm";
export const SET_FORM_TESTS = "setFormTests";
export const SET_FORMS_SUBCATEGORIES = "setFormSubcategories";
export const SET_FORMS_CATEGORIES = "setFormCategories";
//--Patients
export const SET_PATIENTS = "setPatients";
export const SET_PATIENT = "setPatient";
export const SET_PATIENTS_SEARCH_INPUT = "setPatientsSearchInput";
export const SET_PATIENTS_SEARCH_CEGATID = "setPatientsSearchCegatId";
export const SET_PATIENTS_SEXES = "setPatientsSexes";
//--Physicians
export const SET_PHYSICIANS = "setPhysicians";
export const SET_PHYSICIAN = "setPhysician";
export const SET_PHYSICIANS_SEARCH_INPUT = "setPhysiciansSearchInput";
//--Orders
export const SET_ORDERS = "setOrders";
export const SET_ORDER = "setOrder";
export const SET_ORDER_PATIENT = "setOrderPatient";
export const SET_ORDERS_SEARCH_INPUT = "setOrdersSearchInput";
export const SET_ORDERS_SEARCH_ORDERID = "setOrdersSearchOrderId";
export const SET_ORDERS_SEARCH_CEGATID = "setOrdersSearchCegatId";
export const SET_ORDERS_STATUS = "setOrdersStatus";
export const SET_ORDERS_GRID_CONTENT_TYPE = "setOrdersGridContent";
export const SET_ORDER_NOTIFICATIONS = "setOrderNotifications";
export const SET_ORDER_REPORT = "setOrderReport";
export const SET_ORDER_REPORT_LOADED_VALUE = "setOrderReportLoadedValue";
export const SET_ORDER_REPORTS = "setOrderReports";
//--Orders Loading Status
export const INCREMENT_ORDERS_LOADING_COUNT = "incrementOrdersLoadingCount";
export const DECREMENT_ORDERS_LOADING_COUNT = "decrementOrdersLoadingCount";
//--Order Supporting Files
export const SET_ORDER_SUPPORTING_FILES = "setOrderSupportingFiles";
//--Notifications
export const SET_NOTIFICATIONS = "setNotifications";
//--ToasterMessages
export const DELETE_MESSAGE = "removeToasterMessage";
export const ADD_MESSAGE = "addToasterMessage";
//--Profile
export const SET_PROFILE = "setProfile";
