import axios from '@/config/api-config';
import { IPatientsService } from "./ipatients.service";
import { Patient } from "@/entities/patient";
import { ApiResponse } from '@/entities/api-response';

const patientsApi = '/patients';

export class PatientsApiService implements IPatientsService {

  constructor() { }

  public getPatients(): Promise<ApiResponse> {
    return axios.get(`${patientsApi}`).then(res => res.data);
  }
  public getPatientsByCegatId(cegatId: string): Promise<ApiResponse> {
    return axios.get(`${patientsApi}?cegatId=${cegatId}`).then(res => res.data);
  }
  public getPatientsByPhysicianId(physicianId: string): Promise<ApiResponse> {
    return axios.get(`${patientsApi}?doctorId=${physicianId}`).then(res => res.data);
  }
  public getPatient(patientId: number, physicianId?: string): Promise<ApiResponse> {
    if (physicianId) {
      return axios.get(`${patientsApi}/${patientId}?doctorId=${physicianId}`).then(res => res.data);
    } else {
      return axios.get(`${patientsApi}/${patientId}`).then(res => res.data);
    }
  }
  public postPatient(patient: Patient, physicianId?: string): Promise<ApiResponse> {
    if (physicianId) {
      return axios.post(`${patientsApi}?doctorId=${physicianId}`, patient).then(res => res.data);
    } else {
      return axios.post(`${patientsApi}`, patient).then(res => res.data);
    }
  }
  public putPatient(patient: Patient, physicianId?: string): Promise<ApiResponse> {
    if (physicianId) {
      return axios.put(`${patientsApi}/${patient.patientId}/?doctorId=${physicianId}`, patient).then(res => res.data);
    } else {
      return axios.put(`${patientsApi}/${patient.patientId}`, patient).then(res => res.data);
    }
  }
  public deletePatient(patientId: number, physicianId?: string): Promise<ApiResponse> {
    if (physicianId) {
      return axios.delete(`${patientsApi}/${patientId}/?doctorId=${physicianId}`).then(res => res.data);
    } else {
      return axios.delete(`${patientsApi}/${patientId}`).then(res => res.data);
    }
  }
  public getSexes(): Promise<ApiResponse> {
    return axios.get(`${patientsApi}/sexes`).then(res => res.data);
  }

}
