import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {getAllTranslations} from '@/services/translations.service'
import getLanguageFromDomain from '@/utils/language'

Vue.use(VueI18n)
const i18n = new VueI18n()

export async function loadLocaleMessages() {
  const messages = await getAllTranslations()
  for (const key in messages) {
    i18n.setLocaleMessage(key, messages[key])
  }
}

// load initial messages
loadLocaleMessages()
const langFromDomain = getLanguageFromDomain()
i18n.locale = langFromDomain

export default i18n
