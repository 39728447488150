
















































































































import {
  DELETE_ORDER_NOTIFICATION,
  FETCH_NOTIFICATIONS,
  FETCH_ORDER_NOTIFICATIONS,
  POST_ORDER_NOTIFICATION,
} from "@/store/types/actions.type";
import Button from "@/components/input-components/Button";
import Vue from "vue";
import { mapGetters } from "vuex";
import { SHARE_ORDER_SCREEN_TYPE, ORDER_INFO_PAGE_OPTIONS } from "@/utils/constants";
import OrderNotification from "@/components/orders/OrderNotification";
import NotificationMessageService from "@/services/notificationmessage.service";
import ShareOrder from "@/components/orders/ShareOrder.vue";
import { Patient } from "@/entities/patient";
import OrderSubmittedDefault from '@/components/orders/OrderSubmittedDefault'
import OrderSubmittedUsForm from '@/components/orders/OrderSubmittedUsForm'
import OrderSubmittedNoSample from '@/components/orders/OrderSubmittedNoSample'

export default Vue.extend({
  name: "OrderSubmitted",
  components: {
    OrderSubmittedNoSample,
    OrderSubmittedUsForm,
    OrderSubmittedDefault,
    Button,
    OrderNotification,
    ShareOrder,
  },
  data: () => {
    return {
      activeTab: 0 as number,
      showShareOrderDialog: false,
      usersSelected: true,
    };
  },
  mounted() {
    this.fetchOrderNotifications();
  },
  computed: {
    SHARE_ORDER_SCREEN_TYPE() {
      return SHARE_ORDER_SCREEN_TYPE
    },
    ORDER_INFO_PAGE_OPTIONS() {
      return ORDER_INFO_PAGE_OPTIONS
    },
    ...mapGetters("order", [
      "getOrder",
      "getOrderInfoPage",
      "getOrderNotifications",
    ]),
    ...mapGetters("orderNotification", ["getNotifications"]),
    ...mapGetters("patient", ["getPatient"]),
    orderNotifications(): Notification[] {
      return this.getOrderNotifications;
    },
    notifications(): Notification[] {
      return this.getNotifications;
    },
    patient(): Patient {
      return this.getPatient;
    },
    orderId(): Number {
      return parseInt(this.$route?.params?.orderId ?? "-1")
    },
    orderInfoPage(): string {
      return this.getOrderInfoPage
    },
  },
  methods: {
    nextTab(): void {
      this.activeTab = this.activeTab + 1;
    },
    previousTab(): void {
      this.activeTab = this.activeTab - 1;
    },
    async removeAllNotifications(event: any): Promise<void> {
      for (let i = 0; i < event.length; i++) {
        const result = await this.$store.dispatch(
          `order/${DELETE_ORDER_NOTIFICATION}`,
          {
            orderId: this.$route.params.orderId,
            notificationId: event[i].notificationId,
          }
        );
        if (result) {
          NotificationMessageService.showSuccess(
            this.$t(`Order notification settings deleted!`) as string
          );
          this.fetchOrderNotifications();
        }
      }
    },
    async orderNotificationSave(args: any) {
      if (Object.keys(args.remove).length) {
        const result = await this.$store.dispatch(
          `order/${DELETE_ORDER_NOTIFICATION}`,
          {
            orderId: this.$route.params.orderId,
            notificationId: args.remove.notificationId,
          }
        );
        if (result) {
          NotificationMessageService.showSuccess(
            this.$t(`Order notification settings deleted!`) as string
          );
          args = {};
        }
      }
      if (Object.keys(args.add).length) {
        const result = await this.$store.dispatch(
          `order/${POST_ORDER_NOTIFICATION}`,
          {
            orderId: this.$route.params.orderId,
            notification: args.add,
          }
        );
        if (result) {
          NotificationMessageService.showSuccess(
            this.$t(`Order notification settings saved!`) as string
          );
          args = {};
        }
      }
    },
    async fetchOrderNotifications(): Promise<void> {
      await Promise.all([
        this.$store.dispatch(`orderNotification/${FETCH_NOTIFICATIONS}`),
        this.$store.dispatch(
          `order/${FETCH_ORDER_NOTIFICATIONS}`,
          this.$route.params.orderId
        ),
      ]);
    },
    closeSubmitOrderShareDialog(args: String|null) {
      this.showShareOrderDialog = false
      if (args === 'finish') {
        this.$emit('close')
      }
    },
    onFinishClick() {
      // show share dialog only if the users wants to share the order with at least one user
      if (this.usersSelected) {
        this.showShareOrderDialog = true
      }
      else {
        this.$emit('close')
      }
    },
    hasSelectedUsers(usersSelected: boolean) {
      this.usersSelected = usersSelected
    },
  },
});
