import Vue, {PluginObject} from 'vue'
import Keycloak from 'keycloak-js';
import AuthenticatedUser from '@/entities/authenticated-user'
import {KeycloakConfig, AuthenticationOptions} from '@/config/keycloak'
import AxiosStatic from 'axios'
import AuthenticatedAxios from '@/config/api-config'
import parse_jwt from '@/utils/parse-jwt'
import getLanguageFromDomain, {supportedLanguages} from '@/utils/language'
import { TranslationsService } from "@/services/translations.service";
import i18n from '@/plugins/i18n';

const translationsService = new TranslationsService();

declare module 'vue/types/vue' {
  interface VueConstructor {
    $keycloak: Keycloak
  }

  interface Vue {
    $keycloak: Keycloak
    user: AuthenticatedUser,
    userHasRole: (role: string) => boolean
  }
}

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    authentication?: AuthenticationOptions
  }
}

const fetchPermissionsToken = async function (this: Keycloak): Promise<object | undefined> {
  if (!this.authenticated || !this.token) {
    this.login({locale: 'en'})
    return
  }

  const params = new URLSearchParams();
  params.append('grant_type', 'urn:ietf:params:oauth:grant-type:uma-ticket')
  params.append('audience', KeycloakConfig.backendClientId)
  params.append('client_id', KeycloakConfig.clientId)

  const permissionTokenResult = await AxiosStatic.post(`${KeycloakConfig.url}realms/${KeycloakConfig.realm}/protocol/openid-connect/token`,
    params,
    {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    })

  this.permissions_token = permissionTokenResult.data.access_token
  this.permissions_token_parsed = parse_jwt(this.permissions_token)
  return this.permissions_token_parsed
}

Keycloak.prototype.fetchPermissionsToken = fetchPermissionsToken
Keycloak.prototype.getPermissionsToken = async function (this: Keycloak, forceUpdate = false): Promise<string | undefined> {
  if (forceUpdate || !this.authenticated || !this.permissions_token || this.isTokenExpired()) {
    await this.fetchPermissionsToken().catch(() => {
      this.logout()
    })
  }

  if (this.permissions_token) {
    return this.permissions_token
  }

  return undefined
}

Keycloak.prototype.fetchPassphrase = async function (this: Keycloak): Promise<string | undefined> {
  if (!this.authenticated || !this.token) {
    this.login({locale: 'en'})
    return
  }

  if (this.isTokenExpired()) {
    console.warn('token expired, triggering re-login')
    this.login({locale: 'en'})
    return
  }

  return AxiosStatic.put(`${KeycloakConfig.backendApi}/users/passphrase`,
    null, {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    })
}

/**
 * Set the frontend language to the locale that we get from Keycloak if available
 */
Keycloak.prototype.setLocale = function (): Promise<any> {
  const locale = supportedLanguages.includes(this.permissions_token_parsed.locale)
    ? this.permissions_token_parsed.locale
    : getLanguageFromDomain()
  i18n.locale = locale
  return translationsService.postCulture(locale);
}

Keycloak.prototype.getPassphrase = async function (this: Keycloak): Promise<string | undefined> {

  if (!this.permissions_token) {
    await this.getPermissionsToken()
  }
  return this.permissions_token_parsed?.passphrase ?? undefined
}

const CegatKeycloak = new Keycloak(KeycloakConfig)

CegatKeycloak.onAuthRefreshSuccess = CegatKeycloak.onAuthSuccess = function () {
  this.fetchPermissionsToken()
    .then(() => {
      this.setLocale()
    })
    .catch((err) => {
      this.logout()
    })
}

const AuthenticationPluginObject: PluginObject<AuthenticationOptions> = {
  install: function (vue) {
    vue.$keycloak = CegatKeycloak
    Vue.mixin({
      beforeCreate() {
        this.$keycloak.app = this
      }
    })
    AuthenticatedAxios._keycloak = vue.$keycloak

    Object.defineProperties(Vue.prototype, {
      $keycloak: {
        get() {
          return vue.$keycloak
        }
      },
      user: {
        get() {
          if (!vue.$keycloak.tokenParsed) {
            return null
          }

          return new AuthenticatedUser(vue.$keycloak.tokenParsed)
        }
      },
      backend_token: {
        value: null,
        writable: true
      }
    })
  }
}

// install
Vue.use(AuthenticationPluginObject, KeycloakConfig)
export {CegatKeycloak}
