import Vue from "vue";
import {
  FormsService
} from "@/services/forms.service";
import {
  FETCH_FORM,
  FETCH_FORMS_CATEGORIES,
  FETCH_FORMS_SUBCATEGORIES,
  FETCH_FORM_TESTS,
  RESET_FORM
} from "@/store/types/actions.type";
import {
  SET_FORM, SET_FORMS_CATEGORIES, SET_FORMS_SUBCATEGORIES, SET_FORM_TESTS,
} from "@/store/types/mutations.type";
import { JsonForm } from "@/entities/json-form";
import NotificationMessageService from "@/services/notificationmessage.service";

const service = new FormsService();

const initialState: any = {
  form: {
    formConfig: {
      uniqueId: '',
      name: '',
      category: '',
      subcategoryId: 0,
      completedStep: 0,
    },
    steps: [],
    tabs: [],
    controls: [],
  },
  formTests: [],
  formsSubcategories: [],
  formsCategories: [],
};

const state = JSON.parse(JSON.stringify(initialState));

const getters = {
  getForm: (_state: any) => {
    return _state.form as JsonForm;
  },
  getFormTests: (_state: any) => {
    return _state.formTests
  },
  getFormsCategories: (_state: any) => {
    return _state.formsCategories
  }
};

const actions = {
  async [FETCH_FORM](context: any, [formId, testId]: [string, string]) {
    if (formId) {
      try {
        const form = await service.getForm(formId, testId)
        if (form) {
          context.commit(SET_FORM, form);
        }
      } catch (_) {
        NotificationMessageService.showError('Error while fetching form - id: {formId}', {formId: formId});
      }
    }
  },
  async [FETCH_FORM_TESTS](context: any, formId: string) {
    if (formId) {
      try {
        const formTests = await service.getFormTests(formId)
        if (formTests) {
          context.commit(SET_FORM_TESTS, formTests);
        }
      } catch (_) {
        NotificationMessageService.showError('Error while fetching form tests - form id: {formId}', {formId: formId});
      }
    }
  },
  async [FETCH_FORMS_SUBCATEGORIES](context: any) {
    try {
      const formsSubcategories = await service.getSubCategories()
      if (formsSubcategories) {
        context.commit(SET_FORMS_SUBCATEGORIES, formsSubcategories);
      }
    } catch (_) {
      NotificationMessageService.showError(`Error while fetching forms subcategories.`);
    }
  },
  async [FETCH_FORMS_CATEGORIES](context: any) {
    try {
      const formsCategories = await service.getFormsCategories()
      if (formsCategories) {
        context.commit(SET_FORMS_CATEGORIES, formsCategories);
      }
    } catch (_) {
      NotificationMessageService.showError(`Error while fetching forms categories.`);
    }
  },
  [SET_FORM](context: any, form: any) {
    context.commit(SET_FORM, form);
  },
  [RESET_FORM](context: any) {
    context.commit(SET_FORM, JSON.parse(JSON.stringify(initialState)));
  }
};

const mutations = {
  [SET_FORM](_context: any, form: any) {
    for (const f in form) {
      Vue.set(state.form, f, form[f]);
    }
  },
  [SET_FORM_TESTS](_context: any, formTests: any) {
    state.formTests = [];
    for (const f in formTests) {
      Vue.set(state.formTests, f, formTests[f]);
    }
  },
  [SET_FORMS_CATEGORIES](_context: any, formsCategories: any) {
    state.formsCategories = [];
    for (const f in formsCategories) {
      Vue.set(state.formsCategories, f, formsCategories[f]);
    }
  },
  [SET_FORMS_SUBCATEGORIES](_context: any, formsSubcategories: any) {
    state.formsSubcategories = [];
    for (const f in formsSubcategories) {
      Vue.set(state.formsSubcategories, f, formsSubcategories[f]);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
