import axios from '@/config/api-config';
import { ApiResponse } from '@/entities/api-response';
import { IOrderNotificationsService } from "@/services/iorder-notifications.service";

const api = '/notifications';

export class OrderNotificationsApiService implements IOrderNotificationsService {
  constructor() { }

  getNotifications(): Promise<ApiResponse> {
    return axios.get(`${api}`).then(res => res.data);
  }
}
