















import Vue from "vue";
import Button from "@/components/input-components/Button";
import {ORDER_STATUS} from "@/utils/constants";
import {Order} from "@/entities/order";

export default Vue.extend({
  name: "OrderGridActionEdit",
  props: {
    order: {
      type: Object as () => Order,
      required: true,
    },
  },
  components: {
    Button,
  },
  methods: {
    showEditButton(): boolean {
      return (
        (this.hasOrderUpdatePermission &&
          this.order.statusId === ORDER_STATUS.draft) ||
        this.hasOrderUpdateAllPermission
      );
    },
    editOrder(): void {
      this.$router.push({ path: `/order/edit/${this.order.orderId}` });
    },
  },
});
