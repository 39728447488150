












import Vue from 'vue'
import Button from '@/components/input-components/Button.vue'
import {ProspectUserService} from '@/services/prospectuser.service'
import NotificationMessageService from "@/services/notificationmessage.service";

export default Vue.extend({
  name: 'MergeAccount',
  components: {
    Button
  },
  data() {
    return {
      mergeSuccessfully: false,
      prospectUserService: new ProspectUserService()
    }
  },
  mounted() {
    const query = this.$route.query
    if(query?.parameters) {
      this.prospectUserService.postMergeAccount(query?.parameters as string).then(()=> {
        NotificationMessageService.showSuccess(this.$t('Successfully added Email to your account .') as string)
        this.mergeSuccessfully = true
      }).catch(() => {
        NotificationMessageService.showError(this.$t('Please try again later.') as string)
      })
    } else {
      NotificationMessageService.showError(this.$t('Malformed link detected, please check the link in the email.') as string)
    }
  }
})
