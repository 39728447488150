














import Vue from "vue";
import Button from "@/components/input-components/Button";

export default Vue.extend({
  name: "OrderGridActionReportManagement",
  components: {
    Button,
  },
});
