import { ApiResponse } from "@/entities/api-response";
import { Notification } from "@/entities/notification";
import { OrderNotificationsApiService } from "@/services/order-notifications-api.service";
import { IOrderNotificationsService } from "@/services/iorder-notifications.service";

export class OrderNotificationsService implements IOrderNotificationsService {
  private notifications: Promise<Notification[]> | null = null;
  private service: OrderNotificationsApiService;

  constructor(orderNotificationsApiService?: OrderNotificationsApiService) {
    this.service = orderNotificationsApiService || new OrderNotificationsApiService()
  }

  getNotifications(forceUpdate?: boolean): Promise<Notification[]> {
    if (!this.notifications || forceUpdate) {
      this.notifications = this.service.getNotifications().then((res: ApiResponse) => res.content);
    }
    return this.notifications
  }
}
