export const TEXTBOX = "input";
export const TEXTAREA = "text";
export const LABEL = "label";
export const CHECKBOX = "checkbox";
export const LINK = "link";
export const DROPDOWN = "dropdown";
export const RADIO = "radio";
export const GRID = "grid";
export const IMAGE = "image";
export const DATE = "date";
export const BUTTON = "button";