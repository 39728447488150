





























import Vue from "vue";
import Dialog from "@/components/dialogs/Dialog";
import { POST_PATIENT } from "@/store/types/actions.type";
import { mapGetters } from "vuex";
import PatientCreation from "@/components/patients/PatientCreation";
import { Patient } from "@/entities/patient";

export default Vue.extend({
  name: "PatientCreationWindow",
  components: {
    Dialog,
    PatientCreation,
  },
  data() {
    return {
      isOpen: false as boolean,
    };
  },
  computed: {
    ...mapGetters("physician", ["getPhysician"]),
    getPhysicianId(): string {
      return this.getPhysician.userId;
    },
  },
  methods: {
    openCreationWindow(): void {
      this.isOpen = true;
    },
    closeCreationWindow(): void {
      this.isOpen = false;
    },
    tryCloseCreationWindow(): void {
      const patientCreation = this.$refs.patientCreation as any;
      patientCreation.cancel();
    },
    async saveNewPatient(patient: Patient): Promise<void> {
      const physId = this.getPhysicianId || "";
      await this.$store
        .dispatch(`patient/${POST_PATIENT}`, [patient, physId])
        .then(() => {
          this.closeCreationWindow();
          this.$emit("selectNewPatient");
        });
    },
  },
});
