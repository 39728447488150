import axios from '@/config/api-config';
import { ApiResponse } from '@/entities/api-response';
import { IFormsService } from './iforms.service';

const api = '/forms';
const apiFormsCategories = '/form-categories';

export class FormsApiService implements IFormsService {

  constructor() { }

  getForm(formId: string): Promise<ApiResponse> {
    return axios.get(`${api}/${formId}/json`).then(res => res.data);
  }
  getFormTests(formId: string): Promise<ApiResponse> {
    return axios.get(`${api}/${formId}/tests`).then(res => res.data);
  }
  getSubCategories(): Promise<ApiResponse> {
    return axios.get(`${api}/subCategories`).then(res => res.data);
  }
  getFormsCategories(): Promise<ApiResponse> {
    return axios.get(`${apiFormsCategories}`).then(res => res.data);
  }
}