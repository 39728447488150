





































































































import InputText from "@/components/input-components/InputText";
import Vue from "vue";
import Button from "@/components/input-components/Button.vue";
import SubmitOrderShareDialog from "@/components/dialogs/SubmitOrderShareDialog.vue";
import { ProfileService } from "@/services/profile.service";
import { OrderUsersService } from "@/services/orderusers.service";
import {EMAIL_REG_EXP, SHARE_ORDER_SCREEN_TYPE} from "@/utils/constants";
import NotificationMessageService from "@/services/notificationmessage.service";
import { Order } from "@/entities/order";
import { mapGetters } from "vuex";
import {OrderUser} from '@/entities/order-user'

export default Vue.extend({
  name: "ShareOrder",
  components: {
    InputText,
    Button,
    SubmitOrderShareDialog,
  },
  props: {
    showSubmitOrderShareDialog: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    orderId: {
      type: Number,
      required: true,
    },
    screenType: {
      type: String,
      required: true
    },
  },
  watch: {
    showSubmitOrderShareDialog: {
      handler() {
        this.showDialog = this.showSubmitOrderShareDialog
      },
      immediate: true
    },
    showDialog: {
      handler() {
        if (this.showDialog === false) {
          this.closeSubmitOrderShareDialog()
        }
      },
      immediate: false
    },
    selectedUser: {
      handler() {
        this.$emit('hasSelectedUsers', this.selectedUser.length > 0)
      },
      immediate: true
    }
  },
  data() {
    return {
      sharedUsersProfile: {} as { content: any },
      sharedUsersOrder: [] as Array<OrderUser>,
      allSharedUsers: [] as Array<{ [key: string]: unknown }>,
      profileService: new ProfileService(),
      orderUserService: new OrderUsersService(),
      selectedUser: [] as Array<string>,
      emailRules: [
        (v: string) =>
          !v ||
          EMAIL_REG_EXP.test(v) ||
          this.$t("This field must contain a valid email"),
      ],
      emailAdressToAdd: "" as string,
      showDialog: false,
      initialSelectedUsers: [] as Array<string>,
      isLoading: false,
      headers: [
        {
          text: "",
          value: "select",
          sortable: false,
        },
        {
          text: this.$t("Salutation") as string,
          value: "salutation",
          sortable: false,
        },
        {
          text: this.$t("Title") as string,
          value: "title",
          sortable: false,
        },
        {
          text: this.$t("First name") as string,
          value: "firstName",
          sortable: false,
        },
        {
          text: this.$t("Last name") as string,
          value: "lastName",
          sortable: false,
        },
        {
          text: this.$t("Email") as string,
          value: "email",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.getSharedUsersProfile();
  },
  methods: {
    async getSharedUsersProfile() {
      this.isLoading = true
      this.selectedUser = [];
      if (this.$keycloak?.subject) {
        this.sharedUsersProfile = await this.profileService.getSharedUsers();
        this.sharedUsersOrder = await this.orderUserService.getOrderUsers(
          this.orderId
        );
        //check and remove email from sharedUserProfile if already saved in sharedUserOrder
        this.sharedUsersOrder.forEach((el) => {
          if (
            this.sharedUsersProfile.content.some(
              (user: { email: any }) => user.email === el.doctorEmail
            )
          ) {
            this.sharedUsersProfile.content =
              this.sharedUsersProfile.content.filter(
                (sharedUser: { email: any }) =>
                  sharedUser.email !== el.doctorEmail
              );
          }
          //removed loggedin user from list
          this.sharedUsersOrder = this.sharedUsersOrder.filter(
            (el) => el.doctorEmail !== this.user.email
          );
          this.allSharedUsers = this.sharedUsersProfile.content.concat(
            this.sharedUsersOrder
          );
        });
        this.sharedUsersOrder.forEach((el) => {
          this.selectedUser.push(el.doctorEmail);
        });
      }

      this.initialSelectedUsers = [...this.selectedUser]
      this.isLoading = false
    },
    addEmail() {
      //check if email already in allSharedUser or empty and if not put it in
      if (
        !this.allSharedUsers.some(
          (e) =>
            e.email === this.emailAdressToAdd ||
            e.doctorEmail === this.emailAdressToAdd
        ) &&
        this.validEmail
      ) {
        this.allSharedUsers.unshift({ email: this.emailAdressToAdd });
        this.selectedUser.push(this.emailAdressToAdd);
        this.emailAdressToAdd = "";
        NotificationMessageService.showSuccess(
          this.$t("E-Mail has been added. Don't forget to confirm!") as string
        );
      } else {
        NotificationMessageService.showError(
          this.$t("E-Mail already in use or Input field is empty.") as string
        );
      }
    },
    async closeSubmitOrderShareDialog(args = null) {
      if (args === 'finish') {
        const removeUsers = this.initialSelectedUsers.filter(iu => !this.selectedUser.includes(iu))
        const addUsers = this.selectedUser.filter(su => !this.initialSelectedUsers.includes(su))
        let error = false
        for (const email of addUsers) {
          await this.orderUserService
            .postOrderUsers(this.orderId, email)
            .catch(() => {
              error = true
            });
        }

        for (const email of removeUsers) {
          const user = this.sharedUsersOrder.find(asu => asu.doctorEmail === email)
          if (user !== undefined) {
            await this.orderUserService
              .deleteOrderUser(user.orderUserId)
              .catch(() => {
                error = true
              });
          }
        }

        if (error) {
          NotificationMessageService.showError(
            this.$t("An error occurred while sharing the order. Please check under \"Share order\".") as string
          );
        }
        else if (!error && this.isListScreenType) {
          NotificationMessageService.showSuccess(
            this.$t("Consent confirmed and order successfully shared") as string
          );
        }
      }

      this.$emit('closeSubmitOrderShareDialog', args)
    },
  },
  computed: {
    SHARE_ORDER_SCREEN_TYPE() {
      return SHARE_ORDER_SCREEN_TYPE
    },
    ...mapGetters("order", ["getOrder"]),
    validEmail(): boolean {
      return EMAIL_REG_EXP.test(this.emailAdressToAdd);
    },
    order(): Order {
      return {} as Order;
    },
    isListScreenType(): boolean {
      return this.screenType === SHARE_ORDER_SCREEN_TYPE.list
    },
    translationKeySuffix(): string {
      let keySuffix = ""
      if (this.isListScreenType) {
        keySuffix = " (list)"
      }
      return keySuffix
    },
  },
});
