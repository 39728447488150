


























import Vue from "vue";

export default Vue.extend({
  name: "InputText",
  inheritAttrs: false,
  props: {
    multiline: {
      type: Boolean,
      default: false,
      required: false
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  data() {
    return {
      originalType: 'text',
      showPw: false
    }
  },
  beforeMount() {
    this.originalType = this.$attrs?.type ?? 'text'
  },
  computed: {
    getBindings(): object {
      let bindings: {[key: string]:any} = {}
      if (this.originalType === 'password') {
        bindings['type'] = this.showPw ? 'text' : 'password'
        bindings['append-icon'] = this.showPw ? 'mdi-eye' : 'mdi-eye-off'
      }
      else if(this.originalType === 'search') {
        bindings['append-icon'] = 'mdi-magnify'
      }
      return {...this.$attrs, ...bindings}
    },
    getListeners(): object {
      let customHandlers: {[key: string]:any} = {}
      if (this.originalType === 'password') {
        const that = this
        customHandlers['click:append'] = function() {
          that.showPw = !that.showPw
        }
      }

      return {...this.$listeners, ...customHandlers}
    }
  }
});
