





















































































































































































import Vue from "vue";
import {FormControl} from "@/entities/form-control";
import {mapGetters} from "vuex";
import {
  BUTTON,
  CHECKBOX,
  DATE,
  DROPDOWN,
  GRID,
  IMAGE,
  LABEL,
  LINK,
  RADIO,
  TEXTAREA,
  TEXTBOX,
} from "@/entities/form-control-types";
import InputText from "@/components/input-components/InputText";
import Dropdown from "@/components/input-components/Dropdown";
import Button from "@/components/input-components/Button";
import DatePicker from "@/components/input-components/DatePicker.vue";
import {CONSENT_FORM, EMAIL_REG_EXP} from "@/utils/constants";
import {OrderSupportingFile} from "@/entities/order-supporting-file";
import SampleUploadDialog from "@/components/dialogs/SampleUploadDialog.vue";
import {
  DELETE_ORDER_SUPPORTING_FILE,
  FETCH_ORDER_CONSENT,
  POST_ORDER_SUPPORTING_FILE,
  PUT_ORDER,
  PUT_ORDER_SUPPORTING_FILE,
} from "@/store/types/actions.type";
import {promisedTimeout, stringEndsWith} from "@/utils";
import NotificationMessageService from "@/services/notificationmessage.service";
import ControlRendererMixin from "@/mixins/ControlRendererMixin";

export default Vue.extend({
  name: "ControlRenderer",
  components: {Button, Dropdown, InputText, DatePicker, SampleUploadDialog },
  props: {
    controlId: {
      type: String,
      required: true,
    },
    tabId: {
      type: String,
      required: false,
      default() {
        return ""
      }
    }
  },
  data() {
    return {
      TEXTBOX,
      TEXTAREA,
      LABEL,
      CHECKBOX,
      LINK,
      DROPDOWN,
      RADIO,
      GRID,
      IMAGE,
      DATE,
      BUTTON,
      controlText: {} as FormControl,
      saveInProgress: false as boolean,
      errorOnSave: false as boolean,
      showSampleUploadDialog: false as boolean,
      controlRendererMixin: new ControlRendererMixin(),
    };
  },
  computed: {
    ...mapGetters("order", [
      "getOrder",
      "getOrderForm",
      "getOrderFormControlById",
      "getOrderFormControlByName",
      "getOrderSupportingFiles",
      "getOrderFormTabById",
      "getOrderFormTabByControlId",
      "getOrderFormControlsByName",
    ]),
    control(): FormControl {
      return this.getOrderFormControlById(this.controlId);
    },
    supportingFiles(): OrderSupportingFile[] {
      return this.getOrderSupportingFiles;
    },
    orderId(): string {
      return this.getOrder.orderId;
    },
    getDropdownItems(): any {
      const items = this.control.items.map((value, index) => (
        {text: this.controlText.items[index] ?? value, value: value}
      ))
      return items
    }
  },
  watch: {
    control: {
      handler() {
        if (this.$i18n.locale !== 'en' && this.control?.localizations !== undefined) {
          const localizations = this.control?.localizations[this.$i18n.locale] ?? {};

          // Special "items" logic:
          // Sometimes the translations of some items may be missing (= null).
          // In this case we replace null values with the original english label.
          const nonNullItems = localizations.items?.map((item: any, index: any) => {
            if (item === null && this.control.items && this.control.items[index]) {
              return this.control.items[index];
            }
            return item;
          });

          const updatedLocalizations = { ...localizations, items: nonNullItems };
          this.controlText = { ...this.control, ...updatedLocalizations };

        }
        else {
          this.controlText = {...this.control}
        }
      },
      immediate: true
    }
  },
  methods: {
    gridAddNewLine(value: FormControl): void {
      let newLine: string[] = [];
      value.items.forEach(() => {
        newLine.push("");
      });
      if (!value.gridValues) {
        value.gridValues = [[]];
      }
      value.gridValues.push(newLine);
    },
    getControlLabel(control: FormControl): string {
      let controlLabel = control.label;
      if (control.validations?.find((v) => v.ruleType === "required")) {
        controlLabel += "*";
      }
      return controlLabel;
    },
    getFieldRules(control: FormControl): any[] {
      let rules: any[] = [];

      // Append the rules only if the control is shown
      if (this.showControl(control)) {
        if (control.validations.find((v) => v.ruleType === "required")) {
          rules.push(
            (value: string) => !!value || this.$t("This field is required")
          );
        }
        if (control.validations.find((v) => v.ruleType === "numbersOnly")) {
          let regexp = /^\d*$/;
          rules.push(
            (value: string) =>
              regexp.test(value) ||
              this.$t("This field must contain only numbers")
          );
        }
        if (control.validations.find((v) => v.ruleType === "textOnly")) {
          let regexp = /^[a-zA-Z ]*$/;
          rules.push(
            (value: string) =>
              regexp.test(value) ||
              this.$t("This field must contain only letters")
          );
        }
        if (control.validations.find((v) => v.ruleType === "isEmail")) {
          rules.push(
            (value: string) =>
              EMAIL_REG_EXP.test(value) ||
              !value ||
              this.$t("This field must contain a valid e-mail")
          );
        }
      }

      return rules;
    },
    showControl(control: FormControl): boolean {
      return this.controlRendererMixin.showControl(control);
    },
    async onButtonClick(control: FormControl): Promise<void> {
      if (control.buttonType === "upload") {
        this.showSampleUploadDialog = true;
      }

      if (stringEndsWith(control.name, CONSENT_FORM.downloadBtnNameSuffix)) {
        const type =
          control.name?.replace(CONSENT_FORM.downloadBtnNameSuffix, "") ?? null;
        if (!type) {
          NotificationMessageService.showError("Consent form type not found");
          return;
        }

        const tab = this.getOrderFormTabByControlId(control.uniqueId)
        if (!tab) {
          NotificationMessageService.showError("Consent form tab not found");
          return;
        }

        let template = tab.template || CONSENT_FORM.defaultTemplate

        let language = CONSENT_FORM.defaultLanguage;
        const languageControls = this.getOrderFormControlsByName(
          `${type}${CONSENT_FORM.languageNameSuffix}`
        );
        if (languageControls.length > 0) {
          const languageControl = languageControls.find((c: any) => tab.controls.includes(c.uniqueId));
          if (languageControl) {
            language = languageControl.value || language;
          }
        }

        const payload = {
          orderId: this.getOrder.orderId,
          type,
          data: {
            language,
            formData: this.getOrderForm,
          },
          template,
        };
        await this.$store.dispatch(`order/${FETCH_ORDER_CONSENT}`, payload);
      }
    },
    closeSampleUploadDialog() {
      this.showSampleUploadDialog = false;
    },
    async deleteSupportingFiles(
      supportingFiles: OrderSupportingFile[]
    ): Promise<boolean> {
      let errorOnSave = false;
      const removeLength = supportingFiles.length;
      for (let i = 0; i < removeLength; i++) {
        const supportingFile = supportingFiles[i] as OrderSupportingFile;
        const result = await this.$store.dispatch(
          `order/${DELETE_ORDER_SUPPORTING_FILE}`,
          {
            orderFileId: supportingFile.orderFileId,
          }
        );
        if (!errorOnSave && result === false) {
          errorOnSave = true;
        }

        if (i % 5 === 0) {
          await promisedTimeout(300);
        }
      }

      return errorOnSave;
    },
    async addNewSupportingFiles(supportingFiles: any): Promise<boolean> {
      let errorOnSave = false;
      let addLength = supportingFiles.length;

      for (let i = 0; i < addLength; i++) {
        let result;

        const orderToReplace = this.supportingFiles.find(
          (f: any) => f.fileName === supportingFiles[i].name
        );

        if (orderToReplace) {
          result = await this.$store.dispatch(
            `order/${PUT_ORDER_SUPPORTING_FILE}`,
            {
              orderFileId: orderToReplace.orderFileId,
              file: supportingFiles[i],
            }
          );
        } else {
          result = await this.$store.dispatch(
            `order/${POST_ORDER_SUPPORTING_FILE}`,
            { file: supportingFiles[i] }
          );
        }

        if (!errorOnSave && result === false) {
          errorOnSave = true;
        }

        if (i % 5 === 0) {
          await promisedTimeout(300);
        }
      }

      return errorOnSave;
    },
    sampleUploadSave(args: any) {
      this.saveInProgress = true;
      this.errorOnSave = false;
      Promise.all([
        this.deleteSupportingFiles(args.remove),
        this.addNewSupportingFiles(args.add),
        this.$store.dispatch(`order/${PUT_ORDER}`, [false]),
      ]).then((values) => {
        if (values.every((v) => v === false)) {
          this.closeSampleUploadDialog();
          NotificationMessageService.showSuccess("Samples Information Saved!");
        } else {
          this.errorOnSave = true;
        }
        this.saveInProgress = false;
      });
    },
  },
});
