












































































import Vue from "vue";
import {
  DELETE_PATIENT,
  FETCH_PATIENTS,
  FETCH_PATIENTS_SEXES,
} from "@/store/types/actions.type";
import {
  SET_PATIENT,
  SET_ORDER_PATIENT,
  SET_PATIENTS_SEARCH_INPUT,
} from "@/store/types/mutations.type";
import { mapGetters } from "vuex";
import { Patient } from "@/entities/patient";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import Pagination from "@/components/common/Pagination.vue";
import DateHelperMixin from "@/mixins/DateHelperMixin";
import Button from "@/components/input-components/Button";

export default Vue.extend({
  name: "PatientsGrid",
  components: {
    ConfirmationDialog,
    Pagination,
    Button,
  },
  data() {
    return {
      showDeleteDialog: false,
      selectedPatientId: -1,
      selectedPatientFullName: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      isLoading: false,
      dateHelperMixin: new DateHelperMixin(),
    };
  },
  created() {
    this.$store.dispatch(`patient/${SET_PATIENTS_SEARCH_INPUT}`, "");
  },
  mounted() {
    this.fetchSexes();
  },
  computed: {
    ...mapGetters("patient", ["getPatients", "getSearchCegatId", "getSexById"]),
    ...mapGetters("physician", ["getPhysician"]),
    headers(): any[] {
      return [
        {
          text: this.$t("Patient name"),
          align: "start",
          sortable: true,
          value: "firstName",
        },
        {
          text: this.$t("CeGaT ID"),
          value: "patientNumber",
          sortable: true,
        },
        {
          text: this.$t("Patient d.o.b"),
          value: "dateOfBirth",
          sort: (a: string, b: string) => this.dateHelperMixin.sortDate(a, b),
        },
        {
          text: this.$t("sex"),
          value: "sexId",
          sortable: true,
        },
        {
          text: this.$t("Actions"),
          value: "actions",
          sortable: false,
          width: 165,
        },
      ];
    },
    patients(): Patient[] {
      return this.getPatients;
    },
    getPhysicianId(): string {
      return this.getPhysician.userId;
    },
    isSearchingByCegatId(): boolean {
      return this.getSearchCegatId !== "" && this.getSearchCegatId !== null;
    },
    showStartPatientOrder(): boolean {
      return this.hasRoleExternalUser || !this.isSearchingByCegatId;
    },
  },
  methods: {
    async fetchSexes(): Promise<void> {
      this.isLoading = true;
      await this.$store.dispatch(`patient/${FETCH_PATIENTS_SEXES}`);
      this.fetchPatients();
    },
    async fetchPatients(): Promise<void> {
      if (this.hasPatientReadPermission && !this.hasPatientReadAllPermission) {
        await this.$store.dispatch(`patient/${FETCH_PATIENTS}`, true);
      }
      this.isLoading = false;
    },
    editItem(item: Patient) {
      this.$router.push({ path: `/patient/edit/${item.patientId}` });
    },
    deleteItem(item: Patient) {
      this.selectedPatientId = item.patientId;
      this.selectedPatientFullName = item.firstName + " " + item.lastName;
      this.showDeleteDialog = true;
    },
    startPatientOrder(item: Patient) {
      this.$store.dispatch(`patient/${SET_PATIENT}`, item);

      this.$store.dispatch(`order/${SET_ORDER_PATIENT}`, item).then(() => {
        this.$router.push({ path: `/order/new` });
      });
    },
    deleteItemConfirm() {
      const physId = this.getPhysicianId || "";

      this.$store
        .dispatch(`patient/${DELETE_PATIENT}`, [this.selectedPatientId, physId])
        .then(() => {
          this.closeDeleteDialog();
        });
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
      this.selectedPatientId = -1;
    },
  },
});
