import Vue from "vue";
import { PatientsService } from "@/services/patients.service";
import { Patient } from '@/entities/patient';
import {
  FETCH_PATIENT,
  FETCH_PATIENTS,
  FETCH_PATIENTS_SEXES,
  POST_PATIENT,
  PUT_PATIENT,
  DELETE_PATIENT,
  RESET_PATIENT,
  FETCH_PATIENTS_BY_CEGATID,
  FETCH_PATIENTS_BY_PHYSICIANID,
  RESET_PATIENTS
} from "@/store/types/actions.type";
import {
  SET_PATIENTS_SEARCH_INPUT,
  SET_PATIENTS,
  SET_PATIENTS_SEXES,
  SET_PATIENT,
  SET_PATIENTS_SEARCH_CEGATID
} from "@/store/types/mutations.type";
import { Sex } from "@/entities/sex";
import NotificationMessageService from "@/services/notificationmessage.service";

const service = new PatientsService();

const initialState: any = {
  searchInput: '',
  searchCegatId: '',
  patients: [],
  sexes: [],
  patient: {
    patientId: 0,
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    sexId: 'm',
    street: '',
    postalCode: '',
    city: '',
    country: '',
    patientNumber: '',
    externalId: '',
  }
};

const state = JSON.parse(JSON.stringify(initialState));

const getters = {
  getPatients(_state: any) {
    if (_state.searchInput) {
      const search = _state.searchInput.toLowerCase();
      return _state.patients?.filter((p: Patient) => {
        return p.firstName?.toLowerCase().includes(search) ||
          p.lastName?.toLowerCase().includes(search) ||
          p.dateOfBirth.includes(search) ||
          p.patientNumber?.toLowerCase().includes(search) ||
          _state.sexes.find((s: Sex) => s.sexId == p.sexId).name.toLowerCase().includes(search)
      });
    } else {
      return _state.patients
    }
  },
  getPatient(_state: any) {
    return _state.patient;
  },
  getSearchInput(_state: any) {
    return _state.searchInput;
  },
  getSearchCegatId(_state: any) {
    return _state.searchCegatId;
  },
  getSexes(_state: any) {
    return _state.sexes;
  },
  getSexById: (_state: any) => (sexId: string): Sex => {
    return _state.sexes.find((s: Sex) => s.sexId === sexId) ?? _state.sexes[0];
  },
};

const actions = {
  async [FETCH_PATIENT](context: any, [patientId, physicianId]: [number, string?]) {
    if (patientId > 0) {
      try {
        const patient = await service.getPatient(patientId, physicianId)
        if (patient) {
          context.commit(SET_PATIENT, patient);
        }
      } catch (_) {
        NotificationMessageService.showError('Error while fetching patient - id: {patientId}', {patientId: patientId});
      }
    }
  },
  async [FETCH_PATIENTS](context: any, forceUpdate?: boolean) {
    try {
      const patients = await service.getPatients(forceUpdate)
      if (patients) {
        context.commit(SET_PATIENTS, patients);
      }
    } catch (_) {
      NotificationMessageService.showError("Error while fetching patients!");
    }
  },
  async [FETCH_PATIENTS_BY_CEGATID](context: any, cegatId: string) {
    try {
      const patients = await service.getPatientsByCegatId(cegatId)
      if (patients) {
        context.commit(SET_PATIENTS, patients);
      }
    } catch (_) {
      NotificationMessageService.showError("Error while fetching patients!");
    }
  },
  async [FETCH_PATIENTS_BY_PHYSICIANID](context: any, physicianId: string) {
    try {
      const patients = await service.getPatientsByPhysicianId(physicianId)
      if (patients) {
        context.commit(SET_PATIENTS, patients);
      }
    } catch (_) {
      NotificationMessageService.showError("Error while fetching patients!");
    }
  },
  async [FETCH_PATIENTS_SEXES](context: any) {
    try {
      const sexes = await service.getSexes();
      if (sexes) {
        context.commit(SET_PATIENTS_SEXES, sexes);
      }
    } catch (_) {
      NotificationMessageService.showError(`Error while fetching patients sexes.`);
    }
  },
  async [POST_PATIENT](context: any, [patient, physicianId]: [Patient, string]) {
    try {
      const physId = physicianId ?? '';
      const res = await service.postPatient(patient, physId)
      if (res) {
        NotificationMessageService.showSuccess("New Patient Recorded!");
        context.commit(SET_PATIENT, res);
        if (physId) {
          context.dispatch(FETCH_PATIENTS_BY_PHYSICIANID, physId);
        } else {
          context.dispatch(FETCH_PATIENTS, true);
        }
      }
    } catch (_) {
      NotificationMessageService.showError(`Error while recording new patient.`);
      }
  },
  async [PUT_PATIENT](context: any, [_, physicianId]: [Patient, string]) {
    const physId = physicianId ?? '';
    if (state.patient.patientId > 0) {
      try {
        const patient = await service.putPatient(state.patient, physId)
        if (patient) {
          NotificationMessageService.showSuccess("Patient Information Updated!");
          if (physId) {
            context.dispatch(FETCH_PATIENTS_BY_PHYSICIANID, physId);
          } else {
            context.dispatch(FETCH_PATIENTS, true);
          }
        }
      } catch (_) {
        NotificationMessageService.showError('Error while updating patient - id: {patientId}.', {patientId: state.patient.patientId});
      }
    }
  },
  async [DELETE_PATIENT](context: any, [patientId, physicianId]: [number, string]) {
    const physId = physicianId ?? '';
    if (patientId > 0) {
      try {
        const err = await service.deletePatient(patientId, physId)
        if (!err) {
          NotificationMessageService.showSuccess("Patient Deleted!");
          if (physId) {
            context.dispatch(FETCH_PATIENTS_BY_PHYSICIANID, physId);
          } else {
            context.dispatch(FETCH_PATIENTS, true);
          }
        }
      } catch (_) {
        NotificationMessageService.showError('Error while deleting patient - id: {patientId}.', {patientId: patientId});
      }
    }
  },
  [SET_PATIENT](context: any, patient: Patient) {
    context.commit(SET_PATIENT, patient);
  },
  [SET_PATIENTS_SEARCH_INPUT](context: any, searchInput: string) {
    context.commit(SET_PATIENTS_SEARCH_INPUT, searchInput);
  },
  [SET_PATIENTS_SEARCH_CEGATID](context: any, searchCegatId: string) {
    context.commit(SET_PATIENTS_SEARCH_CEGATID, searchCegatId);
  },
  [RESET_PATIENT](context: any) {
    context.commit(SET_PATIENT, JSON.parse(JSON.stringify(initialState.patient)));
  },
  [RESET_PATIENTS](context: any) {
    context.commit(SET_PATIENTS, []);
  }
};

const mutations = {
  [SET_PATIENTS](_context: any, patients: any) {
    state.patients = [];
    for (const f in patients) {
      Vue.set(state.patients, f, patients[f]);
    }
  },
  [SET_PATIENT](_context: any, patient: Patient) {
    Vue.set(state, 'patient', patient);
  },
  [SET_PATIENTS_SEARCH_INPUT](_context: any, searchInput: any) {
    Vue.set(state, 'searchInput', searchInput);
  },
  [SET_PATIENTS_SEARCH_CEGATID](_context: any, searchCegatId: any) {
    Vue.set(state, 'searchCegatId', searchCegatId);
  },
  [SET_PATIENTS_SEXES](_context: any, sexes: string[]) {
    Vue.set(state, 'sexes', sexes);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
