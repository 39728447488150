
















































import Vue from "vue";
import Button from "@/components/input-components/Button.vue";
import Combobox from "@/components/input-components/Combobox.vue";
import { FETCH_ORDER_CONSENT } from "@/store/types/actions.type";
import {CONSENT_FORM, FORM_FLAGS, ORDER_INFO_PAGE_OPTIONS, ORDER_INFO_PAGE_SUFFIXES} from "@/utils/constants";
import { mapGetters } from "vuex";
import { Order } from "@/entities/order";
import { FormControl } from '@/entities/form-control'
import ControlRendererMixin from '@/mixins/ControlRendererMixin'

export default Vue.extend({
  name: "OrderConsentDownload",
  components: { Button, Combobox },
  props: {
    TranslationKeySuffix: {
      type: String,
      required: false,
      default() {
        return "";
      }
    },
  },
  data: () => {
    return {
      consentSelectedLanguage: CONSENT_FORM.defaultLanguage as string,
      availableLanguages: CONSENT_FORM.availableLanguages as Array<string>,
      consentTypes: CONSENT_FORM.types as object,
      controlRendererMixin: new ControlRendererMixin(),
    };
  },
  computed: {
    ...mapGetters("order", [
      "getOrder",
      "getOrderFormControlsByName",
      "getOrderFormTabByControlId",
      "getOrderFormTabsByFlag",
      "getOrderInfoPage",
    ]),
    order(): Order {
      return this.getOrder;
    },
    getOrderFormPatientConsentControl(): FormControl|undefined {
      const controls = this.getOrderFormControlsByName(
        `${CONSENT_FORM.types.patient}${CONSENT_FORM.downloadBtnNameSuffix}`
      );

      const patientTabs = this.getOrderFormTabsByFlag(FORM_FLAGS.isPatientConsent)
      if (!patientTabs) {
        return undefined
      }

      let tab: any
      for (const patientTab of patientTabs) {
        if (this.controlRendererMixin.showTab(patientTab)) {
          tab = patientTab
          break;
        }
      }

      if (tab) {
        return controls.find((c: any) => tab.controls.includes(c.uniqueId))
      }

      return undefined
    },
    getOrderFormParent1ConsentControl(): FormControl|undefined {
      const controls = this.getOrderFormControlsByName(
        `${CONSENT_FORM.types.parent1}${CONSENT_FORM.downloadBtnNameSuffix}`
      );

      const parentTabs = this.getOrderFormTabsByFlag(FORM_FLAGS.isParentConsent)
      if (!parentTabs) {
        return undefined
      }

      let control: FormControl|undefined
      for (const parentTab of parentTabs) {
        if (this.controlRendererMixin.showTab(parentTab)) {
          const foundControl = controls.find((c: any) => parentTab.controls.includes(c.uniqueId))
          if (foundControl) {
            control = foundControl
            break
          }
        }
      }

      return control
    },
    getOrderFormParent2ConsentControl(): FormControl|undefined {
      const controls = this.getOrderFormControlsByName(
        `${CONSENT_FORM.types.parent2}${CONSENT_FORM.downloadBtnNameSuffix}`
      );

      const parentTabs = this.getOrderFormTabsByFlag(FORM_FLAGS.isParentConsent)
      if (!parentTabs) {
        return undefined
      }

      let control: FormControl|undefined
      for (const parentTab of parentTabs) {
        if (this.controlRendererMixin.showTab(parentTab)) {
          const foundControl = controls.find((c: any) => parentTab.controls.includes(c.uniqueId))
          if (foundControl) {
            control = foundControl
            break
          }
        }
      }

      return control
    },
    isUsForm(): boolean {
      return this.TranslationKeySuffix == ORDER_INFO_PAGE_SUFFIXES.us_form ||
        this.getOrderInfoPage == ORDER_INFO_PAGE_OPTIONS.us_form
    },
  },
  methods: {
    getControlByType(type: string): FormControl|undefined {
      if (type === CONSENT_FORM.types.patient) {
        return this.getOrderFormPatientConsentControl
      }
      else if (type === CONSENT_FORM.types.parent1) {
        return this.getOrderFormParent1ConsentControl
      }
      else if (type === CONSENT_FORM.types.parent2) {
        return this.getOrderFormParent2ConsentControl
      }

      return undefined
    },
    async downloadConsent(type: string) {
      const control = this.getControlByType(type)
      let template = CONSENT_FORM.defaultTemplate
      if (control) {
        const formTab = this.getOrderFormTabByControlId(control.uniqueId)
        if (formTab) {
          template = formTab.template || CONSENT_FORM.defaultTemplate
        }
      }

      const payload = {
        orderId: this.getOrder.orderId,
        type,
        data: {
          language: this.consentSelectedLanguage,
          formData: this.getOrder.form,
        },
        template
      };
      await this.$store.dispatch(`order/${FETCH_ORDER_CONSENT}`, payload);
    },
  },
});
