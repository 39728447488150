import { Physician } from "../entities/physician";
import { IPhysiciansService } from "./iphysicians.service";
import { PhysiciansApiService } from "./physicians-api.service";
import { ApiResponse } from "../entities/api-response";

export class PhysiciansService implements IPhysiciansService {

  private physicians: Promise<Physician[]> | null = null;
  private service: PhysiciansApiService;

  constructor(physiciansApiService?: PhysiciansApiService) {
    this.service = physiciansApiService || new PhysiciansApiService()
  }

  public getPhysicians(forceUpdate?: boolean): Promise<Physician[]> {
    if (!this.physicians || forceUpdate) {
      this.physicians = this.service.getPhysicians()
        .then((res: ApiResponse) => res.content)
        .then((physicians: Physician[]) => { return physicians.filter(p => p.isVerified === true) });
    }
    return this.physicians
  }
}
