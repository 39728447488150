import { render, staticRenderFns } from "./RadioGroup.vue?vue&type=template&id=0934b93c&scoped=true&"
import script from "./RadioGroup.vue?vue&type=script&lang=ts&"
export * from "./RadioGroup.vue?vue&type=script&lang=ts&"
import style0 from "./RadioGroup.vue?vue&type=style&index=0&id=0934b93c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0934b93c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VRadioGroup})
