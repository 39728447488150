import {IProspectUserService} from "@/services/iprospectuser.service"
import {ProspectUserApiService} from "@/services/prospectuser-api.service"
import {ApiResponse} from "@/entities/api-response";

export class ProspectUserService implements IProspectUserService {

  private service: ProspectUserApiService

  constructor(prospectUserApiService?: ProspectUserApiService) {
    this.service = prospectUserApiService || new ProspectUserApiService()
  }

  postMergeAccount(parameters: string): Promise<any> {
    return this.service.postMergeAccount(parameters).then((res: ApiResponse) => res.content);
  }
}
