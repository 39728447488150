import {IProfileService} from "@/services/iprofile.service";
import axios from "@/config/api-config";
import {ApiResponse} from "@/entities/api-response";
import {Notification} from "@/entities/notification";

const profileApi = '/users'

export class ProfileApiService implements IProfileService {

  constructor() {
  }

  getProfile(userId: string): Promise<ApiResponse> {
    return axios.get(`${profileApi}/${userId}/personalData`).then(function (res) {
      return res.data;
    });
  }

  getCountries(): Promise<ApiResponse> {
    return axios.get(`${profileApi}/personalData/Countries`).then(function (res) {
      return res.data;
    });
  }

  deleteUser(userId: string): Promise<ApiResponse> {
    return axios.delete(`${profileApi}/${userId}`).then(function (res) {
      return res.data;
    });
  }

  postAdditionalEmails(email: string): Promise<ApiResponse> {
    const objectToPost = {
      email: email
    }

    return axios.post(`${profileApi}/additionalEmails`, objectToPost).then(function (res) {
      return res.data;
    })
  }

  deleteAdditionalEmails(email: string): Promise<ApiResponse> {
    const objectToDelete = {
      email: email
    }
    return axios.delete(`${profileApi}/additionalEmails`, {data: objectToDelete}).then(function (res) {
      return res.data;
    })
  }

  postSharedUsers(email: string, salutation: string, title: string, firstName: string, lastName: string): Promise<ApiResponse> {
    const objectToPost = {
      email: email,
      salutation: salutation,
      title: title,
      firstName: firstName,
      lastName: lastName
    }
    return axios.post(`${profileApi}/sharedUsers`, objectToPost).then(function (res) {
      return res.data;
    })
  }

  deleteSharedUsers(email: string, salutation: string, title: string, firstName: string, lastName: string): Promise<ApiResponse> {
    const objectToDelete = {
      email: email,
      salutation: salutation,
      title: title,
      firstName: firstName,
      lastName: lastName
    }
    return axios.delete(`${profileApi}/sharedUsers`, {data: objectToDelete}).then(function (res) {
      return res.data;
    })
  }

  getSharedUsers(): Promise<ApiResponse> {
    return axios.get(`${profileApi}/sharedUsers`).then(function (res) {
      return res.data;
    })
  }

  getNotifications(userId: string): Promise<ApiResponse> {
    return axios.get(`${profileApi}/${userId}/notifications`).then(function (res) {
      return res.data;
    })
  }

  postNotification(userId: string, notification: Notification): Promise<ApiResponse> {
    return axios.post(`${profileApi}/${userId}/notifications`, notification).then(function (res) {
      return res.data;
    })
  }

  deleteNotification(userId: string, notificationId: string): Promise<ApiResponse> {
    return axios.delete(`${profileApi}/${userId}/notifications/${notificationId}`).then(function (res) {
      return res.data;
    })
  }
}
