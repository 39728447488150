






import { Patient } from "@/entities/patient";
import {
  POST_PATIENT,
  PUT_PATIENT,
  RESET_PATIENT,
} from "@/store/types/actions.type";
import Vue from "vue";
import { mapGetters } from "vuex";
import PatientCreation from "@/components/patients/PatientCreation";

export default Vue.extend({
  name: "Patient",
  components: {
    PatientCreation,
  },
  computed: {
    ...mapGetters("physician", ["getPhysician"]),
    getPhysicianId(): string {
      return this.getPhysician.userId;
    },
  },
  methods: {
    cancel(): void {
      this.$store.dispatch(`patient/${RESET_PATIENT}`).then(() => {
        this.$router.push({ path: `/MyPatients` });
      });
    },
    save(patient: Patient): void {
      const physId = this.getPhysicianId || "";
      let action = patient.patientId > 0 ? PUT_PATIENT : POST_PATIENT;
      this.$store
        .dispatch(`patient/${action}`, [patient, physId])
        .then(() => {
          this.$router.push({ path: `/MyPatients` });
          // inform other tabs about the change in patient data
          window.localStorage.setItem("refreshPatients", "");
        });
    },
  },
});
