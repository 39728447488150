var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 form-renderer"},[(_vm.form.formConfig.uniqueId)?_c('v-stepper',{on:{"change":_vm.onStepChange},model:{value:(_vm.selectedStepOrder),callback:function ($$v) {_vm.selectedStepOrder=$$v},expression:"selectedStepOrder"}},[_c('v-stepper-header',[_vm._l((_vm.form.steps),function(step){return [_c('v-stepper-step',{key:((step.uniqueId) + "-step"),attrs:{"complete":step.sortOrder <= _vm.formCompletedStep || _vm.isReadOnly,"edit-icon":'$complete',"step":step.sortOrder,"color":_vm.isGreen(step.sortOrder) ? 'success' : 'primary',"editable":_vm.devTesting || _vm.isEditable(step.sortOrder),"rules":[function () { return step.valid; }]}},[_c('div',{class:{
              'step-name-green': _vm.isGreen(step.sortOrder) && step.valid,
            }},[_vm._v(" "+_vm._s(_vm.getName(step))+" ")])]),(!_vm.isLastStep(step.sortOrder))?_c('v-divider',{key:((step.uniqueId) + "-div"),class:{
            'divider-green':
              step.sortOrder <= _vm.formCompletedStep || _vm.isReadOnly,
          }}):_vm._e()]})],2),_c('v-divider',{staticClass:"mb-6"}),_c('v-stepper-items',_vm._l((_vm.form.steps),function(step){return _c('v-stepper-content',{key:((step.uniqueId) + "-content"),attrs:{"step":step.sortOrder}},[(step.sortOrder == _vm.selectedStepOrder)?_c('v-container',{staticClass:"stepper-content"},[_c('v-tabs',{staticClass:"vertical-slider-line",attrs:{"vertical":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_vm._l((_vm.getVisibleTabs(step)),function(tabId,index){return _c('v-tab',{key:(tabId + "-header"),staticClass:"tab-header text-wrap",attrs:{"disabled":!_vm.isReadOnly}},[_vm._v(" "+_vm._s(_vm.getName(_vm.getOrderFormTabById(tabId)))+" "),_c('span',{staticClass:"tab-numbers"},[_vm._v(" "+_vm._s(index + 1)+"/"+_vm._s(_vm.getVisibleTabs(step).length))])])}),_vm._l((_vm.getVisibleTabs(step)),function(tabId){return _c('v-tab-item',{key:(tabId + "-content"),staticClass:"tab-content",attrs:{"transition":"false","eager":true}},[_c('div',{staticClass:"form-title"},[_vm._v(" "+_vm._s(_vm.getName(_vm.getOrderFormTabById(tabId)))+" ")]),_c('div',{staticClass:"pa-2"}),(!_vm.isReadOnly)?_c('div',[(
                    _vm.getOrderFormTabById(tabId).flag ===
                      _vm.formFlags.isPatientInfo ||
                    _vm.getOrderFormTabById(tabId).flag ===
                      _vm.formFlags.isParentConsent
                  )?_c('PatientPickerWindow',{attrs:{"flag":_vm.getOrderFormTabById(tabId).flag},on:{"patientSelected":function($event){return _vm.fillPatientData($event)},"parentSelected":function($event){return _vm.fillParentData($event)}}}):_vm._e(),(
                    _vm.getOrderFormTabById(tabId).flag === _vm.formFlags.isSenderInfo
                  )?_c('OrderSenderData',{on:{"fillSender":function($event){return _vm.fillSenderData($event)}}}):_vm._e(),(
                    _vm.getOrderFormTabById(tabId).flag ===
                    _vm.formFlags.isInvoiceInfo
                  )?_c('OrderInvoiceData',{on:{"fillInvoice":function($event){return _vm.fillInvoiceData($event)}}}):_vm._e()],1):_vm._e(),_c('v-form',{ref:"form",refInFor:true,attrs:{"disabled":_vm.isReadOnly,"lazy-validation":""},model:{value:(step.valid),callback:function ($$v) {_vm.$set(step, "valid", $$v)},expression:"step.valid"}},[_vm._l((_vm.getOrderFormTabById(tabId).controls),function(controlId){return [_c('ControlRenderer',{key:controlId,attrs:{"tabId":tabId,"controlId":controlId}})]})],2),_c('v-container',{staticClass:"tab-footer"},[_c('Button',{staticClass:"cancel-order-button",attrs:{"color":"secondary red--text"},on:{"click":function($event){return _vm.cancelOrder()}}},[_vm._v(_vm._s(_vm.$t("Cancel order")))]),_c('Button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFirstBackButton(step, tabId)),expression:"!isFirstBackButton(step, tabId)"}],attrs:{"color":"secondary","scroll":true},on:{"click":function($event){return _vm.previousTab(step.sortOrder)}}},[_vm._v(_vm._s(_vm.$t("Back")))]),_c('Button',{staticClass:"ml-sm-3",attrs:{"scroll":true},on:{"click":function($event){_vm.nextTab(_vm.getVisibleTabs(step).length, step.sortOrder)}}},[_vm._v(_vm._s(_vm.$t(_vm.nextButtonTitle(_vm.getVisibleTabs(step).length, step.sortOrder)))+" "),_c('v-icon',{staticClass:"icon-flipped",attrs:{"dark":"","right":""}},[_vm._v(" mdi-keyboard-backspace ")])],1)],1)],1)})],2)],1):_vm._e()],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }