



























































import Vue from "vue";
import Button from "@/components/input-components/Button";

export default Vue.extend({
  name: "Dialog",
  components: { Button },
  props: {
    maxWidth: {
      type: String,
      required: false,
      default() {
        return "505px";
      },
    },
    title: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    icon: {
      type: String,
      required: false,
      default: "mdi-alert-circle",
    },
    showIcon: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    showCloseButton: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    saveInProgress: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    errorOnSave: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  computed: {
    contentClasses() {
      const untypedAttrs = this.$attrs as any;
      const classes = ["dialog"];
      if (untypedAttrs && untypedAttrs["content-class"] !== "undefined") {
        classes.push(untypedAttrs["content-class"]);
      }
      return classes.join(" ");
    },
    persistent() {
      const untypedAttrs = this.$attrs as any;
      return (
        this.saveInProgress ||
        (untypedAttrs && untypedAttrs["persistent"] === "true") ||
        untypedAttrs["persistent"] === true
      );
    },
  },
});
