import Vue from "vue";
import {
  FETCH_NOTIFICATIONS,
} from "../types/actions.type";
import {
  SET_NOTIFICATIONS,
} from "../types/mutations.type";
import { OrderNotificationsService } from "@/services/order-notifications.service";
import { Notification } from "@/entities/notification";

const service = new OrderNotificationsService();

const state: any = {
  notifications: [],
};

const getters = {
  getNotifications: (_state: any) => {
    return _state.notifications;
  },
}

const actions = {
  async [FETCH_NOTIFICATIONS](context: any, forceUpdate?: boolean) {
    try {
      const notifications = await service.getNotifications(forceUpdate);
      if (notifications) {
        context.commit(SET_NOTIFICATIONS, notifications);
      }
    } catch (_) {
      console.warn(`error while fetching notifications`)
    }
  }
}

const mutations = {
  [SET_NOTIFICATIONS](_context: any, notifications: Notification[]) {
    state.notifications = [];
    for (const notification in notifications) {
      Vue.set(state.notifications, notification, notifications[notification]);
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
