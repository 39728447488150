




































































import Vue from "vue";
import Dialog from "@/components/dialogs/Dialog";
import FileInput from "@/components/input-components/FileInput";
import Button from "@/components/input-components/Button.vue";
import { Report } from "@/entities/report";
import OrderStatus from "@/components/orders/OrderStatus.vue";

export default Vue.extend({
  name: "ReportManagementDialog",
  components: { OrderStatus, Button, FileInput, Dialog },
  inheritAttrs: false,
  props: {
    statuses: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    dataLoading: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
    order: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
    reports: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    show: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      files: [],
      wrongDatatype: false,
    };
  },
  computed: {
    orderStatusId(): string | null {
      return this.order?.statusId ?? null;
    },
    saveInProgress(): boolean {
      return (this.$attrs && !!this.$attrs["save-in-progress"]) ?? false;
    },
  },
  methods: {
    toggleDeleteState(orderFileId: number): void {
      const index = this.reports.findIndex(
        (r: any) => r.orderFileId == orderFileId
      );
      if (index !== -1) {
        this.$set(
          this.reports[index] as Report,
          "delete",
          !(this.reports[index] as Report).delete
        );
      }
    },
    save() {
      const deletedReports = this.reports.filter((r: any) => r.delete);
      const add = this.files;
      this.$emit("save", { remove: deletedReports, add });
    },
  },
  watch: {
    show: {
      immediate: true,
      handler() {
        this.files = [];
      },
    },
  },
});
