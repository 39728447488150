












































import Vue from "vue";
import { supportedLanguages } from "@/utils/language";

export default Vue.extend({
  name: "Header",
  data() {
    return {
      languages: supportedLanguages
    };
  },
  computed: {
    currentLocale(): string {
      return this.$i18n.locale
    },
    dropdownLanguages(): string[] {
      return this.languages.filter(
        (lang) => lang !== this.currentLocale
      )
    }
  },
  methods: {
    changeLanguage(lang: string) {
      this.$i18n.locale = lang
    },
  },
});
