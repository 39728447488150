
































import Vue from "vue";
import ProfilePersonalData from "@/components/profile/ProfilePersonalData.vue";
import ProfileAuthentication from "@/components/profile/ProfileAuthentication.vue";
import ProfileNotification from "@/components/profile/ProfileNotification.vue";
import ProfileDataSharing from "@/components/profile/ProfileDataSharing.vue";
import ProfileAccountManagement from "@/components/profile/ProfileAccountManagement.vue";
import { ProfileService } from "@/services/profile.service";
import { OrderNotificationsService } from "@/services/order-notifications.service";
import NotificationMessageService from "@/services/notificationmessage.service";
import { Notification } from "@/entities/notification";
import {Country} from '@/entities/country'

export default Vue.extend({
  name: "MyProfile",
  components: {
    ProfilePersonalData,
    ProfileAuthentication,
    ProfileNotification,
    ProfileDataSharing,
    ProfileAccountManagement,
  },
  data() {
    return {
      currentTab: 0,
      userProfile: {},
      notifications: [],
      sharedUsers: {},
      allNotifications: [] as any,
      profileTabs: [
        {
          no: 0,
          id: "personal-data",
          translationKey: "Personal Data",
          component: ProfilePersonalData,
        },
        {
          no: 1,
          id: "authentication",
          translationKey: "Authentication",
          component: ProfileAuthentication,
        },
        {
          no: 2,
          id: "notification",
          translationKey: "Notification",
          component: ProfileNotification,
        },
        {
          no: 3,
          id: "data-sharing",
          translationKey: "Data sharing",
          component: ProfileDataSharing,
        },
        {
          no: 4,
          id: "account-management",
          translationKey: "Account management",
          component: ProfileAccountManagement,
        },
      ],
      profileService: new ProfileService(),
      orderNotificationsService: new OrderNotificationsService(),
      countries: [] as Country[],
    };
  },
  mounted() {
    this.getAllNotifications();
    this.getSharedUsers();
    this.$root.$on("resetMyProfileCurrentTab", this.resetCurrentTab);
    this.getCountries()
  },
  methods: {
    async getProfile(): Promise<void> {
      if (this.$keycloak?.subject) {
        this.userProfile = await this.profileService.getProfile(
          this.$keycloak.subject
        );
        const userMenu = this.$root.$refs.UserMenu as any;
        userMenu.updateComponent(this.userProfile);
      }
    },
    async getCountries(): Promise<void> {
      this.countries = await this.profileService.getCountries()
    },
    async getNotifications(): Promise<void> {
      if (this.$keycloak?.subject) {
        this.notifications = await this.profileService.getNotifications(
          this.$keycloak.subject
        );
      }
    },
    async getSharedUsers(): Promise<void> {
      if (this.$keycloak?.subject) {
        this.sharedUsers = await this.profileService.getSharedUsers();
      }
    },
    async getAllNotifications(): Promise<void> {
      this.allNotifications =
        await this.orderNotificationsService.getNotifications();
    },
    addNotification(notification: Notification): void {
      if (this.$keycloak?.subject) {
        this.profileService
          .postNotification(this.$keycloak.subject, notification)
          .then(() => {
            NotificationMessageService.showSuccess(
              this.$t("Added notification for {notificationName}", {
                notificationName: notification.name,
              }) as string
            );
          })
          .catch(() => {
            NotificationMessageService.showError(
              this.$t(
                "Received an error while adding the notification"
              ) as string
            );
            this.getNotifications();
          });
      }
    },
    removeNotification(notification: Notification): void {
      if (this.$keycloak?.subject) {
        this.profileService
          .deleteNotification(
            this.$keycloak.subject,
            notification.notificationId
          )
          .then(() => {
            NotificationMessageService.showInfo(
              this.$t("Removed notification for {notificationName}", {
                notificationName: notification.name,
              }) as string
            );
          })
          .catch(() => {
            NotificationMessageService.showError(
              this.$t(
                "Received an error while removing the notification"
              ) as string
            );
            this.getNotifications();
          });
      }
    },
    handleGoto(id: string): void {
      const item: any = this.profileTabs.find((tab) => tab.id === id);
      if (item) this.currentTab = item.no;
    },
    resetCurrentTab(): void {
      this.currentTab = 0;
    },
  },
});
