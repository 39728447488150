

































































import Vue from "vue";
import Dialog from "@/components/dialogs/Dialog";
import { OrderUser } from "@/entities/order-user";
import Button from "@/components/input-components/Button.vue";
import MoreUserOrderShareDialog from '@/components/dialogs/MoreUserOrderShareDialog.vue'

export default Vue.extend({
  name: "OrderShareDialog",
  components: { MoreUserOrderShareDialog, Dialog, Button },
  props: {
    dataLoading: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
    order: {
      type: Object,
      required: true,
      default() {
        return {
          orderId: -1,
        };
      },
    },
    orderShareholders: {
      type: Array as () => Array<OrderUser>,
      required: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("Salutation") as string,
          value: "doctorSalutation",
        },
        {
          text: this.$t("Title") as string,
          value: "doctorTitle",
        },
        {
          text: this.$t("First name") as string,
          value: "doctorFirstName",
        },
        {
          text: this.$t("Last name") as string,
          value: "doctorLastName",
        },
        {
          text: this.$t("Email") as string,
          value: "doctorEmail",
        },
        { text: "", value: "controls", sortable: false },
      ],
      showMoreUserOrderShareDialog: false,
    };
  },
  methods: {
    async deleteOrderUser(data: any) {
      this.$emit("delete-email", {
        data: data,
      });
    },
    closeSubmitOrderShareDialog(args: String|null) {
      this.showMoreUserOrderShareDialog = false
      if (args === 'finish') {
        this.$emit('save')
      }
    },
  },
  computed: {
    orderUsers(): Array<OrderUser> {
      return this.orderShareholders.filter((u) => !u.isOwner);
    },
    orderId(): Number {
      return this.order?.orderId;
    }
  },
});
