





























































































































































import Button from "@/components/input-components/Button";
import ControlRenderer from "@/components/ControlRenderer.vue";
import PatientPickerWindow from "@/components/patients/PatientPickerWindow.vue";
import OrderSenderData from "@/components/orders/OrderSenderData.vue";
import OrderInvoiceData from "@/components/orders/OrderInvoiceData.vue";
import Vue from "vue";
import { FormStep } from "@/entities/form-step";
import { FormTab } from "@/entities/form-tab";
import { JsonForm } from "@/entities/json-form";
import { FORM_CONTROLS, FORM_FLAGS, ORDER_STATUS } from "@/utils/constants";
import { Order } from "@/entities/order";
import { Patient } from "@/entities/patient";
import { User } from "@/entities/user";
import { mapGetters } from "vuex";
import NotificationMessageService from "@/services/notificationmessage.service";
import { convertFrontendSexValueToFormBuilderValue } from '@/utils'
import ControlRendererMixin from "@/mixins/ControlRendererMixin";

export default Vue.extend({
  name: "FormRenderer",
  components: {
    Button,
    ControlRenderer,
    PatientPickerWindow,
    OrderSenderData,
    OrderInvoiceData,
  },
  data: () => {
    return {
      devTesting: false,
      selectedStepOrder: 1 as number,
      activeTab: 0,
      formFlags: FORM_FLAGS,
      controlRendererMixin: new ControlRendererMixin(),
    };
  },
  watch: {
    selectedStepOrder(_, oldStepOrder) {
      let step = this.getOrderFormStepBySortOrder(oldStepOrder);
      const form = this.$refs.form as any;
      for (let tab of form) {
        if (!tab.validate()) {
          step.valid = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters("order", [
      "getOrder",
      "getOrderForm",
      "getOrderFormTabById",
      "getOrderFormControlByName",
      "getOrdersPatient",
      "getOrderFormStepBySortOrder",
    ]),
    order(): Order {
      return this.getOrder;
    },
    ordersPatient(): Patient {
      return this.getOrdersPatient;
    },
    form(): JsonForm {
      return this.getOrderForm;
    },
    formCompletedStep(): number {
      return this.getOrderForm.formConfig.completedStep;
    },
    isViewOrder(): boolean {
      return this.$route.fullPath.split("/")[2].toLowerCase() === "view";
    },
    isReadOnly(): boolean {
      return (
        (!this.hasOrderUpdateAllPermission &&
          this.order.statusId !== ORDER_STATUS.draft) ||
        this.isViewOrder
      );
    },
  },
  mounted() {
    // start draft order on next not yet completed step
    if (this.formCompletedStep > 0 && !this.isReadOnly) {
      this.selectedStepOrder = this.formCompletedStep + 1;
    }
    // fill patient data of a order started from within patient list
    if (this.order.orderId === 0 && this.ordersPatient.patientId) {
      this.fillPatientData(this.ordersPatient);
    }
  },
  methods: {
    isFirstBackButton(step: FormStep, tabId: string): boolean {
      return step.sortOrder === 1 && tabId === step.tabs[0];
    },
    previousTab(currentStep: number): void {
      if (this.activeTab > 0) {
        this.activeTab = this.activeTab - 1;
      } else {
        this.previousStep(currentStep);
      }
    },
    nextTab(totalTabs: number, currentStep: number) {
      if (this.validateFormTab(this.activeTab)) {
        if (this.activeTab < totalTabs - 1) {
          this.activeTab = this.activeTab + 1;
        } else {
          this.nextStep(currentStep);
        }
      }
    },
    nextStep(currentStep: number): void {
      if (currentStep < this.getLastStep()) {
        this.activeTab = 0;
        this.selectedStepOrder = currentStep + 1;
      } else if (!this.allStepsValid()) {
        NotificationMessageService.showError(
          "One of the steps was not filled out completely."
        );
      } else {
        this.$emit("showSummary");
      }
      this.form.formConfig.completedStep = this.selectedStepOrder - 1;
    },
    previousStep(currentStep: number): void {
      if (currentStep > 1) {
        this.activeTab = 0;
        this.selectedStepOrder = currentStep - 1;
      }
    },
    onStepChange(): void {
      this.activeTab = 0;
      if (!this.isReadOnly) this.$emit("saveOrder");
    },
    allStepsValid(): boolean {
      let allValid = true;
      this.form.steps.every((s: FormStep) => {
        if (!s.valid) {
          allValid = false;
        }
      });
      return allValid;
    },
    validateFormTab(currentTab: number): boolean {
      if (this.devTesting) return true;
      const form = this.$refs.form as any;
      if (!form[currentTab].validate()) return false;
      if (!this.isReadOnly) this.$emit("saveOrder");
      return true;
    },
    isEditable(stepOrder: number): boolean {
      return stepOrder <= this.formCompletedStep + 1 || this.isReadOnly;
    },
    isGreen(stepOrder: number): boolean {
      return (
        (stepOrder <= this.formCompletedStep || this.isReadOnly) &&
        stepOrder !== this.selectedStepOrder
      );
    },
    isLastStep(stepOrder: number): boolean {
      return stepOrder == this.getLastStep();
    },
    isLastTab(totalTabs: number): boolean {
      return this.activeTab == totalTabs - 1;
    },
    nextButtonTitle(totalTabs: number, sortOrder: number): string {
      return this.isLastStep(sortOrder) && this.isLastTab(totalTabs)
        ? "Review Order"
        : "Next";
    },
    getVisibleTabs(step: FormStep) {
      return step.tabs.filter((tab) => this.controlRendererMixin.showTab(this.getOrderFormTabById(tab)));
    },
    getLastStep(): number {
      if (this.form.steps) {
        const orders = this.form.steps.map((s: FormStep) => s.sortOrder);
        return orders.reduce((a: number, c: number) => (a > c ? a : c));
      }
      return 1;
    },
    getName(object: FormStep | FormTab): string {
      if (
        this.$i18n.locale !== "en" &&
        object.localizations[this.$i18n.locale] !== undefined
      ) {
        return object.localizations[this.$i18n.locale].name;
      } else {
        return object.name;
      }
    },
    setFormControlValue(formControlName: string, value: string | undefined) {
      const control = this.getOrderFormControlByName(formControlName)
      if (control && value) {
        control.value = value
      }
    },
    fillPatientData(patient: Patient): void {
      if (patient) {
        this.setFormControlValue(FORM_CONTROLS.patient_firstName, patient.firstName)
        this.setFormControlValue(FORM_CONTROLS.patient_lastName, patient.lastName)
        this.setFormControlValue(FORM_CONTROLS.patient_dateOfBirth, patient.dateOfBirth)
        this.setFormControlValue(FORM_CONTROLS.patient_sexId, convertFrontendSexValueToFormBuilderValue(patient.sexId))
        this.setFormControlValue(FORM_CONTROLS.patient_externalId, patient.externalId)
        this.$emit("patientSelected", patient);
      }
    },
    fillParentData(patient: Patient): void {
      if (patient) {
        if (this.activeTab === FORM_FLAGS.parent1_stepOrder) {
          this.setFormControlValue(FORM_CONTROLS.parent1_firstName, patient.firstName)
          this.setFormControlValue(FORM_CONTROLS.parent1_lastName, patient.lastName)
          this.setFormControlValue(FORM_CONTROLS.parent1_dateOfBirth, patient.dateOfBirth)
          this.setFormControlValue(FORM_CONTROLS.parent1_sexId, convertFrontendSexValueToFormBuilderValue(patient.sexId))
        } else if (this.activeTab === FORM_FLAGS.parent2_stepOrder) {
          this.setFormControlValue(FORM_CONTROLS.parent2_firstName, patient.firstName)
          this.setFormControlValue(FORM_CONTROLS.parent2_lastName, patient.lastName)
          this.setFormControlValue(FORM_CONTROLS.parent2_dateOfBirth, patient.dateOfBirth)
          this.setFormControlValue(FORM_CONTROLS.parent2_sexId, convertFrontendSexValueToFormBuilderValue(patient.sexId))
        }
      }
    },
    fillSenderData(sender: User): void {
      if (sender) {
        this.setFormControlValue(FORM_CONTROLS.sender_firstName, sender.firstName)
        this.setFormControlValue(FORM_CONTROLS.sender_lastName, sender.lastName)
        this.setFormControlValue(FORM_CONTROLS.sender_physicianNpiNumber, sender.physicianNpiNumber)
        this.setFormControlValue(FORM_CONTROLS.sender_institution, sender.institution)
        this.setFormControlValue(FORM_CONTROLS.sender_street, sender.street)
        this.setFormControlValue(FORM_CONTROLS.sender_postalCode, sender.postalCode)
        this.setFormControlValue(FORM_CONTROLS.sender_city, sender.city)
        this.setFormControlValue(FORM_CONTROLS.sender_country, sender.country)
        this.setFormControlValue(FORM_CONTROLS.sender_email, sender.email)
      }
    },
    fillInvoiceData(invoice: any): void {
      if (invoice) {
        this.setFormControlValue(FORM_CONTROLS.firstName, invoice.firstName)
        this.setFormControlValue(FORM_CONTROLS.lastName, invoice.lastName)
        this.setFormControlValue(FORM_CONTROLS.street, invoice.street)
        this.setFormControlValue(FORM_CONTROLS.postalCode, invoice.postalCode)
        this.setFormControlValue(FORM_CONTROLS.city, invoice.city)
        this.setFormControlValue(FORM_CONTROLS.country, invoice.country)
        this.setFormControlValue(FORM_CONTROLS.email, invoice.email)
      }
    },
    cancelOrder(): void {
      this.$emit("cancelOrder");
    },
  },
});
