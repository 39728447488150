export const PERMISSION = Object.freeze({
  rsNames: {
    form: 'form',
    log: 'log',
    notification: 'notification',
    order: 'order',
    orderReport: 'order.report',
    orderSupportingFile: 'order.supportingFile',
    patient: 'patient',
    user: 'user',
    userNotification: 'user.notification',
    userPersonal: 'user.personal'
  },
  scopes: {
    create: 'create',
    createAll: 'create.all',
    delete: 'delete',
    deleteAll: 'delete.all',
    read: 'read',
    readAll: 'read.all',
    readCountry: 'read.country',
    update: 'update',
    updateAll: 'update.all'
  },
  frontendCheck: {
    none: 'none',
    readOnly: 'readOnly',
    readWrite: 'readWrite'
  }
})

export const ROLES = Object.freeze({
  externalUser: 'External-User',
  externalSales: 'External-Sales',
  cegatVerified: 'CeGaT-Verified',
  reportRelease: 'Report-Release'
})

export const ORDER_STATUS = Object.freeze({
  draft: 'draft',
  orderSubmitted: 'order_submitted',
  samplePending: 'sample_pending',
  samplePaymentPending: 'sample_payment_pending',
  paymentPending: 'payment_pending',
  analysisInProgress: 'analysis_in_progress',
  errorActionNeeded: 'error_action_needed',
  reportAvailable: 'report_available'
})

export const FORM_CONTROLS = Object.freeze({
  patient_firstName: 'patient_firstName',
  patient_lastName: 'patient_lastName',
  patient_dateOfBirth: 'patient_dateOfBirth',
  patient_sexId: 'patient_sexId',
  patient_externalId: 'patient_externalId',

  sender_firstName: 'sender_firstName',
  sender_lastName: 'sender_lastName',
  sender_physicianNpiNumber: 'sender_physicianNpiNumber',
  sender_institution: 'sender_institution',
  sender_street: 'sender_street',
  sender_postalCode: 'sender_postalCode',
  sender_city: 'sender_city',
  sender_country: 'sender_country',
  sender_email: 'sender_email',

  firstName: 'firstName',
  lastName: 'lastName',
  street: 'street',
  postalCode: 'postalCode',
  city: 'city',
  country: 'country',
  email: 'email',

  parent1_firstName: 'parent1_firstName',
  parent1_lastName: 'parent1_lastName',
  parent1_dateOfBirth: 'parent1_dateOfBirth',
  parent1_sexId: 'parent1_sexId',

  parent2_firstName: 'parent2_firstName',
  parent2_lastName: 'parent2_lastName',
  parent2_dateOfBirth: 'parent2_dateOfBirth',
  parent2_sexId: 'parent2_sexId',
})

export const FORM_FLAGS = Object.freeze({
  isPatientInfo: "isPatientInfo",
  isSenderInfo: "isSenderInfo",
  isInvoiceInfo: "isInvoiceInfo",
  isPatientConsent: "isPatientConsent",
  isParentConsent: "isParentConsent",

  parent1_stepOrder: 1,
  parent2_stepOrder: 2,
})

export const EMAIL_REG_EXP = /^(([^<>()[\]\\.,;:\s@"=]+(\.[^<>()[\]\\.,;:\s@"=]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([\w-]+\.)+\w{2,}))$/

export const CONSENT_FORM = Object.freeze({
  downloadBtnNameSuffix: '_consent_download',
  languageNameSuffix: '_consent_language',
  availableLanguages: ['German', 'English'],
  defaultLanguage: 'English',
  types: {
    patient: 'patient',
    parent1: 'parent1',
    parent2: 'parent2'
  },
  defaultTemplate: 'Standard'
})

export const SHARE_ORDER_SCREEN_TYPE = Object.freeze({
  order: 'in_order',
  list: 'list'
})

export const ORDER_INFO_PAGE_OPTIONS = Object.freeze({
  default: 'default',
  us_form: 'us_form',
  no_sample: 'no_sample',
})

export const ORDER_INFO_PAGE_SUFFIXES = Object.freeze({
  us_form: '_USA',
  no_sample: '_nosample',
})
