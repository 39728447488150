import Vue from "vue";
import { FormControl } from "@/entities/form-control";
import store from '@/store'
import { FormTab } from "@/entities/form-tab";

export default Vue.extend({
  computed: {
    controls(): FormControl[] {
      return store.getters['order/getOrderFormControls'];
    },
  },
  methods: {
    showTab(tab: FormTab): boolean {
      let hasValue = false;
      let show = true;

      if (tab.showIf.length) {
        tab.showIf.forEach((r) => {
          hasValue = this.isControlWithValue(r.id, r.value);
          if (!hasValue) {
            show = false;
          }
        });
      }

      return show;
    },
    showControl(control: FormControl): boolean {
      let hasValue = false;
      let show = true;

      if (control.showIf.length) {
        control.showIf.forEach((r) => {
          hasValue = this.isControlWithValue(r.id, r.value);
          if (!hasValue) {
            show = false;
          }
        });
      }

      return show;
    },
    isControlWithValue(controlId: string, value: any) {
      const otherControl = this.controls?.find((c) => c.uniqueId === controlId);
      if (
        otherControl?.value.toString().toLowerCase() ==
        value.toString().toLowerCase()
      ) {
        return true;
      }
      if (value == "") {
        if (otherControl?.value == null) {
          return true;
        }
      }
      return false;
    },
  }
})
