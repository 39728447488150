
















































































import Vue from "vue";
import ClosableHeader from "@/components/common/ClosableHeader.vue";
import { mapGetters } from "vuex";
import { FETCH_FORMS_CATEGORIES } from "@/store/types/actions.type";
import { FormCategory } from "@/entities/form-category";
import { FormTestItem } from "@/entities/form-test-item";

export default Vue.extend({
  name: "NewOrder",
  components: {
    ClosableHeader,
  },
  mounted() {
    this.fetchFormsCategories();
  },
  computed: {
    ...mapGetters("form", ["getFormsCategories"]),
    categories(): FormCategory[] {
      return this.getFormsCategories;
    },
  },
  methods: {
    fetchFormsCategories(): void {
      this.$store.dispatch(`form/${FETCH_FORMS_CATEGORIES}`);
    },
    close(): void {
      this.$router.push("/MyOrders");
    },
    createNewOrder(formId: string, testId: string): void {
      this.$router.push(`/order/new/${formId}/${testId}`);
    },
    getTestTitle(formTest: FormTestItem): string {
      if (formTest.testTitle) return String((this.$t(formTest.testTitle)))
      return formTest.formName
    }
  },
});
