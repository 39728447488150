import axios from '@/config/api-config';
import {ApiResponse} from '@/entities/api-response';
import {ITranslationsService} from "@/services/itranslations.service";

export class TranslationsApiService implements ITranslationsService {

  constructor() { }

  postCulture(locale: string): Promise<ApiResponse> {
    return axios.post(`culture/${locale}`).then(res => res.data);
  }

}
