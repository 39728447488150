import Vue from "vue";

export default Vue.extend({
  methods: {
    sortDate(a: string, b: string): number {
      if (!a.length) return -1
      if (!b.length) return 1

      const date1 = new Date(
        `${a.split("-")[0]}/${a.split("-")[1]}/${a.split("-")[2]}`
      );
      const date2 = new Date(
        `${b.split("-")[0]}/${b.split("-")[1]}/${b.split("-")[2]}`
      );

      return date1 > date2 ? 1 : -1;
    },
    getLocalTime(UTCDate: string): string {
      const UTCDateFormatted = UTCDate.replace(/-/g, '/');
      return new Date(`${UTCDateFormatted} UTC`).toLocaleString().replace(/,/g, '');
    },
  }
})