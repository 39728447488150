





































import Vue from "vue";
import Button from "@/components/input-components/Button";
import InputText from "@/components/input-components/InputText";
import PhysicianPickerWindow from "@/components/patients/PhysicianPickerWindow.vue";
import {
  FETCH_PATIENTS_BY_CEGATID,
  FETCH_PATIENTS_BY_PHYSICIANID,
  RESET_PHYSICIAN,
  RESET_PATIENTS,
  FETCH_ORDERS_BY_PHYSICIANID,
} from "@/store/types/actions.type";
import {
  SET_PHYSICIAN,
  SET_PATIENTS_SEARCH_CEGATID,
} from "@/store/types/mutations.type";
import { Physician } from "@/entities/physician";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "PatientsToolbarInternal",
  components: {
    PhysicianPickerWindow,
    InputText,
    Button,
  },
  data() {
    return {
      cegatId: "",
    };
  },
  async mounted() {
    let physician = this.selectedPhysician;
    if (physician.userId !== "") {
      this.loadAllPhysicansAndPatients(physician);
    }
  },
  computed: {
    ...mapGetters("physician", ["getPhysician"]),
    selectedPhysician(): Physician {
      return this.getPhysician;
    },
  },
  methods: {
    watchUserSubmit(event: KeyboardEvent) {
      if (event.key === "Enter") {
        this.loadPatientsByCegatId();
      }
    },
    async loadAllPhysicansAndPatients(physician: any) {
      this.clearCegatId();
      await this.$store.dispatch(
        `patient/${FETCH_PATIENTS_BY_PHYSICIANID}`,
        physician.userId
      );
      this.$store.dispatch(`physician/${SET_PHYSICIAN}`, physician);

      await this.$store.dispatch(
        `order/${FETCH_ORDERS_BY_PHYSICIANID}`,
        physician.userId
      );
    },
    async loadPatientsByCegatId(): Promise<void> {
      this.clearPhysician();
      await this.$store.dispatch(
        `patient/${FETCH_PATIENTS_BY_CEGATID}`,
        this.cegatId
      );
      await this.$store.dispatch(
        `patient/${SET_PATIENTS_SEARCH_CEGATID}`,
        this.cegatId
      );
    },
    async onPhysicianSelected(physician: Physician): Promise<void> {
      this.loadAllPhysicansAndPatients(physician);
    },
    clearPhysician(): void {
      this.$store.dispatch(`physician/${RESET_PHYSICIAN}`);
      this.$store.dispatch(`patient/${RESET_PATIENTS}`);
    },
    clearCegatId(): void {
      this.cegatId = "";
      this.$store.dispatch(
        `patient/${SET_PATIENTS_SEARCH_CEGATID}`,
        this.cegatId
      );
    },
  },
});
