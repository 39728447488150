
































import Vue from "vue";
import InputText from "@/components/input-components/InputText";

export default Vue.extend({
  name: "DatePicker",
  components: { InputText },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        required: (value: string) => !!value || "Required.",
      },
      activePicker: "",
      date: "",
      menu: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    date(val) {
      this.$emit("input", this.formatDate(val));
    },
  },
  computed: {
    computedDateFormatted(): string {
      return this.formatDate(this.$props.value);
    },
  },
  methods: {
    formatDate(date: string): string {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date: string): string {
      if (!date) return "";
      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
});
