import {ApiResponse} from "@/entities/api-response";
import {ITranslationsService} from "@/services/itranslations.service";
import {TranslationsApiService} from "@/services/translations-api.service";
import {LocaleMessageObject} from 'vue-i18n'
import axios from 'axios'
import {API_URL} from '../config/api-config'

export class TranslationsService implements ITranslationsService {
  private service: TranslationsApiService;

  constructor(translationsApiService?: TranslationsApiService) {
    this.service = translationsApiService || new TranslationsApiService()
  }

  postCulture(locale: string): Promise<ApiResponse> {
    return this.service.postCulture(locale).then((res: ApiResponse) => res.content)
  }
}

export async function getAllTranslations() : Promise<any> {
  try {
    const res = await axios.get(`${API_URL}/translations`)
    if (res.data && res.data.content && typeof(res.data.content) === 'object') {
      return res.data.content
    }
  } catch (exception) {
    console.warn('getAllTranslations failed with an exception', exception)
  }

  return {}
}

export async function getTranslations(locale: string) : Promise<LocaleMessageObject> {
  if (!locale || locale.length !== 2) {
    locale = 'en'
  }

  try {
    const res = await axios.get(`${API_URL}/translations/${locale}`)
    if (res.data && res.data.content && typeof(res.data.content) === 'object') {
      return res.data.content as LocaleMessageObject
    }
  } catch (error) {
    console.warn('translation returned wrong content', error)
  }

  return {} as LocaleMessageObject
}
