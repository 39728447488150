import Vue from "vue";
import { ApiResponse } from "@/entities/api-response";
import { LoggingService } from "@/services/logging.service";

export class ErrorHandlerService extends Vue {

  public static handleError(response: ApiResponse): void {
    let text = 'Unknown Error';

    // set the message if it exists
    if (response?.error?.message) {
      text = response?.error?.message;
    }

    const logService = new LoggingService();
    logService.postLog({ logLevel: "error", message: text });
  }
}