












































































































































import Vue from "vue";
import Button from "@/components/input-components/Button.vue";
import InputText from "@/components/input-components/InputText.vue";
import Config from "@/config/config";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import {Country} from '@/entities/country';

export default Vue.extend({
  name: "ProfilePersonalData",
  props: {
    userProfile: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    countries: {
      type: Array as () => Array<Country>,
      required: true,
      default() {
        return [];
      },
    },
  },
  components: {
    Button,
    InputText,
    ConfirmationDialog,
  },
  data() {
    return {
      valid: false,
      userEditDialog: false,
      closeEditDialogConfirmation: false,
      changes: false,
    };
  },
  mounted() {
    window.addEventListener("message", this.checkChanges);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.checkChanges);
  },
  computed: {
    accountPage(): string {
      if (this.userEditDialog) {
        let url = new URL("account/", Config.KEYCLOAK_REALM_URL);
        let parentUrl = new URL(parent.document.URL);
        url.searchParams.append("url", parentUrl.origin);
        return url.href;
      }

      return "about:blank";
    },
    language(): string {
      if (!this.userProfile?.locale) {
        return "";
      }

      return this.$te("locale_" + this.userProfile.locale) ? this.$t("locale_" + this.userProfile.locale) : this.userProfile.locale
    },
    country(): string {
      return this.countries.find((country: Country) => country.countryId === this.userProfile.country)?.name || this.userProfile.country
    },
  },
  methods: {
    checkChanges(event: any) {
      if (event.data === "changed") {
        this.changes = true;
      } else if (event.data === "saved") {
        this.changes = false;
        const self = this;
        setTimeout(function () {
          self.userEditDialog = false;
        }, 1000)
      } else if (event.data === "closeDialog") {
        this.changes = false;
        this.userEditDialog = false;
      }
    },
  },
  watch: {
    userEditDialog: {
      immediate: true,
      handler(newValue: any) {
        if (!newValue) {
          this.$emit("reload-profile");
        }
      },
    },
  },
});
