import {ApiResponse} from "@/entities/api-response";
import {IReportsService} from "@/services/ireports.service";
import {Report} from "@/entities/report";
import {ReportsApiService} from "@/services/reports-api.service";

export class ReportsService implements IReportsService {
  private service: ReportsApiService;

  constructor(reportsApiService?: ReportsApiService, reportsUrl?: string) {
    this.service = reportsApiService || new ReportsApiService(reportsUrl)
  }

  getReports(orderId: number): Promise<Report[]> {
    return this.service.getReports(orderId).then((res: ApiResponse) => res.content)
  }
  getReport(orderId: number, orderFileId: number, fileName: string): Promise<void> {
    return this.service.getReport(orderId, orderFileId, fileName);
  }
  getLoadedValue(): number|null {
    return this.service.getLoadedValue();
  }
  insertReport(orderId: number, report: Report): Promise<Report> {
    return this.service.insertReport(orderId, report).then((res: ApiResponse) => res.content);
  }
  updateReport(orderId: number, orderFileId: number, report: Report): Promise<Report> {
    return this.service.updateReport(orderId, orderFileId, report).then((res: ApiResponse) => res.content);
  }
  deleteReport(orderId: number, orderFileId: number): Promise<any> {
    return this.service.deleteReport(orderId, orderFileId).then((res: ApiResponse) => res.content);
  }
}
