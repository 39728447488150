


















































































import { Patient } from "@/entities/patient";
import {
  FETCH_PATIENT,
  FETCH_PATIENTS_SEXES,
} from "@/store/types/actions.type";
import Vue from "vue";
import { mapGetters } from "vuex";
import DatePicker from "@/components/input-components/DatePicker";
import { Sex } from "@/entities/sex";
import Button from "@/components/input-components/Button";
import InputText from "@/components/input-components/InputText";
import Dropdown from "@/components/input-components/Dropdown";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";

export default Vue.extend({
  name: "PatientCreation",
  components: {
    Dropdown,
    InputText,
    Button,
    DatePicker,
    ConfirmationDialog,
  },
  data() {
    return {
      chosenSexId: "",
      rules: {
        required: (value: string) => !!value || "Required.",
      },
      showUnsavedDataDialog: false,
      patientInfoHasChanged: false,
      newPatient: {
        patientId: 0,
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        sexId: "m",
        street: "",
        postalCode: "",
        city: "",
        country: "",
        patientNumber: "",
        externalId: "",
      } as Patient,
    };
  },
  mounted() {
    this.fetchSexes();
    this.fetchPatient();
  },
  watch: {
    patientStringified: function (newPat: string, oldPat: string) {
      if (newPat !== oldPat) {
        // new patient being created or not first time edited
        // prevents detecting changes when the initial data is fetched
        if (
          JSON.parse(oldPat).patientId > 0 ||
          JSON.parse(newPat).patientId == 0
        ) {
          this.patientInfoHasChanged = true;
        }
      }
    },
  },
  computed: {
    ...mapGetters("patient", ["getPatient", "getSexes", "getSexById"]),
    ...mapGetters("physician", ["getPhysician"]),
    patientId(): number {
      return this.$route.params.patientId
        ? Number.parseInt(this.$route.params.patientId)
        : 0;
    },
    patient(): Patient {
      return this.patientId ? this.getPatient : this.newPatient;
    },
    patientStringified(): string {
      return JSON.stringify(this.patient);
    },
    sexes(): Sex[] {
      return this.getSexes || [];
    },
    pageTitle(): string {
      return this.patientId ? "Patient" : "New Patient";
    },
    getPhysicianId(): string {
      return this.getPhysician.userId;
    },
  },
  methods: {
    async fetchPatient(): Promise<void> {
      if (this.patientId > 0) {
        await this.$store.dispatch(`patient/${FETCH_PATIENT}`, [
          this.patientId,
          this.getPhysicianId,
        ]);
        this.chosenSexId = await this.getSexById(this.patient.sexId);
      }
    },
    fetchSexes(): void {
      this.$store.dispatch(`patient/${FETCH_PATIENTS_SEXES}`);
    },
    sexValidation(value: Sex): boolean | string {
      return (
        this.sexes.find((s) => s.sexId === value.sexId) != null ||
        "Value not accepted: " + value
      );
    },
    saveChangesExit(): void {
      this.showUnsavedDataDialog = false;
      this.save();
    },
    discardChangesExit(): void {
      this.showUnsavedDataDialog = false;
      this.$emit("cancel");
    },
    closeChangesDialog(): void {
      this.showUnsavedDataDialog = false;
    },
    save(): void {
      const patientForm = this.$refs.form as any;
      if (patientForm.validate()) {
        this.patientInfoHasChanged = false;
        this.$emit("save", this.patient);
      }
    },
    cancel(): void {
      if (this.patientInfoHasChanged) {
        this.showUnsavedDataDialog = true;
      } else {
        this.$emit("cancel");
      }
    },
  },
});
