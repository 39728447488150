import {
  ADD_MESSAGE, DELETE_MESSAGE,
} from "../types/mutations.type";
import { LoggingService } from "@/services/logging.service";
import { ToasterMessage } from "@/entities/toaster-message";

const loggingService = new LoggingService();

const state: any = {
  messages: []
};

const getters = {
  getMessages: (_state: any) => {
    return _state.messages;
  },
}

const actions = {
  async [ADD_MESSAGE](context: any, message: ToasterMessage) {
    context.commit(ADD_MESSAGE, message);
    if (message.type != "error") {
      setTimeout(() => {
        context.commit(DELETE_MESSAGE, message)
      }, 5000)
    } else {
      try {
        await loggingService.postLog({ logLevel: "error", message: message.message })
      } catch (res: any) {
        if (res) {
          // display a message to the user if error happened in the Logging Service
          context.commit(ADD_MESSAGE, {
            message: "Error trying to register Log: " + res?.error?.statusCode + ' - ' + res?.error?.message,
            type: "error",
            values: null
          });
        }
      }
    }
  },
  [DELETE_MESSAGE](context: any, message: ToasterMessage) {
    context.commit(DELETE_MESSAGE, message);
  },
}

const mutations = {
  [ADD_MESSAGE](_context: any, message: ToasterMessage) {
    state.messages.unshift(message)
  },
  [DELETE_MESSAGE](_context: any, message: ToasterMessage) {
    state.messages = state.messages.filter((m: ToasterMessage) => m !== message)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
