



















import Vue from "vue";

export default Vue.extend({
  name: "FileInput",
  inheritAttrs: false,
  data() {
    return {
      rules: [
        (value: Array<{ name: string }>) => {
          this.$emit("dataType", false)
          return (
            !value ||
            !value.length ||
            (this as any).checkDatatype(value) ||
            this.$t("The Datatype is not allowed!")
          );
        },
      ],
    };
  },
  methods: {
    checkDatatype(value: Array<{ name: string }>): boolean {
      const fileExtensionPattern =
        /\.(avi|wmv|mpg|mpeg|mp4|mov|mjpeg|bmp|gif|jpg|jpeg|png|tif|tiff|heif|heic|pdf)$/i;

      let isValid = true
      if (value.length) {
        for (let i = 0, length = value.length; i < length; i++) {
          if (!fileExtensionPattern.test(value[i].name)) {
            isValid = false
            this.$emit("dataType", true)
            break
          }
        }
      }

      this.$emit("dataType", !isValid)
      return isValid
    },
  },
});
