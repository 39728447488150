




























































































































































































































































































import InputText from "@/components/input-components/InputText";
import Button from "@/components/input-components/Button";
import Combobox from "@/components/input-components/Combobox";
import Dropdown from "@/components/input-components/Dropdown";
import { Patient } from "@/entities/patient";

export default {
  name: "StyleGuide",
  components: { Dropdown, Combobox, Button, InputText },
  data() {
    return {
      showPw: false,
      radioGroup: 2,
      checkbox: [false, true],
      switchModel: [false, true],
      headers: [
        { text: "Patient name", value: "firstName" },
        { text: "Patient number", value: "number" },
        { text: "Patient d.o.b.", value: "dateOfBirth" },
        { text: "Sex", value: "sexId" },
        { text: "Actions", value: "actions" },
      ],
      page: 1,
      pages: 10,
      dialog: false,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
    };
  },
  methods: {
    runSearch() {
      alert("search");
    },
  },
  computed: {
    getPatients(): Patient[] {
      let patients = [];
      for (let i = 0; i < 500; i++) {
        let patient = {
          patientId: i,
          firstName: `Maximilius ${i + 1}`,
          lastName: `von Daner ${i + 1}`,
          number: `P123A45 ${i + 1}`,
          dateOfBirth: `12.03.1985 ${i + 1}`,
          sexId: "male",
          city: `City ${i + 1}`,
          street: `Street ${i + 1}`,
          country: `Country ${i + 1}`,
          patientNumber: `Patient ${i + 1}`,
        };
        patients.push(patient);
      }
      return patients;
    },
  },
};
