var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.hasNoPermission)?_c('Dialog',_vm._g(_vm._b({attrs:{"content-class":'order-notification--dialog',"persisent":_vm.dataLoading || _vm.saveInProgress},scopedSlots:_vm._u([(_vm.dataLoading)?{key:"content",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]},proxy:true}:{key:"content",fn:function(){return [(!_vm.notifications || _vm.notifications.length === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("Notifications not found"))+" ")]):_c('v-radio-group',{attrs:{"disabled":_vm.saveInProgress || !_vm.hasWritePermission,"hide-details":""},model:{value:(_vm.selectedNotificationState),callback:function ($$v) {_vm.selectedNotificationState=$$v},expression:"selectedNotificationState"}},[_c('v-radio',{staticClass:"notifications--active",attrs:{"label":_vm.$t('Receive notifications for the following status changes'),"value":true}}),_c('div',{staticClass:"notifications"},_vm._l((_vm.notifications),function(n){return _c('v-checkbox',{key:n.notificationId,attrs:{"value":n.notificationId,"label":_vm.$t(n.name),"hide-details":"","disabled":!_vm.selectedNotificationState ||
            _vm.saveInProgress ||
            !_vm.hasWritePermission},model:{value:(_vm.selectedNotifications),callback:function ($$v) {_vm.selectedNotifications=$$v},expression:"selectedNotifications"}})}),1),_c('v-radio',{staticClass:"notifications--inactive",attrs:{"label":_vm.$t('Don\'t receive any notifications for this order'),"value":false}})],1)]},proxy:true},(
      _vm.dataLoading ||
      !_vm.notifications ||
      _vm.notifications.length === 0 ||
      !_vm.hasWritePermission
    )?{key:"actions",fn:function(){return [_vm._v("   ")]},proxy:true}:null],null,true)},'Dialog',_vm.$attrs,false),Object.assign({}, _vm.$listeners, {save: _vm.save}))):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }