

























import Vue from "vue";
import { mapGetters } from "vuex";
import { Order } from "@/entities/order";
import { Patient } from "@/entities/patient";
import { FORM_CONTROLS } from "@/utils/constants";
import { User } from "@/entities/user";

export default Vue.extend({
  name: "OrderInvoiceData",
  computed: {
    ...mapGetters("patient", ["getPatient"]),
    ...mapGetters("order", ["getOrder", "getOrderFormControlByName"]),
    ...mapGetters("profile", ["getProfile"]),
    profile(): User {
      return this.getProfile;
    },
    patient(): Patient {
      return this.getPatient;
    },
    order(): Order {
      return this.getOrder;
    },
  },
  methods: {
    fillInvoice(value: string) {
      let invoiceData;
      if (value === "sender") {
        invoiceData = {
          firstName: this.getOrderFormControlByName(
            FORM_CONTROLS.sender_firstName
          ).value,
          lastName: this.getOrderFormControlByName(
            FORM_CONTROLS.sender_lastName
          ).value,
          street: this.getOrderFormControlByName(FORM_CONTROLS.sender_street)
            .value,
          postalCode: this.getOrderFormControlByName(
            FORM_CONTROLS.sender_postalCode
          ).value,
          city: this.getOrderFormControlByName(FORM_CONTROLS.sender_city).value,
          country: this.getOrderFormControlByName(FORM_CONTROLS.sender_country)
            .value,
          email: this.getOrderFormControlByName(FORM_CONTROLS.sender_email)
            .value,
        };
      } else if (value === "patient") {
        if (this.order.patientId) {
          invoiceData = this.patient;
        } else {
          invoiceData = {
            firstName: this.getOrderFormControlByName(
              FORM_CONTROLS.patient_firstName
            ).value,
            lastName: this.getOrderFormControlByName(
              FORM_CONTROLS.patient_lastName
            ).value,
          };
        }
      } else if (value === 'profile') {
        invoiceData = this.profile
      }
      this.$emit("fillInvoice", invoiceData);
    },
  },
});
