











import Vue from "vue";
import { mapGetters } from "vuex";
import { FETCH_PROFILE } from "@/store/types/actions.type";
import { User } from "@/entities/user";

export default Vue.extend({
  name: "OrderSenderData",
  computed: {
    ...mapGetters("profile", ["getProfile"]),
    ...mapGetters("physician", ["getPhysician"]),
    profile(): User {
      return this.getProfile;
    },
    getPhysicianId(): string {
      return this.getPhysician.userId;
    },
  },
  mounted() {
    this.fetchProfile();
  },
  methods: {
    async fetchProfile(): Promise<void> {
      let userId = this.getPhysicianId
        ? this.getPhysicianId
        : this.$keycloak?.subject;

      await this.$store.dispatch(`profile/${FETCH_PROFILE}`, userId);
    },
    fillSender() {
      this.$emit("fillSender", this.profile);
    },
  },
});
