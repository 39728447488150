import { ToasterMessage } from "@/entities/toaster-message";
import { ADD_MESSAGE } from "@/store/types/mutations.type";
import store from "@/store"

export default class NotificationMessageService {

  public static showError(text: string, values: any = null) {
    this.showMessage(text, "error", values)
  }
  public static showInfo(text: string, values: any = null) {
    this.showMessage(text, "info", values)
  }
  public static showWarning(text: string, values: any = null) {
    this.showMessage(text, "warning", values)
  }
  public static showSuccess(text: string, values: any = null) {
    this.showMessage(text, "success", values)
  }

  private static showMessage(text: string, type: any, values: any = null): void {
    const message: ToasterMessage = {
      message: text,
      type,
      values
    }
    store.dispatch(`notification/${ADD_MESSAGE}`, message)
  }
}
