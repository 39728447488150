import axios from '@/config/api-config';
import {ApiResponse} from '@/entities/api-response';
import {Order} from '@/entities/order';
import {IOrdersService} from './iorders.service';
import {Notification} from "@/entities/notification";
import fileDownload from "js-file-download";

const api = '/orders';

export class OrdersApiService implements IOrdersService {

  constructor() { }

  getOrders(): Promise<ApiResponse> {
    return axios.get(`${api}`).then(res => res.data);
  }
  getOrdersByPhysicianId(physicianId: string): Promise<ApiResponse> {
    return axios.get(`${api}?doctorId=${physicianId}`).then(res => res.data);
  }
  getOrdersByOrderId(orderId: string): Promise<ApiResponse> {
    return axios.get(`${api}?orderId=${orderId}`).then(res => res.data);
  }
  getOrdersByCegatId(cegatId: string): Promise<ApiResponse> {
    return axios.get(`${api}?cegatId=${cegatId}`).then(res => res.data);
  }
  getOrder(orderId: number, physicianId?: string): Promise<ApiResponse> {
    if (physicianId) {
      return axios.get(`${api}/${orderId}?doctorId=${physicianId}`).then(res => res.data);
    } else {
      return axios.get(`${api}/${orderId}`).then(res => res.data);
    }
  }
  postOrder(order: Order, physicianId?: string): Promise<ApiResponse> {
    if (physicianId) {
      return axios.post(`${api}/?doctorId=${physicianId}`, order).then(res => res.data);
    } else {
      return axios.post(`${api}`, order).then(res => res.data);
    }
  }
  postOrderNotification(orderId: number, notification: Notification): Promise<ApiResponse> {
    return axios.post(`${api}/${orderId}/notifications`, notification).then(res => res.data);
  }
  putOrder(order: Order, physicianId?: string, recipientGroupIds?: number[]): Promise<ApiResponse> {
    const queryParams = [];

    if (physicianId) {
      queryParams.push(`doctorId=${physicianId}`);
    }

    if (recipientGroupIds !== undefined && recipientGroupIds?.length > 0) {
      queryParams.push(`groupIds=${recipientGroupIds.join('|')}`);
    }

    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

    const url = `${api}/${order.orderId}${queryString}`;
    return axios.put(url, order).then(res => res.data);
  }
  deleteOrder(orderId: number, physicianId?: string): Promise<ApiResponse> {
    if (physicianId) {
      return axios.delete(`${api}/${orderId}?doctorId=${physicianId}`).then(res => res.data);
    } else {
      return axios.delete(`${api}/${orderId}`).then(res => res.data);
    }
  }
  deleteOrderNotification(orderId: number, notificationId: string): Promise<ApiResponse> {
    return axios.delete(`${api}/${orderId}/notifications/${notificationId}`).then(res => res.data);
  }
  getOrdersStatus(): Promise<ApiResponse> {
    return axios.get(`${api}/statuses`).then(res => res.data);
  }
  getOrderNotifications(orderId: number): Promise<ApiResponse> {
    return axios.get(`${api}/${orderId}/notifications`).then(res => res.data);
  }
  getOrderSupportingFiles(orderId: number): Promise<ApiResponse> {
    return axios.get(`${api}/${orderId}/supportingFiles`).then(res => res.data);
  }
  getOrderSupportingFile(orderId: number, orderFileId: number): Promise<ApiResponse> {
    return axios.get(`${api}/${orderId}/supportingFiles/${orderFileId}`).then(res => res.data);
  }
  postOrderSupportingFile(orderId: number, file: File, comment?: string): Promise<ApiResponse> {
    const formData = new FormData();
    formData.append("file", file);
    if (comment) formData.append("comment", comment);
    return axios.post(`${api}/${orderId}/supportingFiles`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => res.data);
  }
  putOrderSupportingFile(orderId: number, orderFileId: number, file: File, comment?: string): Promise<ApiResponse> {
    const formData = new FormData();
    formData.append("file", file);
    if (comment) formData.append("comment", comment);
    // endpoint = 'POST'
    return axios.post(`${api}/${orderId}/supportingFiles/${orderFileId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => res.data);
  }
  deleteOrderSupportingFile(orderId: number, orderFileId: number): Promise<ApiResponse> {
    return axios.delete(`${api}/${orderId}/supportingFiles/${orderFileId}`).then(res => res.data);
  }
  getOrderConsent(orderId: number, type: string, data: string, template: string): Promise<void> {
    return axios.post(`${api}/${orderId}/consents/${type}/${template}`, data, {
      responseType: 'blob'
    }).then(res => {
      fileDownload(res.data, `consent_${type}_${orderId}.pdf`)
    });
  }
  getOrderSummary(orderId: number, languageId: string = 'en'): Promise<void> {
    return axios.get(`${api}/${orderId}/summary/${languageId}`, {
      responseType: 'blob'
    }).then(res => {
      fileDownload(res.data, `${orderId}_order_summary_${languageId}.pdf`)
    });
  }
}
