import { IPhysiciansService } from "./iphysicians.service";
import { ApiResponse } from "@/entities/api-response";
import axios from "@/config/api-config";

const physiciansApi = '/users';// '/physicians'

export class PhysiciansApiService implements IPhysiciansService {

  constructor() { }

  public getPhysicians(): Promise<ApiResponse> {
    return axios.get(`${physiciansApi}`).then(res => res.data);
  }
}