
















































import {
  DELETE_ORDER,
  FETCH_FORM,
  FETCH_ORDER,
  FETCH_ORDER_SUPPORTING_FILES,
  RESET_ORDER_SUPPORTING_FILES,
  POST_ORDER,
  PUT_ORDER,
  RESET_ORDER,
  POST_PATIENT,
  RESET_PATIENT,
  FETCH_PATIENT,
} from "@/store/types/actions.type";
import { SET_ORDER_PATIENT } from "@/store/types/mutations.type";
import ClosableHeader from "@/components/common/ClosableHeader.vue";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import DateHelperMixin from "@/mixins/DateHelperMixin";
import FormRenderer from "@/components/FormRenderer.vue";
import NotificationMessageService from "@/services/notificationmessage.service";
import OrderSubmitted from "@/components/orders/OrderSubmitted.vue";
import OrderSummary from "@/components/orders/OrderSummary.vue";
import Vue from "vue";
import { JsonForm } from "@/entities/json-form";
import { FORM_CONTROLS, ORDER_STATUS } from "@/utils/constants";
import { Order } from "@/entities/order";
import { Patient } from "@/entities/patient";
import {FormControl} from "@/entities/form-control";
import { mapGetters } from "vuex";
import { convertFormBuilderSexValueToFrontendValue } from '@/utils'

export default Vue.extend({
  name: "Order",
  components: {
    FormRenderer,
    ClosableHeader,
    OrderSummary,
    OrderSubmitted,
    ConfirmationDialog,
  },
  data() {
    return {
      testName: "",
      showSummary: false,
      showSubmitted: false,
      dateHelperMixin: new DateHelperMixin(),
      showDeleteDialog: false,
    };
  },
  async mounted() {
    await this.loadOrder();
    this.checkPermissions();
  },
  computed: {
    ...mapGetters("order", ["getOrder", "getOrderFormControlByName", "getOrderFormControls"]),
    ...mapGetters("form", ["getForm"]),
    ...mapGetters("patient", ["getPatient"]),
    ...mapGetters("physician", ["getPhysician"]),
    patient(): Patient {
      return this.getPatient;
    },
    form(): JsonForm {
      return this.getForm;
    },
    testTitle(): string {
      return this.order.formTestTitle;
    },
    formName(): string {
      return this.order.form?.formConfig?.name || "";
    },
    title(): string {
      return this.testTitle ?? this.formName;
    },
    isNewOrder(): boolean {
      return this.$route.fullPath.split("/")[2].toLowerCase() === "new";
    },
    isEditOrder(): boolean {
      return this.$route.fullPath.split("/")[2].toLowerCase() === "edit";
    },
    isViewOrder(): boolean {
      return this.$route.fullPath.split("/")[2].toLowerCase() === "view";
    },
    isDraft(): boolean {
      return this.order.statusId == ORDER_STATUS.draft;
    },
    formId(): string {
      return this.$route.params.formId;
    },
    testId(): number {
      return Number.parseInt(this.$route.params.testId);
    },
    orderId(): string {
      return this.$route.params.orderId;
    },
    order(): Order {
      return this.getOrder;
    },
    getPhysicianId(): string {
      return this.getPhysician.userId;
    },
  },
  methods: {
    checkPermissions(): void {
      if (
        (this.isNewOrder && !this.hasCreatePermission()) ||
        (this.isEditOrder && !this.hasEditPermission()) ||
        (this.isViewOrder && !this.hasViewPermission())
      ) {
        NotificationMessageService.showWarning(`Not Enough Permissions`);
        this.$router.push({ path: "/" });
      }
    },
    /**
     * Checks if the form has active notification triggers.
     * @returns {number[]} - recipient group ids of all controls with notification triggers have their current values included
     * in their notification triggers, empty array otherwise.
     */
    collectActiveRecipientGroups(): number[] {
      const controlsWithTrigger = this.getOrderFormControls.filter((c: FormControl) => c.notificationTriggers.length)
      if (!controlsWithTrigger.length) {
        return []
      }

      let recipientGroupIds: number[] = []
      controlsWithTrigger.forEach((control: FormControl) => {
        const controlValueAsString = String(control.value)
        if (control.notificationTriggers.includes(controlValueAsString)) {
          control.recipientGroups.forEach((groupId: number) => {
            if (!recipientGroupIds.includes(groupId)) {
              recipientGroupIds.push(groupId)
            }
          })
        }
      })

      return recipientGroupIds
    },
    hasCreatePermission(): boolean {
      return (
        (this.hasOrderCreatePermission &&
          this.order.statusId === ORDER_STATUS.draft) ||
        this.hasOrderCreateAllPermission
      );
    },
    hasEditPermission(): boolean {
      return (
        (this.hasOrderUpdatePermission &&
          this.order.statusId === ORDER_STATUS.draft) ||
        this.hasOrderUpdateAllPermission
      );
    },
    hasViewPermission(): boolean {
      return (
        (this.hasOrderReadPermission &&
          this.order.statusId !== ORDER_STATUS.draft) ||
        this.hasOrderReadAllPermission
      );
    },
    async loadOrder(): Promise<void> {
      if (this.isNewOrder && this.formId) {
        // load form / convert into order
        await this.fetchForm();
        // save order to get the orderId
        await this.postOrder();
        // returning the ID = saved successfully
        if (this.order.orderId) {
          // loading the saved order using url params
          this.$router.push(`/order/edit/${this.order.orderId}`);
        }
      } else if ((this.isEditOrder || this.isViewOrder) && this.orderId) {
        await this.$store.dispatch(`order/${RESET_ORDER}`);

        // load order using url orderId
        await this.$store.dispatch(`order/${FETCH_ORDER}`, [
          this.orderId,
          this.getPhysicianId,
        ]);
        // load uploaded supporting files
        await this.$store.dispatch(
          `order/${FETCH_ORDER_SUPPORTING_FILES}`,
          this.orderId
        );
        // load set patient
        await this.$store.dispatch(`patient/${FETCH_PATIENT}`, [
          this.order.patientId,
          this.getPhysicianId,
        ]);
      }
    },
    async fetchForm(): Promise<void> {
      // load the form model from db and convert to order
      await this.$store.dispatch(`form/${FETCH_FORM}`, [
        this.formId,
        this.testId,
      ]);
    },
    async postOrder(): Promise<void> {
      // save order preview into db to get the orderID
      this.updateOrderInitialData();
      await this.$store.dispatch(`order/${POST_ORDER}`, this.getPhysicianId);
    },
    updateOrderInitialData(): void {
      this.order.statusId = "draft";
      this.order.formTestId = this.testId;
      this.order.form = this.form;
    },
    updateOrderPatientData(patient: Patient): void {
      this.order.patientId = patient.patientId;
      this.order.patientFirstName = patient.firstName;
      this.order.patientLastName = patient.lastName;
      this.order.patientDateOfBirth = patient.dateOfBirth;
      this.order.cegatId = patient.patientNumber;
      this.order.referenceId = patient.externalId;
    },
    async saveOrder(recipientGroupIds?: number[]): Promise<void> {
      await this.$store.dispatch(`order/${PUT_ORDER}`, [
        true,
        this.getPhysicianId,
        recipientGroupIds,
      ]);
    },
    async submitOrder(): Promise<void> {
      if (this.isDraft) {
        // if patient is new, register
        if (!this.order.patientId) {
          await this.registerNewPatient();
          this.updateOrderPatientData(this.patient);
        }
        const recipientGroupIds = this.collectActiveRecipientGroups();
        this.order.statusId = "order_submitted";
        await this.saveOrder(recipientGroupIds);
      }
      this.showSummary = false;
      this.showSubmitted = true;
    },
    async registerNewPatient(): Promise<void> {
      await this.$store.dispatch(`patient/${RESET_PATIENT}`);
      // build new patient from the form data, but first filling it with the initial state.
      let newPatient = this.patient;
      newPatient.firstName =
        this.getOrderFormControlByName("patient_firstName").value;
      newPatient.lastName =
        this.getOrderFormControlByName("patient_lastName").value;
      newPatient.dateOfBirth = this.getOrderFormControlByName(
        "patient_dateOfBirth"
      ).value;
      newPatient.sexId =
        convertFormBuilderSexValueToFrontendValue(this.getOrderFormControlByName("patient_sexId").value)
      const externalIdControl = this.getOrderFormControlByName(FORM_CONTROLS.patient_externalId)
      if (externalIdControl) {
        newPatient.externalId = externalIdControl.value
      }

      // save new patient and update patient data in order
      await this.$store.dispatch(`patient/${POST_PATIENT}`, [
        newPatient,
        this.getPhysicianId,
      ]);
    },
    close(): void {
      if (this.isDraft && !this.isViewOrder) {
        this.saveOrder();
      }

      this.$router.push("/MyOrders");
    },
    cancelOrder(): void {
      this.showDeleteDialog = true;
    },
    async newPatientOrder(): Promise<void> {
      await this.$store.dispatch(`patient/${FETCH_PATIENT}`, [
        this.order.patientId,
        this.getPhysicianId,
      ]);
      await this.$store.dispatch(`order/${RESET_ORDER_SUPPORTING_FILES}`);
      await this.$store.dispatch(`order/${RESET_ORDER}`);
      await this.$store
        .dispatch(`order/${SET_ORDER_PATIENT}`, this.patient)
        .then(() => {
          this.$router.push({ path: `/order/new` });
        });
    },
    async deleteItemConfirm() {
      await this.$store.dispatch(`order/${DELETE_ORDER}`, [
        this.order.orderId,
        this.getPhysicianId,
      ]);
      this.closeDeleteDialog();
      this.$router.push("/MyOrders");
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
  },
});
