

















import Vue from "vue";
import Button from "@/components/input-components/Button.vue";

export default Vue.extend({
  name: "ClosableHeader",
  components: {
    Button,
  },
});
