



















import Vue from "vue";
import { mapGetters } from "vuex";
import { ToasterMessage } from "@/entities/toaster-message";
import { DELETE_MESSAGE } from "@/store/types/mutations.type";
import Button from "@/components/input-components/Button.vue";

export default Vue.extend({
  name: "Toaster",
  components: {
    Button,
  },
  computed: {
    ...mapGetters("notification", ["getMessages"]),
    messages(): ToasterMessage[] {
      return this.getMessages;
    },
  },
  methods: {
    dismiss(msg: ToasterMessage): void {
      this.$store.dispatch(`notification/${DELETE_MESSAGE}`, msg);
    },
  },
});
