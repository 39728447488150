

















import Vue from "vue";
import Dialog from "@/components/dialogs/Dialog";
import ShareOrder from '@/components/orders/ShareOrder.vue'
import {SHARE_ORDER_SCREEN_TYPE} from '@/utils/constants'

export default Vue.extend({
  name: "MoreUserOrderShareDialog",
  computed: {
    SHARE_ORDER_SCREEN_TYPE() {
      return SHARE_ORDER_SCREEN_TYPE
    }
  },
  components: { ShareOrder, Dialog },
  props: {
    orderId: {
      type: Number,
      required: true,
    }
  },
  methods: {
    closeSubmitOrderShareDialog(args: String|null) {
      this.$emit('closeSubmitOrderShareDialog', args)
    },
  }
});
