








import Vue from "vue";

export default Vue.extend({
  name: "RadioGroup",
  inheritAttrs: false,
});
