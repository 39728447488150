

















import Vue from "vue";

export default Vue.extend({
  name: "FileTooltip",
  props: {
    file: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    contentClass: {
      type: String,
      default() {
        return "";
      },
    },
  },
});
