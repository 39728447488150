




import Vue from "vue";
import Button from "@/components/input-components/Button.vue";
import { FETCH_ORDER_SUMMARY } from "@/store/types/actions.type";
import { mapGetters } from "vuex";
import { Order } from "@/entities/order";

export default Vue.extend({
  name: "OrderSummaryDownload",
  components: { Button },
  computed: {
    ...mapGetters("order", ["getOrder"]),
    order(): Order {
      return this.getOrder;
    },
  },
  methods: {
    async downloadOrderSummary(): Promise<void> {
      const payload = {
        orderId: this.order.orderId,
        languageId: this.$i18n.locale,
      };
      await this.$store.dispatch(`order/${FETCH_ORDER_SUMMARY}`, payload);
    },
  },
});
