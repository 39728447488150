












import Vue from "vue";
import Dropdown from "@/components/input-components/Dropdown.vue";

export default Vue.extend({
  name: "OrderStatus",
  components: { Dropdown },
  props: {
    statuses: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    order: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
    saveInProgress: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  computed: {
    orderStatusId(): string | null {
      return this.order?.statusId ?? null;
    },
  },
});
