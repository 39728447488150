import {User} from "@/entities/user";
import {ApiResponse} from "@/entities/api-response";
import {ProfileApiService} from "@/services/profile-api.service";
import {IProfileService} from "@/services/iprofile.service";
import {Notification} from "@/entities/notification"
import {Country} from '@/entities/country'

export class ProfileService implements  IProfileService {

  private service: ProfileApiService;

  constructor(profileApiService?: ProfileApiService) {
    this.service = profileApiService || new ProfileApiService()
  }

  public getProfile(userId: string): Promise<User> {
    return this.service.getProfile(userId).then((res: ApiResponse) => res.content)
  }

  public deleteUser(userId: string): Promise<any> {
    return this.service.deleteUser(userId).then((res: ApiResponse) => res.content)
  }

  public postAdditionalEmails(email: string): Promise<any> {
    return this.service.postAdditionalEmails(email).then((res: ApiResponse) => res.content)
  }

  public deleteAdditionalEmails(email: string): Promise<any> {
    return this.service.deleteAdditionalEmails(email).then((res: ApiResponse) => res.content)
  }

  public postSharedUsers(email: string, salutation: string, title: string, firstName: string, lastName: string): Promise<any> {
    return this.service.postSharedUsers(email, salutation, title, firstName, lastName).then((res: ApiResponse) => res.content)
  }

  public deleteSharedUsers(email: string, salutation: string, title: string, firstName: string, lastName: string): Promise<any> {
    return this.service.deleteSharedUsers(email, salutation, title, firstName, lastName).then((res: ApiResponse) => res.content)
  }

  public getSharedUsers(): Promise<ApiResponse> {
    return this.service.getSharedUsers()
  }

  deleteNotification(userId: string, notificationId: string): Promise<any> {
    return this.service.deleteNotification(userId, notificationId).then((res: ApiResponse) => res.content)
  }

  getNotifications(userId: string): Promise<any> {
    return this.service.getNotifications(userId).then((res: ApiResponse) => res.content)
  }

  postNotification(userId: string, notification: Notification): Promise<any> {
    return this.service.postNotification(userId, notification).then((res: ApiResponse) => res.content)
  }

  public getCountries(): Promise<Country[]> {
    return this.service.getCountries().then((res: ApiResponse) => res.content)
  }
}
